import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LessonSection } from './LessonSection';
import { Divider, message, Space } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { Tooltip, Popconfirm, Modal, Input, Button, Collapse, Select, DatePicker, Image } from 'antd';
import { ProfileOutlined, FileAddOutlined, PlusOutlined, SaveOutlined, ExportOutlined } from '@ant-design/icons';
// import ModalDraggable from 'react-draggable';

// import EllipsisText from "react-ellipsis-text";
import { useSelector, useDispatch } from 'react-redux'
import { update_topic_order, delete_item, edit_title, add_item } from '../../actions';
import { AddLesson } from '../../components/lesson';
import uuid from 'react-uuid'
import axios from 'axios';
import './TopicSection.scss'
import LessonService from '../../services/LessonService';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
const { Option } = Select;
const { RangePicker } = DatePicker;



export function TopicSection(props) {


   const [topic, setTopic] = React.useState([]);
   const [formativeQuiz, setFormativeQuiz] = React.useState([]);
   const [visibleResources, setVisibleResources] = React.useState(false);
   const [visibleExam, setVisibleExam] = React.useState(false);
   const [currentTopicEditIdx, setCurrentTopicEditIdx] = React.useState(0);
   const [visibleEdit, setVisibleEdit] = React.useState(false);
   //  const [visibleQuiz, setVisibleQuiz]= React.useState(false);

   const [topicTitleEdit, setTopicTitleEdit] = React.useState('');
   const [topicIdx, setTopicIdx] = React.useState(0);
   const [viewMode, setViewMode] = React.useState('');
   const [dateEffective, setDateEffective] = React.useState('');
   const [levelId, setLevelId] = React.useState(0);
   const [Id, setId] = React.useState(0);
   const [quizSelected, setQuizSelected] = React.useState({});
   const [currentPanelIdx, setCurrentPanelIdx] = React.useState('');
   const [openPanel, setOpenPanel] = React.useState(['']);

   const [mode, setMode] = React.useState('');
   //  const [topicTitle, setTopicTitle]= React.useState('');
   const { Panel } = Collapse;
   useEffect(() => {
      setTopic(props.topics)
      setViewMode(props.mode)
      const search = window.location.search;
      const query = new URLSearchParams(search);
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      const LId = query.get('level')
      setLevelId(LId)
      setId(user)

      LessonService.getListFormative(user, LId).then((res) => {
         var data = res.data;
         let allquiz = []
         data.map((item) => {
            const dta = { id: item.id, subject_desc: item.subject_desc, title: item.title, q_type: item.question_type }
            allquiz.push(dta)
         })
         setFormativeQuiz(allquiz)
      }).catch((ex) => {
         message.error(ex.message)
      })
   }, [props.topics]);

   // var topic = props.topics;
   const lessonState = useSelector(state => state.lesson)
   const dispatch = useDispatch();

   const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      //   boxShadow: isDragging ? "0 0 4px 2px #ccc" : "None"  ,
      border: isDragging ? "1px solid #ccc" : "None",
      background: isDragging ? "#E2EBFF" : "None",
      paddingTop: '1px',
      paddingBottom: '1px',
      borderRadius: '7px',
      // styles we need to apply on draggables
      ...draggableStyle
   });

   const _onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
         return;
      }
      if (result.source.index !== result.destination.index) {
         var params = { SourceIdx: result.source.index, DestinationIdx: result.destination.index }
         dispatch(update_topic_order(params));
         var newTopic = [...lessonState.sections];
         setTopic(newTopic)
         props.saveLesson()
         props.refreshLesson();
      }
   }

   const handleDeleteRequest = (event, id) => {
      var params = { Type: "TOPIC", SourceIdx: id }
      dispatch(delete_item(params));
      var newItems = [...lessonState.sections];
      setTopic(newItems)
      props.saveLesson()
      event.stopPropagation();
   }

   const addTopicLesson = (event, idx, type) => {
      setOpenPanel([])
      setCurrentPanelIdx(idx)
      setDateEffective("")
      setCurrentTopicEditIdx(idx);

      type === 0 ? setVisibleResources(true) : setVisibleExam(true);
      // event.stopPropagation();
   }

   const addFinish = () => {
      var newTopic = [...lessonState.sections];
      setTopic(newTopic)
      setVisibleResources(false);
      props.saveLesson()
      props.refreshLesson();
   }

   const editOkTitle = () => {
      if (mode === "EditLesson") {
         var params = { Type: "TOPIC", TopicIndex: topicIdx, title: topicTitleEdit }
         dispatch(edit_title(params));
         setVisibleEdit(false)
         props.saveLesson()
      }
      else {
         var item = {
            id: uuid(),
            title: topicTitleEdit,
            contents:
               []
         }
         var params = { Type: "TOPIC", title: item }
         dispatch(add_item(params));
         var newItems = [...lessonState.sections];
         setTopic(newItems)
         setVisibleEdit(false)
         props.saveLesson()
      }
   }

   const addFormativeQuiz = (topicIdx) => {
      if (dateEffective !== "") {
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         const assignment_id = "ass_assign_" + o2o_mode + "_" + uuid();
         const effectiveDaterange = dateEffective[0] + "@" + dateEffective[1];
         // temp
         /*         var newItem = {id : uuid() ,type: "quiz", title : quizSelected.title , otherdesc : quizSelected.subject_desc, otherid : quizSelected.id , otherid2 : assignment_id, links : []}
                 var params = {Type : "LESSON" ,TopicIndex : topicIdx , Item : newItem}
                 dispatch(add_item(params));     
                 setVisibleResources(false)    */
         // temp
         LessonService.assignFormative(assignment_id, quizSelected.id, Id, lessonState.lessonid, dateEffective[0], dateEffective[1], levelId).then(() => {
            var newItem = { id: uuid(), type: "quiz", title: quizSelected.title, otherdesc: quizSelected.subject_desc, otherid: quizSelected.id, otherid2: assignment_id, effectivedate: effectiveDaterange, links: [], q_type: quizSelected.q_type }
            var params = { Type: "LESSON", TopicIndex: topicIdx, Item: newItem }
            dispatch(add_item(params));
            setVisibleExam(false)
            props.saveLesson()
         }).catch((ex) => {
            message.error(ex.message)
            setVisibleExam(false)
         })
      }
      else {
         message.error("Effective date is blank!")
      }

   }

   const editTitleClick = (event, title, idx, mode) => {
      setVisibleEdit(true)
      setTopicTitleEdit(title)
      setTopicIdx(idx)
      setMode(mode)
      event.stopPropagation();
   }

   const addTopic = () => {
      setMode('AddTopic')
      setTopicTitleEdit("")
      setVisibleEdit(true)
   }

   const handleChangeQuiz = (value) => {
      console.log(`selected ${value}`);
      const selectedQuiz = formativeQuiz.filter(p => p.id === value)
      setQuizSelected(selectedQuiz[0])
   }

   const menuExtra = (index, item) => {
      return (
         <>
            <div >
               <Space size="small" style={{ paddingRight: '10px' }}>
                  <Tooltip placement="bottom" title='Add/Edit Resources' key={'0'}>
                     <FileAddOutlined onClick={(e) => addTopicLesson(e, index, 0)} />
                  </Tooltip>
                  <Tooltip placement="bottom" title='Add Assessment' key={'0'}>
                     <ProfileOutlined onClick={(e) => addTopicLesson(e, index, 1)} />
                  </Tooltip>
                  <Tooltip placement="bottom" title='Edit Topic Title' key={'1'}>
                     <AntDIcons.EditOutlined color='#4abec7' onClick={(e) => editTitleClick(e, item.title, index, 'EditLesson')} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                  <Tooltip placement="bottom" title='Remove Topic' key={'2'}>
                     <Popconfirm
                        title="Delete this TOPIC ?"
                        onConfirm={(e) => handleDeleteRequest(e, item.id)}
                        okText="Yes"
                        cancelText="No">

                        <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
               </Space>
            </div>
         </>
      )
   }

   const lessonCount = (index) => {
      return (
         <>
            {
               topic[index].contents?.length > 0
                  ?
                  <div style={{ fontWeight: "bold" }}>Total of <span style={{ fontWeight: "bold", color: "red" }}>{topic[index].contents.length}</span> {"Resources/Quiz"}</div>
                  :
                  <div>No Resource/Quiz included</div>
            }
         </>
      )
   }

   const dateEffectiveChange = (date, datestring) => {
      setDateEffective(datestring)
   }

   return <>
      <div className='topicHeader'>
         <div style={{ paddingTop: "7px", paddingBottom: "10px" }}><h5>Topic Content</h5></div>
         <div style={{
            fontSize: "20px",
            paddingLeft: "8px",
            cursor: "pointer"
         }} ><ExportOutlined onClick={() => props.toggle()} /></div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
         {viewMode === "edit" && <div style={{ width: "150px", paddingRight: "5px" }}><Button className='button-shadow' type="primary" block icon={<PlusOutlined />} onClick={() => addTopic()} shape="round" size="middle">Add Topic Title</Button></div>}
         {viewMode === "edit" && <div style={{ width: "140px", paddingRight: "5px" }}><Button className='button-shadow' type="primary" block icon={<SaveOutlined />} onClick={() => props.saveLesson()} shape="round" size="middle">Save Topic</Button></div>}
         {/*                 {viewMode === "edit" && <div style={{width : "120px"}}><Button type="primary"  block icon={<SaveOutlined /> }  onClick={() => addQuiz()}  shape="round" size="small">Add Quiz</Button></div>}   */}
      </div>
      <Divider />
      {/*         <div style={{marginLeft : '10px', marginRight : '10px', display : "flex", justifyContent :"center", position : "relative", paddingTop : "10px"}}>
           <Button type="primary"  block icon={<FileAddOutlined /> }  onClick={() => addTopic()}  shape="round" size="medium">Add Topic</Button>
        </div> */}
      {(topic && viewMode === "edit") && <DragDropContext onDragEnd={_onDragEnd}>
         <Droppable droppableId="droppable">
            {(provided, snapshot) => (
               <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
               >
                  {topic.map((item, index) => (
                     <Draggable key={item.id?.toString()} draggableId={item.id?.toString()} index={index}>
                        {(provided, snapshot) => (
                           <div >
                              <div
                                 ref={provided.innerRef}
                                 {...provided.draggableProps}
                                 style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                 )}
                              >
                                 <div className='individualTopic' >
                                    <div {...provided.dragHandleProps} >
                                       <Collapse accordion defaultActiveKey={openPanel} ghost >
                                          <Panel style={{ font: "normal normal bold 14px/18px Inter;" }}
                                             header={<div>
                                                <div className="TopicTitle">
                                                   <span style={{ fontWeight: "bold", fontSize: "14px" }}>{`Topic ${index + 1} : ${item.title}`}</span>
                                                </div>
                                                <div className="TopicTitle">
                                                   {lessonCount(index)}
                                                </div>
                                             </div>
                                             }
                                             key={index}
                                             extra={menuExtra(index, item)} >
                                             <LessonSection
                                                lessons={item.contents}
                                                index={index}
                                                editLesson={props.editLesson}
                                                lessonClick={props.lessonClick} mode={props.mode}
                                                saveLesson={props.saveLesson}
                                                refreshLesson={props.refreshLesson}
                                             />
                                          </Panel>
                                       </Collapse>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        )}
                     </Draggable>
                  ))}
                  {provided.placeholder}
               </div>
            )}
         </Droppable>
      </DragDropContext>}

      {(topic && viewMode === "view") && <>
         <div>
            {topic.map((item, index) => (
               <div >
                  <div>
                     <div className='individualTopic' >
                        <div>
                           <Collapse accordion ghost >
                              <Panel style={{ font: "normal normal bold 14px/18px Inter;" }}
                                 header={<div>
                                    <div className="TopicTitle">
                                       <span style={{ fontWeight: "bold", fontSize: "14px" }}>{`Topic ${index + 1} : ${item.title}`}</span>
                                    </div>
                                    <div className="TopicTitle">
                                       {lessonCount(index)}
                                    </div>
                                 </div>
                                 } key={index}>
                                 <LessonSection lessons={item.contents} index={index} editLesson={props.editLesson} lessonClick={props.lessonClick} mode={props.mode} />
                              </Panel>
                           </Collapse>
                        </div>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </>}

      <Modal
         key="Modal-Add-Lesson"
         title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" /> Add Resource Description</div>}
         okButtonProps={{ style: { display: 'none' } }}
         destroyOnClose={true}
         centered
         open={visibleResources}
         onOk={() => setVisibleResources(false)}
         onCancel={() => setVisibleResources(false)}
      >
         <AddLesson topicIdx={currentTopicEditIdx} modalClose={addFinish} dateEffective={dateEffective} />
      </Modal>

      <Modal
         key="Modal-Add-Mastery"
         title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" /> Add Formative Test/ Mastery</div>}
         okButtonProps={{ style: { display: 'none' } }}
         destroyOnClose={true}
         centered
         open={visibleExam}
         onOk={() => setVisibleExam(false)}
         onCancel={() => setVisibleExam(false)}
      >
         <span>Effective Date : </span>
         <RangePicker
            style={{ width: '100%' }}
            format="YYYY-MM-DD hh:mm a"
            // showTime={{ defaultValue: moment('00:00:00', 'hh:mm a') }}
            showTime={{ format: 'hh:mm A', use12Hours: true }}
            onChange={dateEffectiveChange}
         />
         <div style={{ marginTop: "8px" }}>
            <div style={{ margin: "auto" }} >
               <Select style={{ width: 'calc(100% - 180px)' }} onChange={handleChangeQuiz}>
                  {
                     formativeQuiz.map((item) => {
                        return (
                           <Option value={item.id}>{item.title}</Option>
                        )
                     })
                  }
               </Select>
               <Button className='button-shadow' type="primary" onClick={() => addFormativeQuiz(currentTopicEditIdx)} style={{ borderRadius: "7px", marginLeft: "5px", width: "150px" }}>Add Formative</Button>
            </div>
         </div>
         <div style={{ marginTop: "8px" }}>
            <div style={{ margin: "auto" }} >
               <Select disabled style={{ width: 'calc(100% - 180px)' }} >
               </Select>
               <Button className='button-shadow' disabled type="primary" style={{ borderRadius: "7px", marginLeft: "5px", width: "150px" }}>Add Mastery</Button>
            </div>
         </div>
      </Modal>

      <Modal
         key="Modal-Add-Topic"
         title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" /> {mode === "EditLesson" ? "Update Topic Title" : "Add Topic Title"}</div>}
         okButtonProps={{ style: { display: 'none' } }}
         destroyOnClose={true}
         centered
         visible={visibleEdit}
         onOk={() => setVisibleEdit(false)}
         onCancel={() => setVisibleEdit(false)}

      >
         <Input.Group compact>
            <Input style={{ width: '300px' }} value={topicTitleEdit} onChange={(e) => setTopicTitleEdit(e.target.value)} />
            <Button className='button-shadow' type="primary" onClick={() => editOkTitle()} style={{ borderTopRightRadius: "7px", borderBottomRightRadius: "7px" }}>{mode === "EditLesson" ? "Update Title" : "Add Topic Title"}</Button>
         </Input.Group>
      </Modal>
   </>

}
