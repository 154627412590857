/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
// import { Layout } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import uuid from 'react-uuid';
import { UserOutlined, CloudUploadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input, Divider, Typography, Modal, Row, Col, Upload, Image, message, Radio, Space, DatePicker, Spin } from 'antd';
import "./Application.scss"
import Utils from '../../misc/Utils';
//import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import moment from 'moment';
//import Compressor from 'compressorjs';

// import { type } from '@testing-library/user-event/dist/type';
// const { Header, Footer, Sider, Content } = Layout;
const { Title, Text } = Typography;
const { Dragger } = Upload;

export default function Application() {
   const [form] = Form.useForm();

   // var subdomain = "";

   var host = window.location.host
   var subdomain = host.split('.')[0]
   if (subdomain.indexOf("localhost") !== -1)
      subdomain = "localhost"

   const [pict, setPict] = useState(null);
   const [bc, setBC] = useState(null);
   const [rc, setRC] = useState(null);
   const [pop, setPop] = useState(null);

   const [docToView, setDocToView] = useState(null);
   const [updating, setUpdating] = useState(false);
   const [filesForUpload, setFilesForUpload] = useState([]);
   const [fileSeq, setFileSeq] = useState([]);
   const [courseOffering, setCourseOffering] = useState([]);
   const [firstChoice, setFirstChoice] = useState(null);
   const [secondChoice, setSecondChoice] = useState(null);
   const [formValues, setFormValues] = useState(null);
   // const [oaData, setOAData] = useState({
   //    course_offering: [],
   //    bank_details: null,
   //    testing_fee: null,
   // });

   const [oaData, setOAData] = useState(null);

   const [modalVisible, setModalVisible] = useState(false);
   const [modalDisclaimer, setModalDisclaimer] = useState(false);
   const [modalSubmit, setModalSubmit] = useState(false);

   useEffect(() => {
      // getCourseOffering();
      getOAData();
   }, []);

   const getOAData = () => {
      var url = `${process.env.REACT_APP_API_CPH}/getoadata`;

      axios.get(url).then((response) => {
         if (response.data) {
            var data = response.data;

            setOAData({
               course_offering: JSON.parse(data.course_offering),
               bank_details: JSON.parse(data.bank_details),
               testing_fee: data.testing_fee,
               online_payment: JSON.parse(data.online_payment)
            });
         }
      }, (error) => {
         // setLoading(false);
         console.log(error.message);
      });
   }

   const getCourseOffering = () => {
      var url = `${process.env.REACT_APP_API_CPH}/getcourseoffering`;

      axios.get(url).then((response) => {
         if (response.data) {
            setCourseOffering(response.data);
         }
      }, (error) => {
         // setLoading(false);
         console.log(error.message);
      });
   }

   const beforeDocUpload = (file, doctype) => {
      var isValid = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';

      if (!isValid) {
         message.error('You can only upload JPG and PNG files.');
      }

      var isLt = file.size / 1024 / 1024 <= 10;
      if (!isLt) {
         message.error('Image must smaller than 2MB.');
      }

      if (isValid && isLt) {
         handleUpload(file, doctype);
      }
   }

   const handleUpload = (file, doctype) => {
      const formData = new FormData();
      formData.append('file', file);

      var list = filesForUpload;
      var seqlist = fileSeq;

      //-- check if already exist
      if (seqlist.indexOf(doctype) > -1) {
         list[seqlist.indexOf(doctype)] = file;
      }
      else {
         list.push(file);
         setFilesForUpload(list);

         seqlist.push(doctype);
         setFileSeq(seqlist);
      }

      Utils.getBase64(file, imageb64 => {
         if (doctype === 'sp')
            setPict(imageb64);

         if (doctype === 'bc')
            setBC(imageb64);

         if (doctype === 'rc')
            setRC(imageb64);

         if (doctype === 'pop')
            setPop(imageb64);
      });

      // new Compressor(file, {
      //    quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
      //    success: (compressedResult) => {
      //       // compressedResult has the compressed file.
      //       // Use the compressed file to upload the images to your server.        
      //       //   setCompressedFile(res)
      //       // formUploads.append('files', compressedResult);
      //       var list = filesForUpload;
      //       list.push(compressedResult);
      //       setFilesForUpload(list);

      //       Utils.getBase64(compressedResult, imageb64 => {
      //          if (doctype === 'sp')
      //             setPict(imageb64);

      //          if (doctype === 'bc')
      //             setBC(imageb64);

      //          if (doctype === 'rc')
      //             setRC(imageb64);
      //       });
      //    },
      // });
   };

   const renderPreviewer = (document) => {
      // if (document !== null && document !== '') {

      //    var type = document.includes('pdf') ? 'pdf' : 'image';

      //    if (type === 'pdf')
      //       return <>
      //          <RenderPDFViewer url={document} height={"calc(100vh - 185px)"} showdownload={false} showprint={false} showpagecontrols={true} showfullscreen={true} />
      //       </>

      //    if (type === 'image')
      //       return <>
      //          <div
      //             className={'modal-document-viewer'}
      //             style={{ height: "calc(100vh - 195px)" }}
      //          >
      //             <Image
      //                src={document}
      //                style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
      //             />
      //          </div>
      //       </>
      // }
      return <>
         <div
            className={'modal-document-viewer'}
            style={{ height: "calc(100vh - 195px)" }}
         >
            <Image
               src={document}
               style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
            />
         </div>
      </>
   }

   const handleViewDoc = (doc) => {
      setDocToView(doc);
      setModalVisible(true);
   }

   const handleSaveApplication = (data) => {
      if (filesForUpload.length > 0) {
         setUpdating(true);

         var formData = new FormData();

         filesForUpload.forEach(file => {
            formData.append('files', file);
         });

         var upload_url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMultiple?s3path=${process.env.REACT_APP_S3_ONLINE_APPLICATION_PATH}`;
         axios.post(upload_url, formData).then(response => {
            if (response.data !== '') {

               var uploadedFiles = response.data.split(',');

               var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
               let id = 'online_application_' + o2o_mode + "_" + uuid();

               var other_data = {
                  number_street: data.number_street,
                  barangay: data.barangay,
                  district: data.district,
                  city_municipality: data.city_municipality,
                  province: data.province,
                  region: data.region,
                  nationality: data.nationality,
                  civil_status: data.civil_status,
                  employment_status: data.employment_status,
                  birth_place: data.birth_place,
                  educational_attainment: data.educational_attainment,
                  parent_guardian_name: data.parent_guardian_name,
                  parent_guardian_address: data.parent_guardian_address,
                  first_choice: firstChoice,
                  second_choice: secondChoice,
               }

               var documents = {
                  birth_certificate: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/online-application/${uploadedFiles[fileSeq.indexOf('bc')]}`,
                  report_card: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/online-application/${uploadedFiles[fileSeq.indexOf('rc')]}`,
                  proof_of_payment: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/online-application/${uploadedFiles[fileSeq.indexOf('pop')]}`,
               }

               var applicant_data = {
                  // id: mode === 'edit' ? applicationID : id,
                  id,
                  last_name: data.last_name,
                  first_name: data.first_name,
                  middle_name: data.middle_name,
                  gender: data.gender,
                  birth_date: moment(data.birth_date).format('YYYY-MM-DD'),
                  email: data.email,
                  contact_number: data.contact_no,
                  other_info: JSON.stringify(other_data),
                  picture: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/online-application/${uploadedFiles[fileSeq.indexOf('sp')]}`,
                  documents: JSON.stringify(documents),
                  application_status: 'pending',
                  bank_details: data.bank_details,
               }

               const url = `${process.env.REACT_APP_API_CPH}/upsertonlineapplicant`;
               axios.post(url, applicant_data).then(response => {
                  console.log(response);

                  if (response.data === 2) {
                     setUpdating(false);

                     Modal.info({
                        title: 'Online Application',
                        content: 'The applicant you entered has already submitted an application.',
                        centered: true,
                     });
                  } else {
                     setUpdating(false);
                     Modal.success({
                        title: 'Online Application',
                        content: 'Application posted.',
                        centered: true,
                     });

                     //-- Clear variables
                     form.resetFields();
                     setPict(null)
                     setBC(null);
                     setRC(null);
                     setPop(null);
                     setFirstChoice(null);
                     setSecondChoice(null);
                     setFileSeq([]);
                     setFilesForUpload([]);
                  }
               }, (error) => {
                  setUpdating(false);
                  console.log(error.message);
                  Modal.error({
                     title: 'Online Application',
                     content: 'It seems that there was a problem encountered while processing your request.',
                     centered: true
                  });
               });

               // setFilesForUpload([]);
            } else {
               setUpdating(false);
               Modal.error({
                  title: 'Online Application',
                  content: 'It seems that there was a problem encountered while processing your request.',
                  centered: true
               });
            }
         }, (error) => {
            setUpdating(false);
            console.log(error.message);
            Modal.error({
               title: 'Online Application',
               content: 'It seems that there was a problem encountered while processing your request.',
               centered: true
            });
         });
      }
   }

   const handleSaveApplication2 = (data) => {
      if (filesForUpload.length > 0) {
         setUpdating(true);

         var formData = new FormData();

         filesForUpload.forEach(file => {
            formData.append('files', file);
         });

         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         let id = 'online_application_' + o2o_mode + "_" + uuid();

         var other_data = {
            number_street: data.number_street,
            barangay: data.barangay,
            district: data.district,
            city_municipality: data.city_municipality,
            province: data.province,
            region: data.region,
            nationality: data.nationality,
            civil_status: data.civil_status,
            employment_status: data.employment_status,
            birth_place: data.birth_place,
            educational_attainment: data.educational_attainment,
            parent_guardian_name: data.parent_guardian_name,
            parent_guardian_address: data.parent_guardian_address,
            first_choice: firstChoice,
            second_choice: secondChoice,
         }

         var documents = {
            birth_certificate: '',
            report_card: '',
            proof_of_payment: '',
         }

         var applicant_data = {
            // id: mode === 'edit' ? applicationID : id,
            id,
            last_name: data.last_name,
            first_name: data.first_name,
            middle_name: data.middle_name,
            gender: data.gender,
            birth_date: moment(data.birth_date).format('YYYY-MM-DD'),
            email: data.email,
            contact_number: data.contact_no,
            other_info: JSON.stringify(other_data),
            picture: null,
            documents: JSON.stringify(documents),
            application_status: 'pending',
            bank_details: data.bank_details,
            files_for_upload: formData,
            file_sequence: fileSeq,
         }

         const url = `${process.env.REACT_APP_API_CPH}/upsertonlineapplicant`;
         axios.post(url, applicant_data).then(response => {
            console.log(response);

            if (response.data === 2) {
               setUpdating(false);

               Modal.info({
                  title: 'Online Application',
                  content: 'The applicant you entered has already submitted an application.',
                  centered: true,
               });
            } else {
               setUpdating(false);
               Modal.success({
                  title: 'Online Application',
                  content: 'Application posted.',
                  centered: true,
               });

               //-- Clear variables
               form.resetFields();
               setPict(null)
               setBC(null);
               setRC(null);
               setPop(null);
               setFirstChoice(null);
               setSecondChoice(null);
               setFileSeq([]);
               setFilesForUpload([]);
            }
         }, (error) => {
            setUpdating(false);
            console.log(error.message);
            Modal.error({
               title: 'Online Application',
               content: 'It seems that there was a problem encountered while processing your request.',
               centered: true
            });
         });
      }
   }

   const onFinish = (values) => {
      setModalSubmit(true);
      setFormValues(values);
   };

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const handleFirstChoice = (value) => {
      setFirstChoice(value);

      if (secondChoice === value) {
         Modal.info({
            title: 'Online Application',
            content: 'Your first choice course and second choice course should not be the same.',
            centered: true,
            // onOk: () => setFirstChoice(null)
         });
      }
   }

   const handleSecondChoice = (value) => {
      setSecondChoice(value);

      if (firstChoice === value) {
         Modal.info({
            title: 'Online Application',
            content: 'Your first choice course and second choice course should not be the same.',
            centered: true,
            // onOk: () => setSecondChoice(null)
         });
      }
   }

   const handleSubmitApplication = (values) => {
      setModalSubmit(false);

      var canContinue = false;
      if (oaData.testing_fee !== '0')
         canContinue = pict !== null && bc !== null && rc !== null && pop !== null;
      else
         canContinue = pict !== null && bc !== null && rc !== null;

      if (canContinue) {
         if (firstChoice !== secondChoice) {
            handleSaveApplication(values);
            console.log('Success:', values);
         }
         else {
            Modal.info({
               title: 'Online Application',
               content: (<>
                  Please select two different course offering choices.
               </>),
               centered: true
            });
         }
      }
      else {
         Modal.info({
            title: 'Online Application',
            content: (<>
               Please provide the following.
               <ul>
                  {
                     pict === null &&
                     <li>
                        Picture
                     </li>
                  }
                  {
                     bc === null &&
                     <li>
                        Birth Certificate(PSA Accredited)
                     </li>
                  }
                  {
                     rc === null &&
                     <li>
                        Report Card or Diploma
                     </li>
                  }
                  {
                     pop === null && oaData.testing_fee !== '0' &&
                     <li>
                        Proof of Payment
                     </li>
                  }
               </ul>
            </>),
            centered: true
         });
      }
      // if (values.disclaimer === 'agree') {

      // }
      // else {
      //    Modal.info({
      //       title: 'Online Application',
      //       content: 'You must agree with privacy disclaimer before you can proceed.',
      //       centered: true
      //    });
      // }
   }

   return (
      <>
         <Spin size='large' spinning={updating} tip="Submitting...Please wait.">
            <div style={{ width: '100%', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
               <div className="header-logo" >
                  {/* <Image preview={false} src="/images/brainee_logo_new.png" height="60px" alt="BRAINEE" /> */}
                  <Image preview={false} src="/images/db1tvet_logo.png" height="60px" alt="DB1TVET" />
               </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto', backgroundColor: '#F2F2F2' }}>
               {/* <Card
               className='custom-card'
               style={{ maxWidth: '1000px', height: '100%' }}
            > */}
               <div style={{ marginTop: '35px', marginBottom: '20px', width: '100%', maxWidth: '1000px', height: '100%', border: '1px solid #ccc', borderRadius: '7px', backgroundColor: '#FFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                  <div className='block-container'>
                     <div className="login-header align-left" style={{ margin: '35px 35px' }}>
                        <h3><span>APPLICATION</span> FORM</h3>
                        <p className="text-muted" style={{ fontSize: '18px' }}>Learner's Profile Form</p>
                     </div>

                     <div className='align-right' style={{ width: '165px', height: '165px', paddingTop: '35px', paddingRight: '35px' }}>
                        <Dragger
                           showUploadList={false}
                           style={{ backgroundColor: '#fff', borderRadius: '7px', padding: '0px', border: '1px solid #ccc' }}
                           beforeUpload={(file) => beforeDocUpload(file, 'sp')}
                           maxCount={1}
                           accept=".png,.jpg,.jpeg"
                        >
                           {
                              pict !== null
                                 ?
                                 <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: '#FFF',
                                    borderRadius: '7px',
                                    height: '100px',
                                 }}>
                                    {/* <Image src={questionImage} preview={false} width={'100%'} /> */}
                                    <Image src={pict} preview={false} height={'100%'} />
                                 </div>
                                 :
                                 <>
                                    <UserOutlined />
                                    <div className="ant-upload-text">Upload</div>
                                 </>
                           }
                        </Dragger>
                     </div>
                  </div>

                  <Form
                     form={form}
                     // name="application"
                     // initialValues={{ remember: true, }}
                     onFinish={onFinish}
                     onFinishFailed={onFinishFailed}
                     autoComplete="off"
                     layout='vertical'
                     style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}
                  >
                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                        <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Course Offering</Title>
                     </Divider>
                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                           <Form.Item
                              name={'first_choice'}
                              label="First Choice"
                              rules={[{ required: true, message: 'First Choice is required' },]}
                              style={{ marginBottom: '10px' }}
                              value={firstChoice}
                           >
                              <Radio.Group
                                 name={'first_choice'}
                                 id={'first_choice'}
                                 onChange={(e) => handleFirstChoice(e.target.value)}

                              >
                                 {
                                    oaData !== null &&
                                    <Space direction='vertical'>
                                       {oaData.course_offering.map(({ value, name }, idx) => {
                                          return <Radio value={value}>{name}</Radio>
                                       })}
                                    </Space>
                                 }

                              </Radio.Group>
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                           <Form.Item
                              name={'second_choice'}
                              label="Second Choice"
                              rules={[{ required: true, message: 'Second Choice is required' },]}
                              style={{ marginBottom: '10px' }}
                              value={secondChoice}
                           >
                              <Radio.Group
                                 id={'second_choice'}
                                 name='second_choice'
                                 onChange={(e) => handleSecondChoice(e.target.value)}
                              >
                                 {
                                    oaData !== null &&
                                    <Space direction='vertical'>
                                       {oaData.course_offering.map(({ value, name }, idx) => {
                                          return <Radio value={value}>{name}</Radio>
                                       })}
                                    </Space>
                                 }
                              </Radio.Group>
                           </Form.Item>
                        </Col>
                     </Row>
                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                        <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Learner's Profile</Title>
                     </Divider>

                     <Row gutter={12}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                           <h6>Name</h6>
                        </Col>
                     </Row>
                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'last_name'}
                              label="Last Name, Extension (eg. Jr., Sr.)"
                              rules={[{ required: true, message: 'Last Name is required' }]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'last_name'} name={'last_name'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'first_name'}
                              label="First Name"
                              rules={[{ required: true, message: 'First Name is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'first_name'} name={'first_name'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'middle_name'}
                              label="Middle Name"
                              // rules={[{ required: true, message: 'Middle Name is required' }]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'middle_name'} name={'middle_name'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                     </Row>
                     <Row gutter={12}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                           <h6>Complete Permanent Mailing Address</h6>
                        </Col>
                     </Row>
                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'number_street'}
                              label="Number, Street"
                              //rules={[{ required: true, message: ''},]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'number_street'} name={'number_street'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'barangay'}
                              label="Barangay"
                              rules={[{ required: true, message: 'Barangay is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'barangay'} name={'barangay'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'district'}
                              label="District"
                              //rules={[{ required: true, },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'district'} name={'district'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                     </Row>
                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'city_municipality'}
                              label="City/Municipality"
                              rules={[{ required: true, message: 'City/Municipality is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'city_municipality'} name={'city_municipality'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'province'}
                              label="Province"
                              //rules={[{ required: true, },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'province'} name={'province'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'region'}
                              label="Region"
                              rules={[{ required: true, message: 'Region is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'region'} name={'region'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                     </Row>
                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'email'}
                              label="Email Address"
                              rules={[{ required: true, type: 'email', message: 'Email is required' }]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'email'} name={'email'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'contact_no'}
                              label="Contact No."
                              rules={[{ required: true, message: 'Contact No. is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'contact_no'} name={'contact_no'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'nationality'}
                              label="Nationality"
                              rules={[{ required: true, message: 'Nationality is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'nationality'} name={'nationality'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                     </Row>

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                        <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Personal Information</Title>
                     </Divider>

                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'gender'}
                              label="Sex"
                              rules={[{ required: true, message: 'Gender is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Radio.Group
                                 id={'gender'}
                                 name={'gender'}
                              // onChange={(e) => onShowResultChange(e)}
                              >
                                 {/* <Space direction='vertical'> */}
                                 <Radio value="male">Male</Radio>
                                 <Radio value="female">Female</Radio>
                                 {/* </Space> */}
                              </Radio.Group>
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'civil_status'}
                              label="Civil Status"
                              rules={[{ required: true, message: 'Civil Status is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Radio.Group
                                 id={'civil_status'}
                                 name={'civil_status'}
                              // onChange={(e) => onShowResultChange(e)}
                              >
                                 {/* <Space direction='vertical'> */}
                                 <Radio value="single">Single</Radio>
                                 <Radio value="married">Married</Radio>
                                 <Radio value="widower">Widower</Radio>
                                 <Radio value="separated">Separated</Radio>
                                 <Radio value="solo_parent">Solo Parent</Radio>
                                 {/* </Space> */}
                              </Radio.Group>
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                           <Form.Item
                              name={'employment_status'}
                              label="Employment Status"
                              rules={[{ required: true, message: 'Employment Status is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Radio.Group
                                 id={'employment_status'}
                                 name={'employment_status'}
                              // onChange={(e) => onShowResultChange(e)}
                              >
                                 {/* <Space direction='vertical'> */}
                                 <Radio value="employed">Employed</Radio>
                                 <Radio value="unemployed">Unemployed</Radio>
                                 {/* </Space> */}
                              </Radio.Group>
                           </Form.Item>
                        </Col>
                     </Row>

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#eee', margin: '0px 0px' }} />

                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={6} lg={6}>
                           <Form.Item
                              name={'birth_date'}
                              label="Birth Date"
                              rules={[{ required: true, message: 'Birth Date is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <DatePicker
                                 id={'birth_date'}
                                 name={'birth_date'}
                                 style={{ width: "100%" }}
                                 format={'MMMM D, YYYY'}
                              // value={moment(staffData.date_of_birth)}
                              />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={18} lg={18}>
                           <Form.Item
                              name={'birth_place'}
                              label="Birth Place (City/Municipality, Province, Region)"
                              rules={[{ required: true, message: 'Birth Place is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'birth_place'} name={'birth_place'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                     </Row>

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#eee', margin: '0px 0px' }} />

                     <Row gutter={[12, 12]} style={{ width: "100%" }}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                           <Form.Item
                              name={'educational_attainment'}
                              label="Educational Attainment Before the Training (Trainee)"
                              rules={[{ required: true, message: 'Educational Attainment is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Radio.Group
                                 id={'educational_attainment'}
                                 name={'educational_attainment'}
                                 style={{ width: '100%' }}
                              // style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                              // onChange={(e) => onShowResultChange(e)}
                              >
                                 <Row>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="no_grade_completed">No Grade Completed</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="pre_school">Pre-School</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="high_school_undergraduate">High School Undergraduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="high_school_graduate">High School Graduate</Radio>
                                    </Col>
                                 </Row>
                                 <Row>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="elementary_undergraduate">Elementary Undergraduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="post_secondary_undergraduate">Post Secondary Undergraduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="college_undergraduate">College Undergraduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="college_graduate">College Graduate or Higher</Radio>
                                    </Col>
                                 </Row>
                                 <Row>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="elementary_graduate">Elementary Graduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="post_secondary_graduate">Post Secondary Graduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="junior_high_graduate">Junior High Graduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="senior_high_graduate">Senior High Graduate</Radio>
                                    </Col>
                                 </Row>
                                 <Row>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="als_hs_graduate">ALS/High School Graduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       <Radio value="tvet_post_secondary_graduate">TVET/Post Secondary Graduate</Radio>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       &nbsp
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                       &nbsp
                                    </Col>
                                 </Row>

                                 {/* <Radio value="no_grade_completed">No Grade Completed</Radio>
                              <Radio value="pre_school">Pre-School (Nursery/Kinder/Prep)</Radio>
                              <Radio value="high_school_undergraduate">High School Undergraduate</Radio>
                              <Radio value="high_school_graduate">High School Graduate</Radio>
                              <Radio value="elementary_undergraduate">Elementary Undergraduate</Radio>
                              <Radio value="post_secondary_undergraduate">Post Secondary Undergraduate</Radio>
                              <Radio value="college_undergraduate">College Undergraduate</Radio>
                              <Radio value="college_graduate">College Graduate or Higher</Radio>
                              <Radio value="elementary_graduate">Elementary Graduate</Radio>
                              <Radio value="post_secondary_graduate">Post Secondary Graduate</Radio>
                              <Radio value="junior_high_graduate">Junior High Graduate</Radio>
                              <Radio value="senior_high_graduate">Senior High Graduate</Radio> */}
                              </Radio.Group>
                           </Form.Item>
                        </Col>
                     </Row>

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#eee', margin: '0px 0px' }} />

                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={7} lg={7}>
                           <Form.Item
                              name={'parent_guardian_name'}
                              label="Parent/Guardian Name"
                              rules={[{ required: true, message: 'Parent/Guardian Name is required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'parent_guardian_name'} name={'parent_guardian_name'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={17} lg={17}>
                           <Form.Item
                              name={'parent_guardian_address'}
                              label="Parent/Guardian Complete Permanent Mailing Address"
                              rules={[{ required: true, message: 'Parent/Guardian Mailing Address is required' }]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'parent_guardian_address'} name={'parent_guardian_address'} style={{ borderRadius: '7px' }} />
                           </Form.Item>
                        </Col>
                     </Row>

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                        <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Upload Required Documents</Title>
                     </Divider>

                     <Row gutter={[12, 12]}>
                        {/* <Col xs={24} sm={24} md={12} lg={12}>
                           Birth Certificate (PSA Accredited) <Space><Upload beforeUpload={(file) => { beforeDocUpload(file, 'bc') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                              <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                           </Upload>
                              {
                                 bc !== null &&
                                 <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(bc)}><EyeOutlined /></Button>
                              }
                           </Space>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                           Report Card or Diploma <Space><Upload beforeUpload={(file) => { beforeDocUpload(file, 'rc') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                              <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                           </Upload>
                              {
                                 rc !== null &&
                                 <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(rc)}><EyeOutlined /></Button>
                              }
                           </Space>
                        </Col> */}
                        {/* <Col xs={24} sm={24} md={8} lg={8}>
                           Proof of Payment <Space><Upload beforeUpload={(file) => { beforeDocUpload(file, 'pop') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                              <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                           </Upload>
                              {
                                 rc !== null &&
                                 <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(pop)}><EyeOutlined /></Button>
                              }
                           </Space>
                        </Col> */}

                        <Col xs={24} sm={24} md={24} lg={24}>
                           {/* <ul> */}
                           <li style={{ marginBottom: '5px' }}>
                              Birth Certificate (PSA Accredited) <Space><Upload beforeUpload={(file) => { beforeDocUpload(file, 'bc') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                                 <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                              </Upload>
                                 {
                                    bc !== null &&
                                    <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(bc)}><EyeOutlined /></Button>
                                 }
                              </Space>
                           </li>
                           <li>
                              Report Card or Diploma <Space><Upload beforeUpload={(file) => { beforeDocUpload(file, 'rc') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                                 <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                              </Upload>
                                 {
                                    rc !== null &&
                                    <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(rc)}><EyeOutlined /></Button>
                                 }
                              </Space>
                           </li>
                           {/* </ul> */}
                        </Col>
                     </Row>

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                        <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Upload Proof of Payment</Title>
                     </Divider>

                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                           <li style={{ marginBottom: '5px' }}>Application / Testing Fee: ₱{oaData !== null ? oaData.testing_fee : ''}</li>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                           <li style={{ marginBottom: '5px' }}>Bank Accounts (Name - Account Number):</li>

                           {
                              oaData !== null &&
                              <Space direction='vertical'>
                                 {oaData.bank_details.map(({ name, account_no }, idx) => {
                                    return <li>{name} - {account_no}</li>
                                 })}
                              </Space>
                           }

                        </Col>
                     </Row>
                     <Row gutter={[12, 12]} style={{ marginTop: '15px' }}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                           <li><b>Online Payment</b></li>
                           {
                              oaData !== null &&
                              <>
                                 {
                                    oaData.online_payment !== null && oaData.online_payment !== undefined
                                       ?
                                       <Space direction='vertical'>
                                          {oaData.online_payment.map(({ name, url, ico }, idx) => {
                                             return <>
                                                <li style={{ marginBottom: '5px' }}>
                                                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                   <img src={ico} height={'20x'} width={'20px'} style={{ cursor: 'pointer' }} onClick={() => window.open(url, '_blank')} />&nbsp;
                                                   <Text style={{ cursor: 'pointer' }} onClick={() => window.open(url, '_blank')}>{name}</Text>
                                                </li>
                                             </>
                                          })}
                                       </Space>
                                       :
                                       <li style={{ marginBottom: '5px' }}>NOT AVAILABLE</li>
                                 }
                              </>
                           }
                           {/* <li style={{ marginBottom: '5px' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <img src="https://oss.clouddn.fucentgateway.com/phl/merchant/resource/images/gcashpay_ico.ico" height={'20x'} width={'20px'} style={{ cursor: 'pointer' }} onClick={() => window.open('https://pay.bananapay.cn/phl/qr.php?offlineinfonew=NzM5&pay_way=gcashpay', '_blank')} />&nbsp;
                              <Text style={{ cursor: 'pointer' }} onClick={() => window.open('https://pay.bananapay.cn/phl/qr.php?offlineinfonew=NzM5&pay_way=gcashpay', '_blank')}>GCash</Text>
                           </li>
                           <li style={{ marginBottom: '5px' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <img src="https://oss.clouddn.fucentgateway.com/phl/merchant/resource/images/unionpay_ico.ico" height={'20x'} width={'20px'} style={{ cursor: 'pointer' }} onClick={() => window.open('https://pay.bananapay.cn/phl/qr.php?offlineinfonew=NzM5&pay_way=unionpay', '_blank')} />&nbsp;
                              <Text style={{ cursor: 'pointer' }} onClick={() => window.open('https://pay.bananapay.cn/phl/qr.php?offlineinfonew=NzM5&pay_way=unionpay', '_blank')}>UnionPay</Text>
                           </li>
                           <li style={{ marginBottom: '5px' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <img src="https://oss.clouddn.fucentgateway.com/phl/merchant/resource/images/alipay_ico.ico" height={'20x'} width={'20px'} style={{ cursor: 'pointer' }} onClick={() => window.open('https://pay.bananapay.cn/phl/qr.php?offlineinfonew=NzM5&pay_way=alipay', '_blank')} />&nbsp;
                              <Text style={{ cursor: 'pointer' }} onClick={() => window.open('https://pay.bananapay.cn/phl/qr.php?offlineinfonew=NzM5&pay_way=alipay', '_blank')}>Alipay</Text>
                           </li>
                           <li style={{ marginBottom: '5px' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <img src="https://oss.clouddn.fucentgateway.com/phl/merchant/resource/images/weixin_ico.ico" height={'20x'} width={'20px'} style={{ cursor: 'pointer' }} onClick={() => window.open('https://pay.bananapay.cn/phl/qr.php?offlineinfonew=NzM5&pay_way=weixin', '_blank')} />&nbsp;
                              <Text style={{ cursor: 'pointer' }} onClick={() => window.open('https://pay.bananapay.cn/phl/qr.php?offlineinfonew=NzM5&pay_way=weixin', '_blank')}>WeChat Pay</Text>
                           </li> */}
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                           <li style={{ marginBottom: '5px' }}>
                              Proof of Payment <Space><Upload beforeUpload={(file) => { beforeDocUpload(file, 'pop') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                                 <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                              </Upload>
                                 {
                                    pop !== null &&
                                    <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(pop)}><EyeOutlined /></Button>
                                 }
                              </Space>
                           </li>
                        </Col>
                     </Row>
                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                           <Form.Item
                              name={'bank_details'}
                              label="Payment Details (Bank name/Reference number)"
                              rules={[{ required: oaData !== null && oaData.testing_fee !== '0' ? true : false, message: 'Payment Details Required' },]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Input id={'bank_details'} name={'bank_details'} style={{ borderRadius: '7px', paddingLeft: '10px' }} />
                           </Form.Item>
                        </Col>
                     </Row>

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                        <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Privacy Statement</Title>
                     </Divider>

                     {/* <Row gutter={[12, 12]} style={{ padding: '15px 0px 0px 0px' }}>
                        <Col xs={24} sm={24} md={12} lg={12} style={{ display: 'flex', justifyContent: 'right' }}>
                           <Form.Item
                              name={'disclaimer'}
                              rules={[{ required: true, message: 'Privacy Disclaimer is required' }]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Radio.Group
                                 id={'disclaimer'}
                                 name={'disclaimer'}
                              >
                                 <Radio value="agree">Agree</Radio>
                                 <Radio value="disagree">Disagree</Radio>
                              </Radio.Group>
                           </Form.Item>
                        </Col>
                     </Row> */}

                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                           <i>I hereby allow Don Bosco Training Centers to use/post my contact details, name, email, cellphone/landline nos. and other information I provided which may be used for processing of my scholarship application, for employment opportunities and for the survey of Don Bosco TVET programs.</i>
                        </Col>
                     </Row>

                     <Row gutter={[12, 12]} style={{ paddingTop: '20px' }}>
                        <Col xs={24} sm={24} md={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                           {/* <Text>By clicking "Submit Application", you agree to our </Text>&nbsp;<Text style={{ color: '#4ABEC7', cursor: 'pointer' }} onClick={() => setModalDisclaimer(true)}>privacy statement</Text>. */}
                           <Text>By clicking</Text>&nbsp;<Text style={{ color: '#4ABEC7', }}>Submit Application</Text><Text>, you agree to our privacy statement.</Text>
                        </Col>
                     </Row>

                     <Row gutter={[12, 12]} style={{ padding: '15px 0px 15px 0px' }}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                           {/* <Button className='button-shadow' type='primary' shape='round' htmlType="submit">Submit Application</Button> */}
                           <Button className='button-shadow' type='primary' shape='round' htmlType="submit">Submit Application</Button>
                        </Col>
                     </Row>


                  </Form>
               </div>
               {/* </Card> */}
            </div >
            <div style={{ width: '100%', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
               Powered by CAMPUSCLOUD IT CONSULTANCY ©2024
            </div>
         </Spin>

         <Modal
            key='preview-doc'
            title={"Preview "}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               {renderPreviewer(docToView)}
            </div>
         </Modal>

         <Modal
            key='disclaimer'
            title={"Privacy Statement"}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalDisclaimer}
            width='50%'
            onCancel={() => { setModalDisclaimer(false); }}
            onOk={() => { setModalDisclaimer(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>

            </div>
         </Modal>

         <Modal
            key='confirm_submit'
            title={"Submit Application"}
            centered
            destroyOnClose={true}
            open={modalSubmit}

            footer={[
               <Button key="notpaid" type='primary' shape='round' onClick={() => handleSubmitApplication(formValues)}>Submit</Button>,
               <Button key="close" type="primary" shape='round' onClick={() => setModalSubmit(false)}>Cancel</Button>
            ]}
         >
            <Text>REMINDER: Kindly verify your email address and contact number before submitting your application. These details are essential for effective communication during the processing of your application.</Text>
         </Modal>
      </>
   )
}
