import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Empty, message, Modal, Breadcrumb } from 'antd';
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StickyBox from "react-sticky-box";
import axios from 'axios';
import uuid from 'react-uuid';
import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
// import BraineeAssessmentTool from '../../components/assessment/BraineeAssessmentTool';
import AssessmentTools from '../../components/assessment/AssessmentTools';
// import { CardRequestHelper } from './Helpers';
import { CardRequestHelper } from '../../helper/AQHelper';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

import Split from 'react-split';
// import './SplitPanel.css';

const questionData = []

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let tempAssOptions = null;
let defaultAssOptions = {
   id: '',
   assessment_id: '',
   assigned_by: '1',
   duration: '1',
   allowed_attempts: '1',
   passing_percentage: '50',
   allow_result_viewing: '0',
   enable_timer: '0',
   start_date: '',
   end_date: '',
   assigned_students: '',
   term: ''
}

export function CreateManual(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'registrar')
         navigate('/home');
   }, []);

   // const[answerSheetData, setAnswerSheetData] = useState(questionData);
   const [answerSheetData, setAnswerSheetData] = useState(() => {
      var questionsheet = JSON.parse(localStorage.getItem(user_id + "sa_asd"));
      if (questionsheet !== null) return questionsheet;
      else return [];
   });
   const [showAssessmentTools, setShowAssessmentTools] = useState(true);
   const [showAssignment, setShowAssignment] = useState(false);
   const [assOptions, setAssOptions] = useState(() => {
      var assoptns = JSON.parse(localStorage.getItem(user_id + "sa_ass_options"));
      if (assoptns !== null) return assoptns;
      else return defaultAssOptions;
   });

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: location.state.title,
      Term: location.state.term,
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      // Type: location.state.type,
      Instruction: "",
   });

   const handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(answerSheetData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setAnswerSheetData(items);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleCardRequest = (method, type, data, idx) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData);

      //--Save to local storage in case something happens to the browser
      localStorage.removeItem(user_id + "sa_asd");
      localStorage.removeItem(user_id + "sa_ass_options");
      // localStorage.clear();

      // if (asd.length > 0) {
      //    localStorage.setItem(user_id + 'sa_asd', JSON.stringify(asd));
      //    localStorage.setItem(user_id + 'sa_ass_options', JSON.stringify(assOptions));
      // }

      setAnswerSheetData(asd);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      //-- Hide drawer on button click
      // setDrawerVisible(false);
   }

   const handleCardRequest2 = (method, type, data, idx, template) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData, template);

      setAnswerSheetData(asd);

      //--Save to local storage in case something happens to the browser
      localStorage.removeItem(user_id + "sa_asd");
      localStorage.removeItem(user_id + "sa_ass_options");

      // if (asd.length > 0) {
      //    localStorage.setItem(user_id + 'sa_asd', JSON.stringify(asd));
      //    localStorage.setItem(user_id + 'sa_ass_options', JSON.stringify(assOptions));
      // }

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleGenerateCardsFromAI = (data) => {
      //--Save to local storage in case something happens to the browser
      localStorage.removeItem(user_id + "sa_asd");
      localStorage.removeItem(user_id + "sa_ass_options");

      // if (data.length > 0) {
      //    localStorage.setItem(user_id + 'sa_asd', JSON.stringify(data));
      //    localStorage.setItem(user_id + 'sa_ass_options', JSON.stringify(assOptions));
      // }

      setAnswerSheetData(data);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleSave = (assinfo) => {
      // message.success(JSON.stringify(assOptions));
      // message.success(JSON.stringify(answerSheetData));
      const key = "updatable";

      if (assinfo.title !== '' && assinfo.level !== '' && assinfo.subject !== '' && assinfo.term !== '' && answerSheetData.length > 0) {
         message.loading({ content: "Saving assessment...", key });

         localStorage.removeItem(user_id + 'sa_asd');
         localStorage.removeItem(user_id + 'sa_ass_options');
         // localStorage.clear();

         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

         var ass_info = {
            Id: "ass_" + o2o_mode + "_" + uuid(),
            Title: assinfo.title,
            Term: assinfo.term,
            File_url: '',
            Question_type: "2",
            Created_by: user_id,
            Copied_from: null,
            Question_sheet: JSON.stringify(answerSheetData),
            Level: assinfo.level,
            Subject: assinfo.subject,
            // Type: location.state.type,
            Instruction: assinfo.instruction,
         }

         const url = `${process.env.REACT_APP_API_ASSESSMENT}/add?at=summative`;
         axios.post(url, ass_info).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               message.success({ content: 'Assessment was created.', key });
               Modal.success({
                  title: 'Institutional Assessment',
                  content: 'Assessment was created.',
                  centered: true,
                  onOk: navigate(-1),
               });
            }
         }, (error) => {
            console.log(error.message);
            message.error({ content: 'Assessment creation failed.', key });
            Modal.error({
               title: 'Institutional Assessment',
               content: 'Assessment creation failed.',
               centered: true
            });
         });
      } else {
         Modal.info({
            content: (
               <>
                  Please complete the folowing before saving.
                  <ul>
                     <li>
                        Title
                     </li>
                     <li>
                        Course
                     </li>
                     <li>
                        Subject
                     </li>
                     <li>
                        Term
                     </li>
                     <li>
                        Answer keys
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const showHideAssignment = () => {
      setShowAssignment(!showAssignment);
      setShowAssessmentTools(!showAssessmentTools);
   }

   const handleSetOptions = (data) => {
      // message.info(JSON.stringify(data));
      tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);
   }

   const handleBack = (data) => {
      // message.info(JSON.stringify(data));
      setShowAssignment(!showAssignment);
      setShowAssessmentTools(!showAssessmentTools);
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/assessment', { state: { activetab: "1" } })}>Institutional Assessments</a></li>
                           <li><span>/</span></li>
                           <li className="active">Create</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <Link to="/assessment">Institutional Assessment</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Create</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>

                     {answerSheetData.length > 0 ?
                        <div className='answer-sheet-content2'>
                           <SimpleBarReact>
                              <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
                                 <Droppable droppableId="questions">
                                    {(provided) => (
                                       <ul className="questions" {...provided.droppableProps} ref={provided.innerRef} style={{ paddingRight: "15px" }}>
                                          {answerSheetData.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                             if (type === "section") {
                                                qcardIdx = 1;
                                                idxCol.push(sectionIdx);
                                                sectionIdx++;
                                             } else {
                                                idxCol.push(qcardIdx);
                                                qcardIdx++;
                                             }

                                             return (
                                                <Draggable key={id} draggableId={id} index={idx}>
                                                   {(provided) => (
                                                      <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                         <BraineeQuestionCard
                                                            key={id}
                                                            index={idxCol[idx]}
                                                            id={idx}
                                                            type={type}
                                                            points={points}
                                                            correct={correct}
                                                            option_labels={option_labels}
                                                            question={question}
                                                            image_url={image_url}
                                                            question_type={'2'}
                                                            mode={'create'}
                                                            parentCallback={handleCardRequest}
                                                            template={template}
                                                            question_image={question_image}
                                                            question_audio={question_audio}
                                                            question_video={question_video}
                                                            // subject_name={location.state.subjectname}
                                                            templateReplaceCallback={handleCardRequest2}
                                                            assessment_type={'summative'}
                                                         />
                                                      </li>
                                                   )}
                                                </Draggable>
                                             );
                                          })}
                                          {provided.placeholder}
                                       </ul>
                                    )}
                                 </Droppable>
                              </DragDropContext>
                           </SimpleBarReact>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Empty description='No items added.' />
                        </div>
                     }
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <div className='answer-sheet-content'>
                        {showAssignment &&
                           <AssessmentAssignTool
                              mode={"normal"}
                              options={assOptions}
                              parentCallback={handleSetOptions}
                              backCallback={handleBack} />
                        }

                        {showAssessmentTools &&
                           <SimpleBarReact>
                              {/* <AssessmentTools
                                 key={"createManual"}
                                 data={assInfo}
                                 cardRequestCallback={handleCardRequest}
                                 showHideAssCallback={showHideAssignment}
                                 saveCallback={handleSave}
                                 showToggle={false}
                                 mode={"create"} /> */}
                              <AssessmentTools
                                 key={"createManual"}
                                 data={assInfo}
                                 cardRequestCallback={handleCardRequest2}
                                 saveCallback={handleSave}
                                 showToggle={true}
                                 mode={"create"}
                                 type={'manual'}
                                 assessment_type={'summative'}
                                 generateCardsFromAI={handleGenerateCardsFromAI}
                              />
                           </SimpleBarReact>
                        }
                     </div>
                  </div>
               </div>
            </Split>
         </div>

         {/* <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li><a href="#">Home</a></li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/assessment', {state: {activetab: "1"}})}>Institutional Assessments</a></li>
                     <li><span>/</span></li>
                     <li><a className="active" href="#">Create</a></li>
                  </ul>     
               </div>   
               
               {answerSheetData.length > 0 ?
               <div className='answer-sheet-content2'>
                  <SimpleBarReact>
                     <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
                        <Droppable droppableId="questions">
                           {(provided) => (
                              <ul className="questions" {...provided.droppableProps} ref={provided.innerRef}>
                                 {answerSheetData.map(({id, type, points, correct, option_labels, question, image_url}, idx) => { 

                                    if (type == "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                    }                                    

                                    return (
                                       <Draggable key={id} draggableId={id} index={ idx }>
                                          {(provided) => (
                                             <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <QuestionCard 
                                                   key={id}
                                                   index={ idxCol[idx] } 
                                                   id={idx} 
                                                   type={type} 
                                                   points={points} 
                                                   correct={correct} 
                                                   option_labels={option_labels} 
                                                   question={question}
                                                   image_url={image_url}
                                                   question_type={'2'}
                                                   mode={'create'}
                                                   parentCallback={handleCardRequest} />                                          
                                             </li>
                                          )}
                                       </Draggable>
                                    );
                                 })}
                                 {provided.placeholder}
                              </ul>
                           )}
                        </Droppable>
                     </DragDropContext>
                  </SimpleBarReact>                  
               </div>
               :
               <div className='temp-answer-sheet-content2'>
                  <Empty description='No items added.'/>
               </div>
               }
            </div>
         </div>

         <div className="notice-area" style={{display:"block"}}> 
            <div className="notice-item">
               <div className='answer-sheet-content'>
                  {showAssignment && 
                     <AssessmentAssignTool 
                        mode={"normal"}
                        options={assOptions} 
                        parentCallback={handleSetOptions} 
                        backCallback={handleBack} />
                  }

                  {showAssessmentTools && 
                     <StickyBox style={{zIndex: 1000}}>
                        <AssessmentTools 
                           key={"createManual"}
                           data={assInfo} 
                           cardRequestCallback={handleCardRequest} 
                           showHideAssCallback={showHideAssignment} 
                           saveCallback={handleSave} 
                           showToggle={false}
                           mode={"create"} />
                     </StickyBox>
                  }
               </div>                  
            </div>
         </div> */}
      </>
   )
}
