import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
   Input, Table, Typography, Space, Tooltip, Button, Select, Row, Col, Popconfirm,
   Modal, Spin, Breadcrumb, Empty
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import uuid from 'react-uuid';
import moment from 'moment';

import Create from './Components/Create';
import Edit from './Components/Edit';
import Submissions from './Components/Submissions';

const { Text } = Typography;
const { Option } = Select;

export function Homeworks() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [homeworkList, setHomeworkList] = useState([]);
   const [subjectList, setSubjectList] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [level, setLevel] = useState(null);
   const [levelSubmission, setLevelSubmission] = useState(null);
   const [section, setSection] = useState(null);
   const [subject, setSubject] = useState(null);
   const [homeworkListLoading, setHomeworkListLoading] = useState(false);
   const [secsubLoading, setSecSubLoading] = useState(false);

   const [createModalVisible, setCreateModalVisible] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [hsModalVisible, setHSModalVisible] = useState(false);

   const [homeworkTitle, setHomeworkTitle] = useState("");
   const [homeworkID, setHomeoworkID] = useState("");
   const [homeworkData, setHomeworkData] = useState(null);

   const [creating, setCreating] = useState(false);
   const [updating, setUpdating] = useState(false);
   const [hsLoading, setHSLoading] = useState(false);

   const user_full_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   const [userList, setUserList] = useState([]);
   const [user_id, setUserId] = useState(Utils.getCurrentUserInfo(Enums.UserInfo.id));

   const { confirm } = Modal;

   // const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() === "admin") {
         var url = `${process.env.REACT_APP_API_LESSON}/listAllStaff`;
         axios.get(url).then((response) => {
            if (response.data) {
               let data = response.data;
               var users = data.filter((d) => d.id !== Number(user_id)).sort((a, b) => {
                  var aVal = a["first_Name"];
                  var bVal = b["first_Name"];
                  switch (typeof aVal) {
                     case 'string':
                        return aVal.localeCompare(bVal);
                     case 'number':
                        return aVal - bVal;
                     default:
                        throw new Error("Unsupported value to sort by");
                  }
               });
               var final = data.filter((d) => d.id === Number(user_id));
               Array.prototype.push.apply(final, users);
               setUserList(final)
            }
         }, (error) => {
            // setLoading(false);
            console.log(error.message);
         });
      }

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');
      else
         handleSearchHomeworks(user_id);

   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const HomeworkListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'class_id',
         key: 'class_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'section_id',
         key: 'section_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'subject_id',
         key: 'subject_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'created_by',
         key: 'created_by',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'level_desc',
         key: 'level_desc',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: title => (
            <Tooltip placement="right" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Level',
         key: 'level',
         dataIndex: 'level',
         sorter: (a, b) => { return (a.level === null ? '' : a.level).localeCompare(b.level === null ? '' : b.level) },
         width: '8%'
      },
      {
         title: 'Sections',
         key: 'section_names',
         dataIndex: 'section_names',
         sorter: (a, b) => { return (a.section_names === null ? '' : a.section_names).localeCompare(b.section_names === null ? '' : b.section_names) },
         ellipsis: {
            showTitle: false,
         },
         render: section_names => (
            <Tooltip placement="left" title={section_names}>
               <span style={{ cursor: "default" }}>{section_names}</span>
            </Tooltip>
         ),
         width: '14%'
      },
      {
         title: 'Subject',
         key: 'subject',
         dataIndex: 'subject',
         sorter: (a, b) => { return a.subject.localeCompare(b.subject) },
         ...getColumnSearchProps('subject'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="right" title={object}>
               <span style={{ cursor: "default" }}>{object}</span>
            </Tooltip>
         ),
         width: '14%'
      },
      {
         title: 'Created By',
         key: 'teacher',
         dataIndex: 'teacher',
         sorter: (a, b) => { return a.teacher.localeCompare(b.teacher) },
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="right" title={object}>
               <span style={{ cursor: "default" }}>{object}</span>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Homework Date',
         key: 'homework_date',
         dataIndex: 'homework_date',
         sorter: (a, b) => { return a.homework_date.localeCompare(b.homework_date) },
         width: '12%'
      },
      {
         title: 'Submit Date',
         key: 'submit_date',
         dataIndex: 'submit_date',
         sorter: (a, b) => { return a.submit_date.localeCompare(b.submit_date) },
         width: '12%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit'>
                     <AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                        onClick={() => handleEditHomework(payload.id)}
                     />
                  </Tooltip>
                  <Tooltip placement="top" title='Show Submissions'>
                     <AntDIcons.SolutionOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                        onClick={() => handleHomeworkSubmissions(payload.id, payload.class_id, payload.title)}
                     />
                  </Tooltip>
                  <Tooltip placement="top" title='Duplicate'>
                     <Popconfirm
                        title="Create a copy of this homework?"
                        onConfirm={() => handleDuplicateHomework(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.CopyOutlined color={'#4abec7'} style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  <Tooltip placement="top" title='Remove'>
                     <Popconfirm
                        title="Are you sure you want to delete this homework?"
                        onConfirm={() => handleRemoveHomework(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                     {/* <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleRemoveHomework(payload.id)} /> */}
                  </Tooltip>
               </Space>
            </>
         },
         width: '8%'
      },
   ].filter(item => !item.hidden);

   const handleSearchHomeworks = (userid) => {
      setHomeworkListLoading(true);
      var url = `${process.env.REACT_APP_API_HOMEWORK}/list?created_by=${userid}&level=${level}&section=${section}&subject=${subject}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setHomeworkList(data);

            setHomeworkListLoading(false);
         }
      }, (error) => {
         setHomeworkListLoading(false);
         console.log(error.message);
      });
   }

   const handleLevelChange = (val) => {
      setSection(null);
      setSubject(null);
      setLevel(val);
      setSecSubLoading(true);

      var sujectlist = [];
      var sectionlist = [];
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${val}&school_year=16`).then((response) => {
         if (response.data) {
            const data = response.data;

            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               sujectlist.push(obj);
            });

            setSubjectList(sujectlist);

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
               if (response.data) {
                  const data = response.data;

                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     sectionlist.push(obj);
                  });

                  setSectionList(sectionlist);
                  setSecSubLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });

         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleCreateHomework = () => {
      setCreateModalVisible(true)
   }

   const handleEditHomework = (id) => {
      // axios.get(`${process.env.REACT_APP_API_HOMEWORK}/info?id=${id}`).then((response) => {
      //    if (response.data) {
      //       const data = response.data;
      //       // message.info(data[0]);
      //       setHomeworkData(data[0]);
      //       setHomeoworkID(id);
      //       setEditModalVisible(true);
      //    }
      // }, (error) => {
      //    console.log(error.message);
      // });

      setHomeoworkID(id);
      setEditModalVisible(true);
   }

   const handleHomeworkSubmissions = (homework_id, level_id, title) => {
      setHomeworkTitle(title);
      setHomeoworkID(homework_id);
      setLevelSubmission(level_id);
      setHSModalVisible(true);
   }

   // function RemoveHomework(id) {
   //    var url = `${process.env.REACT_APP_API_HOMEWORK}/remove?id=${id}`;

   //    return new Promise((resolve, reject) => {
   //       axios.post(url).then(response => {
   //          if (response.data) {
   //             handleSearchHomeworks();
   //             return true;
   //          }
   //       }, (error) => {
   //          //
   //          console.log(error);
   //          return false;
   //       });
   //    })

   // }

   const handleRemoveHomework = (id) => {
      setHomeworkListLoading(true);

      var url = `${process.env.REACT_APP_API_HOMEWORK}/remove?id=${id}`;
      axios.post(url).then(response => {
         if (response.data) {
            Modal.success({
               title: 'Homework',
               content: 'Homework successfully removed.',
               centered: true,
               onOk: handleSearchHomeworks(user_id)
            });
         }
      }, (error) => {
         //
         setHomeworkListLoading(false);
         console.log(error);
      });

      // Modal.confirm({
      //    title: 'Remove Homework',
      //    icon: <AntDIcons.ExclamationCircleOutlined />,
      //    content: 'Are you sure you want to remove this homework?',
      //    okText: 'Yes',
      //    cancelText: 'No',
      //    onOk() {
      //      return new Promise((resolve, reject) => {
      //          //  setTimeout(RemoveHomework(id) ? resolve : reject, 0);
      //          {
      //             if (RemoveHomework(id)) 
      //                return resolve;
      //             else return reject;
      //          }
      //      }).catch(() => console.log('Oops errors!'));
      //    },
      //    onCancel() {},
      //  });
   }

   const handleCancelCreate = () => {
      setCreateModalVisible(false);
   }

   const handleOnFinishCreate = (homeworkData, fileForUpload) => {
      setCreating(true);

      if (fileForUpload.length > 0) {
         var formData = new FormData();

         fileForUpload.forEach(file => {
            // the name has to be 'files' so that .NET could properly bind it (Phuck this sheet! Took me the whole day to find this sheet!)
            formData.append('files', file);
         });

         var uploadedfiles = "";
         var url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadHomework?s3path=${process.env.REACT_APP_S3_HOMEWORKS_TEACHERS_PATH}`;
         axios.post(url, formData).then(response => {

            if (response.data) {
               uploadedfiles = response.data;

               homeworkData.document = uploadedfiles;

               var url = `${process.env.REACT_APP_API_HOMEWORK}/add`;
               axios.post(url, homeworkData).then(response => {
                  setCreating(false);
                  if (response.data) {
                     Modal.success({
                        title: 'Homework',
                        content: 'Homework created.',
                        centered: true,
                        onOk: () => { setCreateModalVisible(false) },
                     });

                     handleSearchHomeworks(user_id);
                  }
               }, (error) => {
                  setCreating(false);
                  Modal.error({
                     title: 'Homework',
                     content: 'Homework creation failed.',
                     centered: true,
                     onOk: () => { setCreateModalVisible(false) },
                  });
               });

            }
         }, (error) => {
            // console.log(error.message);
         });
      } else {
         var url = `${process.env.REACT_APP_API_HOMEWORK}/add`;
         axios.post(url, homeworkData).then(response => {
            setCreating(false);
            if (response.data) {
               Modal.success({
                  title: 'Homework',
                  content: 'Homework created.',
                  centered: true,
                  onOk: () => { setCreateModalVisible(false) },
               });

               handleSearchHomeworks(user_id);
            }

         }, (error) => {
            setCreating(false);
            Modal.error({
               title: 'Homework',
               content: 'Homework creation failed.',
               centered: true,
               onOk: () => { setCreateModalVisible(false) },
            });
         });
      }
   }

   const handleCancelEdit = () => {
      setEditModalVisible(false);
   }

   const handleOnFinishEdit = (homeworkData, filesForUpload) => {
      // message.info("Blah! Blah! Blah!");
      // setEditModalVisible(false);


      setUpdating(true);

      if (filesForUpload.length > 0) {
         var formData = new FormData();

         filesForUpload.forEach(file => {
            // the name has to be 'files' so that .NET could properly bind it (Phuck this sheet! Took me the whole day to find this sheet!)
            formData.append('files', file);
         });

         var uploadedfiles = "";
         var url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadHomework?s3path=${process.env.REACT_APP_S3_HOMEWORKS_TEACHERS_PATH}`;
         axios.post(url, formData).then(response => {
            console.log(response);
            if (response.data) {
               uploadedfiles = response.data;

               homeworkData.document += homeworkData.document !== "" ? "," + uploadedfiles : uploadedfiles;

               var url = `${process.env.REACT_APP_API_HOMEWORK}/update`;
               axios.post(url, homeworkData).then(response => {
                  // console.log(response);

                  if (response.data) {
                     setUpdating(false);
                     Modal.success({
                        title: 'Homework',
                        content: 'Homework updated.',
                        centered: true,
                        onOk: () => { setEditModalVisible(false) },
                     });

                     handleSearchHomeworks(user_id);
                  }
               }, (error) => {
                  // console.log(error.message);
                  setUpdating(false);
                  Modal.error({
                     title: 'Homework',
                     content: 'Updating homework failed.',
                     centered: true
                  });
               });

            }
         }, (error) => {
            // console.log(error.message);
         });
      } else {
         var url = `${process.env.REACT_APP_API_HOMEWORK}/update`;
         axios.post(url, homeworkData).then(response => {
            console.log(response)
            if (response.data) {
               setUpdating(false);
               Modal.success({
                  title: 'Homework',
                  content: 'Homework updated.',
                  centered: true,
                  onOk: () => { setEditModalVisible(false) },
               });

               handleSearchHomeworks(user_id);
            }

         }, (error) => {
            console.log(error.message);
            setUpdating(false);
            Modal.error({
               title: 'Homework',
               content: 'Updating homework failed.',
               centered: true,
               onOk: () => { setEditModalVisible(false) },
            });
         });
      }
   }

   const handleOnEvaluate = () => {

   }

   const handleUserChange = (id) => {
      handleSearchHomeworks(id);
      setUserId(id);
   }

   const handleDuplicateHomework = (id) => {
      axios.get(`${process.env.REACT_APP_API_HOMEWORK}/info?id=${id}`).then((response) => {
         if (response.data) {
            const homeworkdata = response.data[0];

            var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
            var homework_id = "homework_" + o2o_mode + "_" + uuid();
            var utc = new Date().toJSON().slice(0, 19);

            let hwData = {
               id: homework_id,
               class_id: homeworkdata.class_id,
               session_id: 0, //api will take care of this
               title: homeworkdata.title + " - (" + utc + ")",
               homework_date: moment(homeworkdata.homework_date).format('YYYY-MM-DD HH:mm:ss'),
               submit_date: moment(homeworkdata.submit_date).format('YYYY-MM-DD HH:mm:ss'),
               subject_id: homeworkdata.subject_id,
               description: homeworkdata.description,
               created_by: homeworkdata.created_by,
               document: homeworkdata.document,
               sections: '', //section.join(','),
               assigned_students: '', //homeworkdata.assigned_students,
               students: '', //homeworkdata.students,
            }

            var url = `${process.env.REACT_APP_API_HOMEWORK}/add`;
            axios.post(url, hwData).then(response => {
               setCreating(false);
               if (response.data) {
                  Modal.success({
                     title: 'Homework',
                     content: 'Homework duplicated.',
                     centered: true,
                  });

                  handleSearchHomeworks(user_id);
               }
            }, (error) => {
               setCreating(false);
               Modal.error({
                  title: 'Homework',
                  content: 'Homework duplication failed.',
                  centered: true,
               });
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   return (
      <>

         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Homeworks & Projects</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Homeworks & Projects</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <Space direction='vertical' size={"middle"} style={{ width: "99.8%" }}>
                  <Row gutter={12}>
                     <Col className="gutter-row" span={24}>
                        <div className='search-columns-2'>
                           {user_role.toLowerCase() === 'admin' &&
                              <>
                                 <Space direction='horizontal' size={'small'}>
                                    <span>View as </span>
                                    <Select
                                       showSearch
                                       placeholder="Search to Select"
                                       optionFilterProp="children"
                                       filterOption={(input, option) => option.children.includes(input)}
                                       defaultValue={user_full_name} style={{ width: '250px' }} onChange={(value) => handleUserChange(value)} >
                                       {
                                          userList.map((item) => {
                                             var fullname = (item.first_Name + " " + item.last_Name).trimEnd()
                                             return (
                                                <Option value={item.id}>{fullname}</Option>
                                             )
                                          })
                                       }
                                    </Select>
                                 </Space>
                              </>
                           }

                           <Tooltip placement="bottom" title='Create new Homework'>
                              <Button className='button-shadow' type='primary' size='large' shape='round' style={{ maxWidth: '25ch' }} onClick={() => handleCreateHomework()}>
                                 <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Create Homework</Text>
                              </Button>
                           </Tooltip>
                        </div>
                     </Col>
                  </Row>

                  <Row gutter={12}>
                     <Col span={24}>
                        <div className="dashboard-item-inner3">
                           {
                              homeworkListLoading
                                 ?
                                 <div className='center-empty-space'>
                                    <Spin size='large' tip="Loading, please wait..." />
                                 </div>
                                 :
                                 (homeworkList.length > 0 && !homeworkListLoading)
                                    ?
                                    <Table
                                       rowKey={'uid'}
                                       // loading={homeworkListLoading}
                                       size='small'
                                       columns={HomeworkListColumns}
                                       dataSource={homeworkList}
                                       pagination={{ position: ['topCenter'] }}
                                       scroll={{
                                          x: '1000px',
                                          y: 'calc(100svh - 15.5rem)',
                                       }}
                                       footer={() => 'Total Records: ' + homeworkList.length}
                                    />
                                    :
                                    <div className='center-empty-space'>
                                       <Empty image="../images/brainee_mascot.png" description="No records found" />
                                    </div>
                           }
                        </div>
                     </Col>
                  </Row>
               </Space>
            </div>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

         {/* <div className="dashboard-area-all" style={{paddingRight: `0px`}}>
      <Split
         sizes={[70, 30]}
         direction="horizontal"
         cursor="col-resize"
         className="split-flex"
      >
         <div style={{ width: `70%`, paddingLeft:'10px', paddingRight: '10px', overflow: `auto` }}>
               <div className="dashboard-item" style={{minWidth: "700px"}}>
                  <div className="dashboard-item-inner" style={{paddingLeft: `30px`}}>
                     <ul>
                        <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                        <li><span>/</span></li>
                        <li className="active">Homeworks & Projects</li>
                     </ul>      
                  </div>   
                  <Space direction='vertical' size={"middle"} style={{width: "99.8%"}}>
                     
                     <Row gutter={24}>
                        <Col className="gutter-row" span={12}>
                           { user_role.toLowerCase() === 'admin' && 
                           <>
                           <span>View as </span>  
                           <Select 
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children.includes(input)}                
                              defaultValue={user_full_name} style={{ width: 220 }} onChange={(value) => handleUserChange(value)} >
                              {
                                 userList.map((item) =>{
                                    var fullname = (item.first_Name + " " + item.last_Name).trimEnd()
                                    return (
                                       <Option value={item.id}>{fullname}</Option>
                                    )
                                 })
                              }                        
                           </Select>  
                           </>
                           }
                        </Col>
                        <Col className="gutter-row" span={6}>
                        </Col>
                        <Col className="gutter-row" span={6}>
                           <div style={{width:"100%", textAlign:"right"}}>
                              <Tooltip placement="bottom" title='Create new Homework'>
                                 <Button className='button-shadow' type='primary' size='large' shape='round' onClick={() => handleCreateHomework()}>
                                    <Text ellipsis style={{color: "#fff"}}><AntDIcons.PlusOutlined />&nbsp;Create Homework</Text>
                                 </Button>
                              </Tooltip>
                           </div>
                        </Col>
                     </Row>
                     
                     <Row gutter={24} style={{overflow: "auto"}} >
                        <Col span={24}>
                           <div className="dashboard-item-inner3"> 
                              <Table 
                                 loading={homeworkListLoading}
                                 size='small' 
                                 columns={HomeworkListColumns} 
                                 dataSource={homeworkList} 
                                 pagination={{ pageSize: 10 }} 
                                 
                              />
                           </div>
                        </Col>
                     </Row>
                  </Space>
               </div>
         </div>
         <div style={{ width: `30%`, overflow: "auto" }}>
            <div className="notice-item" style={{minWidth: "300px"}}>
               <SideCalendar/>
               <NoticeBoard/>
            </div>
         </div>
      </Split>
      </div> */}

         <Modal
            title={"Create Homework"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancelCreate(); }}
            open={createModalVisible}
            // width={window.innerWidth - 400}
            width='80%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <Spin spinning={creating} tip="Creating homework, please wait...">
               <Create
                  onCancelCallback={handleCancelCreate}
                  onFinishCallback={handleOnFinishCreate}
               />
            </Spin>
         </Modal>

         <Modal
            title={"Edit Homework"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancelEdit(); }}
            open={editModalVisible}
            // width={window.innerWidth - 400}
            width='80%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <Edit
                  homeworkID={homeworkID}
                  homeworkData={homeworkData}
                  onCancelCallback={handleCancelEdit}
                  onFinishCallback={handleOnFinishEdit}
               />
            </Spin>
         </Modal>

         <Modal
            title={"Homework Submissions"}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={hsModalVisible}
            // width={window.innerWidth - 300}
            width='80%'
            onCancel={() => { setHSModalVisible(false); }}
            onOk={() => { setHSModalVisible(false); }}
         >
            {/* <Spin spinning={updating} tip={"Loading, please wait..."}> */}
            <Submissions
               homeworkTitle={homeworkTitle}
               homeworkID={homeworkID}
               levelID={levelSubmission}
               onEvaluateCallback={handleOnEvaluate}
            />
            {/* </Spin> */}
         </Modal>
      </>
   );
}

