import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { Link } from 'react-router-dom';
import { Create } from './Components/Create';
import { View } from './Components/View';

export function LessonCalendar(props) {
   // const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [currentDocument, setCurrentDocument] = useState(null);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

   }, []);

   const handleFinishUploading = (document) => {
      setCurrentDocument(document);
   }

   const handleChangeSearchCriteria = (level, section) => {
      setLevel(level);
      setSection(section);
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Schedule</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Lesson Calendar</a></li>
                  </ul> */}

                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        {/* <AntDIcons.HomeOutlined /> */}
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Schedule</Breadcrumb.Item>
                     <Breadcrumb.Item>Lesson Calendar</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
               {
                  (user_role === 'Admin' || user_role === 'Teacher') &&
                  <div style={{ paddingBottom: '10px' }}>
                     <Create
                        type='lesson'
                        button_label='Lesson Calendar'
                        level={level}
                        section={section}
                        onFinishCreateCallback={handleFinishUploading}
                     />
                  </div>
               }
               {
                  // currentDocument !== null
                  //    ?
                  <View
                     type='lesson'
                     uploader={user_id}
                     document={currentDocument}
                     onChangeSearchCriteria={handleChangeSearchCriteria}
                     showsearchtools={true}
                     emptydescription='Lesson calendar not available.'
                  />
                  // :
                  // <div className='document-viewer'>
                  //    <Empty
                  //       description={'Lesson calendar not available.'}
                  //    // image="../images/brainee_mascot.png"
                  //    />
                  // </div>
               }

            </div>

         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

      </>
   );
}