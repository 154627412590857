/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Spin, Breadcrumb } from 'antd';
import './MasteryAssessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
// import { CardRequestHelper } from './Helpers';
import { CardRequestHelper } from '../../helper/AQHelper';
import Split from 'react-split';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

import { useSelector } from 'react-redux';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

var sectionIdx = 1;
var qcardIdx = 1;
var idxCol = [];

export function MasteryPreviewPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const masteryrec = useSelector(state => state.masteryrec);
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [answerSheetData, setAnswerSheetData] = useState([]);
   const [questionSheetPDF, setQuestionSheetPDF] = useState("");

   const subdomain = localStorage.getItem("lms-subdomain");

   useEffect(() => {
      Utils.verifyJwt();
      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      // switch (location.state.type) {
      //    case "formative_1":
      //       setAnswerSheetData(JSON.parse(masteryrec.formative_1));
      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_1_file_url}`);
      //       break;
      //    case "formative_2":
      //       setAnswerSheetData(JSON.parse(masteryrec.formative_2));
      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_2_file_url}`);
      //       break;
      //    case "formative_3":
      //       setAnswerSheetData(JSON.parse(masteryrec.formative_3));
      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_3_file_url}`);
      //       break;
      //    case "summative":
      //       setAnswerSheetData(JSON.parse(masteryrec.summative));
      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.summative_file_url}`);
      //       break;
      //    default:
      //       setAnswerSheetData("");
      //       setQuestionSheetPDF("");
      // }
      switch (location.state.type) {
         case "formative_1":
            setAnswerSheetData(JSON.parse(masteryrec.formative_1));

            if ((masteryrec.formative_1_file_url).includes('base64'))
               setQuestionSheetPDF(`${masteryrec.formative_1_file_url}`);
            else
               setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_1_file_url}`);

            break;
         case "formative_2":
            setAnswerSheetData(JSON.parse(masteryrec.formative_2));

            if ((masteryrec.formative_2_file_url).includes('base64'))
               setQuestionSheetPDF(`${masteryrec.formative_2_file_url}`);
            else
               setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_2_file_url}`);

            break;
         case "formative_3":
            setAnswerSheetData(JSON.parse(masteryrec.formative_3));

            if ((masteryrec.formative_3_file_url).includes('base64'))
               setQuestionSheetPDF(`${masteryrec.formative_3_file_url}`);
            else
               setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_3_file_url}`);

            break;
         case "summative":
            setAnswerSheetData(JSON.parse(masteryrec.summative));

            if ((masteryrec.summative_file_url).includes('base64'))
               setQuestionSheetPDF(`${masteryrec.summative_file_url}`);
            else
               setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.summative_file_url}`);

            break;
         default:
            setAnswerSheetData("");
            setQuestionSheetPDF("");
      }

      // getAssessmentInfo(id);
   }, []);

   const handleCardRequest = (method, type, data, idx) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData);
      setAnswerSheetData(asd);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const docs = [
      // { uri: questionSheetPDF },
      {
         // uri: "../images/_images_pdf.pdf",
         // uri: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${g_report_card_url}`,
         uri: questionSheetPDF,
         // fileType: 'pdf',
         fileName: location.state.title,
      },
   ];

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div className="dashboard-item">
                  <div className="dashboard-item-inner">
                     {/* <ul>
                        <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                        <li><span>/</span></li>
                        <li className="active">Brainee LMS</li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subjects</a></li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subjectid}&name=${location.state.subject}`)}>Mastery Assessment ({location.state.subject})</a></li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate(`/masteryassessment/preview?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subject}&mode=${location.state.mode}`)}>Preview</a></li>
                        <li><span>/</span></li>
                        <li><a className="active">{location.state.title}</a></li>
                     </ul> */}
                     <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                        <Breadcrumb.Item>
                           <Link to="/home">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subject</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subjectid}&name=${location.state.subject}`)}>Mastery Assessment ({location.state.subject})</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate(`/masteryassessment/preview?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subject}&mode=${location.state.mode}`)}>Preview</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{location.state.title}</Breadcrumb.Item>
                     </Breadcrumb>
                  </div>
                  {questionSheetPDF
                     ?
                     <div className="pdf-all-page-container" style={{ height: 'calc(100vh - 58px)' }}>
                        <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
                           <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                              <Viewer
                                 pageLayout={{
                                    transformSize: ({ size }) => ({
                                       height: size.height + 30,
                                       width: size.width + 30,
                                    }),
                                 }}
                                 fileUrl={questionSheetPDF}
                                 defaultScale={SpecialZoomLevel.PageWidth}
                                 initialPage={0}
                              />
                           </Worker>
                        </div>
                        {/* <RenderPDFReader url={questionSheetPDF} height={"calc(100vh - 58px)"} /> */}
                        {/* <RenderPDFViewer url={questionSheetPDF} height={"calc(100vh - 58px)"} /> */}
                     </div>
                     :
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  }
               </div>

               <div className="notice-item">
                  <>
                     {answerSheetData
                        ?
                        <div className='answer-sheet-content'>
                           <SimpleBarReact>
                              {/* <Descriptions layout='vertical' size={"small"} bordered style={{marginBottom:"15px", width:"100%"}} column={4}>                     
                           <Descriptions.Item label={"Title"} span={4} style={{textAlign:"center"}} >
                              <Paragraph style={{textAlign:"left", width:"100%"}}>{ assInfo.Title }</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item label={"Level"} span={2} style={{ textAlign:"center", width:"50%"}} >
                              <Paragraph style={{textAlign:"left", width:"100%"}}>{assInfo.Level_desc}</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item label={"Subject"} span={2} style={{textAlign:"center", width:"50%"}} >
                              <Paragraph style={{textAlign:"left", width:"100%"}}>{assInfo.Subject_desc}</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item label={"Term"} span={2} style={{textAlign:"center", width: "50%"}} >
                              <Select 
                                 size='default' 
                                 style={{width: '100%', textAlign:"left"}} 
                                 value={ assInfo.Term }
                                 disabled
                              >
                                 {SchoolTerms.map(({value, name}, idx) => { 
                                    return <Option value={value}>{name}</Option>
                                 })}
                              </Select>
                           </Descriptions.Item>
                           <Descriptions.Item label={"Date"} span={2} style={{textAlign:"center", width: "50%"}}>
                              <Paragraph style={{textAlign:"left", width:"100%"}}>{ assInfo.Date_created }</Paragraph>
                           </Descriptions.Item>
                        </Descriptions> */}
                              <ul className="questions">
                                 {answerSheetData.map(({ id, type, points, correct, option_labels, image_url }, idx) => {

                                    if (type === "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       // if (idxCol.length <= idx) {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                       // }
                                    }

                                    return (
                                       <li>
                                          <BraineeQuestionCard
                                             key={id}
                                             index={idxCol[idx]}
                                             id={idx}
                                             type={type}
                                             points={points}
                                             correct={correct}
                                             option_labels={option_labels}
                                             image_url={image_url}
                                             question_type={'1'}
                                             mode={'preview'}
                                             parentCallback={handleCardRequest}
                                             card_bg={location.state.card_bg}
                                             assessment_type={'mastery'}
                                          />
                                       </li>
                                    );
                                 })}
                              </ul>
                           </SimpleBarReact>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </>
               </div>
            </Split>
         </div>
      </>
   )
}
