// import './DashboardPage.css';
import React, { useEffect } from 'react';
import * as AntDIcons from '@ant-design/icons';
// import SideCalendar from '../side-calendar/SideCalendar'; //'./side-calendar/SideCalendar';
import NoticeBoard from '../notice-board/NoticeBoard';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
// import SimpleBarReact from "simplebar-react";
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import NoticeBoardList from '../notice-board/NoticeBoardList';
// import { Avatar } from 'antd';
// import { UserOutlined } from '@ant-design/icons';
import "./Profile.scss"
import LoginService from '../../services/LoginService';

import {
   AutoComplete,
   Button,
   Cascader,
   Alert,
   Col,
   Form,
   Input,
   Divider,
   Row,
   Select,
   Typography
} from 'antd';

import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

function Profile() {
   const navigate = useNavigate();

   const [currentUsername, setCurrentUsername] = React.useState('');
   const [response, setResponse] = React.useState('Ok');
   const [form] = Form.useForm();

   useEffect(() => {
      Utils.verifyJwt();
      setCurrentUsername(Utils.getCurrentUserInfo(Enums.UserInfo.fullname));
   }, []);

   const formItemLayout = {
      labelCol: {
         xs: { span: 24 },
         sm: { span: 8 },
      },
      wrapperCol: {
         xs: { span: 24 },
         sm: { span: 16 },
      },
   };

   const tailFormItemLayout = {
      wrapperCol: {
         xs: {
            span: 24,
            offset: 0,
         },
         sm: {
            span: 16,
            offset: 8,
         },
      },
   };

   const onFinish = (values) => {
      const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
      const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
      var user_type = (user_role === "Admin" || user_role === "Teacher") ? "1" : user_role === "Student" ? "2" : "3"
      var request = { Id: user_id, UserType: user_type, CurrentPassword: values.currentpassword, NewPassword: values.newpassword }

      LoginService.changePasswordService(request).then((response) => {
         setResponse(response.data)
      }).catch((e) => {
         var ex = e;
         setResponse("")
      })
      console.log('Received values of form: ', values);
   };

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Profile</a></li>
                  </ul>
               </div>
               <div style={{ background: "#E2EBFF 0% 0% no-repeat padding-box", height: "58px" }}>
                  <div>
                     <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        {/*                          <div style={{padding : "10px", width : "120px", margin : "auto" }}>
                        <Avatar size={105} icon={<UserOutlined />} />
                     </div> */}
                        <div className='header-profile'>
                           <div className='name'>{currentUsername}</div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="dashboard-item-inner3">
                  <Typography.Title level={2} style={{ padding: "5px" }}>
                     Change your password
                  </Typography.Title>
                  <Divider />
                  {(response === "Invalid Password" || response === "") && <Alert
                     message="Error in authenticating the user."
                     showIcon
                     description="Current Password does not match, please try again."
                     type="error"
                  />}
                  {(response === "Success") && <Alert
                     message="Change password."
                     showIcon
                     description="Change password successful! You may logout and try your new password."
                     type="success"
                  />}
                  <Row style={{ padding: "25px" }}>
                     <Col span={12}>
                        <Form
                           {...formItemLayout}
                           form={form}
                           name="register"
                           onFinish={onFinish}
                           scrollToFirstError
                        >
                           <Form.Item
                              name="currentpassword"
                              label="Current Password"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Please input your Current Password!',
                                 },
                              ]}
                           >
                              <Input.Password />
                           </Form.Item>

                           <Form.Item
                              name="newpassword"
                              label="Password"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Please input your password!',
                                 },
                              ]}
                              hasFeedback
                           >
                              <Input.Password />
                           </Form.Item>

                           <Form.Item
                              name="confirmnewpassword"
                              label="Confirm Password"
                              dependencies={['password']}
                              hasFeedback
                              rules={[
                                 {
                                    required: true,
                                    message: 'Please confirm your password!',
                                 },
                                 ({ getFieldValue }) => ({
                                    validator(_, value) {
                                       if (!value || getFieldValue('newpassword') === value) {
                                          return Promise.resolve();
                                       }
                                       return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                 }),
                              ]}
                           >
                              <Input.Password />
                           </Form.Item>

                           <Form.Item  {...tailFormItemLayout}>
                              <Button type="primary" shape='round' htmlType="submit">
                                 Submit
                              </Button>
                           </Form.Item>
                        </Form>
                     </Col>
                  </Row>
               </div>
            </div>

         </div>
      </>);
}

export default Profile;   