import React,{ useEffect, useState,useRef } from 'react'
import CanvasComponent from './CanvasComponent';
import Utils from '../../../misc/Utils';
import "./LessonViewer.scss"
import {Pagination, Button
 } from 'antd';
 import { FullscreenExitOutlined, MenuFoldOutlined} from '@ant-design/icons';

export const CanvasContext = React.createContext({});  

export default function FullScreenViewer(props) {

    const [zoomValue, setZoomValue] = useState(1);
    const [lessonId, setLessonId] = useState("")
    const [canvasData, setCanvasData] = useState([])
    const [canvasListData, setCanvasListData] = useState([])
    const [canvasListDataIdx, setCanvasListDataIdx] = useState(0)
    const [bodyBackgroundColor, setBodyBackgroundColor] = useState("white")
    const [bodyBackgroundImage, setBodyBackgroundImage] = useState("")
    const [scaleX, setScaleX] = useState(1)
    const [scaleY, setScaleY] = useState(1)
    const [translationX, setTranslationX] = useState(1)
    const [translationY, setTranslationY] = useState(0)
    const [proportional, setProportional] = useState(false)

    const containerRef = useRef(null)
    const divWrapper = useRef(null)
    const [percentage, setPercentage] = useState("-50%")

    const [current, setCurrent] = useState(0);
    const [showElement,setShowElement] = React.useState(true)
    const timerId = useRef(null)

    // const onChange = (page) => {
    //   console.log(page);
    //   setCurrent(page);
    // };

    // useEffect(() => {
    //     if (!divWrapper.current) return;
    //     if (!containerRef.current) return;
    //     const resizeObserver = new ResizeObserver(() => {

    //     //   if (divWrapper.current.offsetHeight === 0) return

    //       var elHeight = containerRef.current?.clientHeight;
    //       var elWidth = containerRef.current?.clientWidth;  
          
    //       var scale;
    //       var width = window.innerWidth / elWidth
    //       var height =  window.innerHeight / elHeight
    //       var heightPercentage = elHeight /  window.innerHeight
    //       var percent = "-" + (parseFloat(heightPercentage.toFixed(2)) * 100).toFixed(0).toString() + "%";
    //       setPercentage(percent)
    //       scale = Math.min(
    //         window.innerWidth / elWidth,    
    //         window.innerHeight / elHeight
    //       );
    

    //     //   scale = scale > 1 ? 1 : scale
    //       var minHeight = scale === 0 ? scaleHeight : height-0.2
    //       var minWidth = scale === 0 ? scaleWidth : scale 

    //       setScale(minWidth)
    //       setScaleWidth(width)
    //       setScaleHeight(height-0.1)
    //       var s= ""
    //     });
    //     resizeObserver.observe(divWrapper.current);
    //     return () => resizeObserver.disconnect(); // clean up 
    //   }, []);    

    useEffect(() => {
        if (!divWrapper.current) return;
        if (!containerRef.current) return;


        var currentWidth = containerRef.current?.clientWidth; 
        var currentHeight = containerRef.current?.clientHeight;
      
        var availableHeight = window.innerHeight;
        var availableWidth = window.innerWidth;
      
        var scaleX = availableWidth / currentWidth;
        var scaleY = (availableHeight / currentHeight) + 0.25;
      
        if (proportional) {
          scaleX = Math.min(scaleX, scaleY)
          scaleY = scaleX 
        }
      
        var translationX = Math.round((availableWidth - (currentWidth * scaleX)) / 2);
        var translationY = Math.round((availableHeight - (currentHeight * scaleY)) / 2);

        setTranslationX(translationX)
        setTranslationY(translationY)
        setScaleX(scaleX)
        setScaleY(scaleY)      
      }, []);    
      
      useEffect(() => {
        // Hide the div after 5 seconds
        var timer = setTimeout(() => {
          setShowElement(false);
        }, 5000);
    
        // Add event listener for mouse move
        const handleMouseMove = () => {
          setShowElement(true);
          // Remove the event listener after the div is shown
          // window.removeEventListener('mousemove', handleMouseMove);
          clearTimeout(timer);
          timer = setTimeout(() => {
            setShowElement(false);
          }, 5000);        
        };
    
        window.addEventListener('mousemove', handleMouseMove);
    
        // Cleanup the timer and event listener on component unmount
        return () => {
          clearTimeout(timer);
          window.removeEventListener('mousemove', handleMouseMove);
        };
      }, []);   

const setSelectedIdxCanvas = (idx) => {
    // set default in List Canvas
    var canvas = canvasListData[idx-1].data
    setCanvasListDataIdx(idx-1)
    setCanvasData(canvas)
    setCurrent(idx)
}


    const context = {
        actions: {
            setCanvasData,
            setCanvasListData,  
            setCanvasListDataIdx,
            setSelectedIdxCanvas,
            setLessonId,
        },
        state: {
            canvasData,
            canvasListData,
            bodyBackgroundImage,
            lessonId
        }
    }


    useEffect(() => {

        let data = props.sections 
        setCanvasListDataIdx(0)
        if (data?.sections === null || data?.sections === undefined)
           data.sections = []
  
        data = Utils.convertKeysToLowerCase(data)
        if (data.sectionversion === 0)
        {
          var listData = {backgroundcolor : "white", data : canvasData}
          setCanvasListData([...canvasData, listData])
        }
        else
        {
          var canvasListDataT = data.sections[props.lessonIdx].contents[props.topicIdx]?.data
          var canvasDataT = []
          if (canvasListDataT)
          {
             if (canvasListDataT.length > 0)
              {
                canvasDataT = [...{...canvasListDataT[0]}?.data]
                setCanvasData(canvasDataT)
                setCanvasListData([...canvasListDataT])
              }
             else
             {
              var listData = {backgroundcolor : "white", data : canvasDataT}
              setCanvasListData([...canvasDataT, listData])
             } 
          } 
          else
          {
            var listData = {backgroundcolor : "white", data : canvasDataT}
            setCanvasListData([...canvasDataT, listData])
          }
  
          setCanvasData(canvasDataT)  
        }      
  
     }, [props.lessonIdx,props.topicIdx]);

     const clickNextPrev = (type) =>
     {
        var dataIdx = canvasListDataIdx
         if (type === "prev")
        {
            dataIdx = (dataIdx-1) < 0 ? 0 : dataIdx-1
        }
        if (type === "next")
        {
            dataIdx = (dataIdx+1) > canvasListData.length-1 ? canvasListData.length-1 : dataIdx+1
        }     

        setSelectedIdxCanvas(dataIdx)
     }

    return(
        <>
           {/* <CanvasContext.Provider value={context}>   */}
            {/* <div style={{ height : "100%", backgroundColor : "white"}}>    */}
             <div class="scaleable-wrapper" id="scaleable-wrapper" ref={divWrapper}
               style={{backgroundColor : "white", height : "100%"}}
             >
                <div class="very-specific-design" id="very-specific-design" ref={containerRef}
                  style={{
                    position: "fixed",
                    left: "0px",
                    top: "80px",
                    "-webkit-transform": "translate(" + translationX + "px, "
                                                      + translationY + "px) scale3d("
                                                      + scaleX + ", " + scaleY + ", 1)",
                    "-webkit-transform-origin": "0 0",                    
                    backgroundColor : canvasListData.length > 0 ?  canvasListData[canvasListDataIdx].backgroundcolor : "white" ,
                    backgroundImage : canvasListData.length > 0 ?  (`url(${canvasListData[canvasListDataIdx].backgroundimage})`) : "" ,                  
                  }}
                >              
                      {canvasData.map((canvas) => {
                          return <CanvasComponent {...canvas} />;
                      })} 
                </div>  
              </div>                
 
                {showElement && <div
                   className="slide-in" 
                  style={{
                    position : "fixed",
                    display : "flex",
                    width : "100%",
                    bottom : "0px",
                    right : "0px",
                    justifyContent : "space-between",
                    alignContent : "center",
                    padding : "15px",
                    backgroundColor  : "#7fc9ce",
                    color : "white",
                    // borderTop : "1px solid black",
                    // boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);"                   
                  }}
                >

                    <div style={{display : "flex"}}>
                      <div style={{marginLeft : "5px"}}>
                      <Button type='default' size='middle' shape='round' style={{marginRight : "10px"}} onClick={() => props.exit()}  icon={<FullscreenExitOutlined />}  >
                          Exit FullScreen
                      </Button>
                      </div>  
                      <div>
                      <Button type='default' size='middle' shape='round' style={{marginRight : "10px"}} onClick={() => setShowElement(false)}  icon={<MenuFoldOutlined />}  >
                          Hide Menu
                      </Button>
                      </div>    
                    </div>               
                    <div>
                        <Pagination
                            // style={{color : "white", backgroundColor : "black"}}
                            total={canvasListData.length}
                            onChange={setSelectedIdxCanvas}
                            current={current}
                            defaultPageSize={1}
                            responsive
                            showQuickJumper
                            showTotal={(total) => `Total ${total} items`}
                        />   
                    </div>
                 </div>}
                {/* </div>                          */}
        {/* </CanvasContext.Provider>         */}
        </>
    )
}
