import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { UndoOutlined } from '@ant-design/icons';
import { Button, Descriptions, message, Popconfirm, Typography, Select, Modal, Spin, Breadcrumb } from 'antd';
// import StickyBox from "react-sticky-box";
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
// import AllPagesPDFViewer from '../../components/pdf-viewer/all-pages';
// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import { SchoolTerms } from '../../constants';
import Utils from '../../misc/Utils';
import Split from 'react-split';
// import './SplitPanel.css';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Paragraph } = Typography;
const { Option } = Select

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];

export function FormativePreviewPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();

   // const[answerSheetData, setAnswerSheetData] = useState(questionData);
   // const[questionSheetPDF, setQuestionSheetPDF] = useState("https://media.campuscloudph.com/ssapamp/uploads/lms_assessment/lms_assessment_online_163922682965702119/assessment_online_163922685061451080.pdf");
   const [origin, setOrigin] = useState(location.state.origin);
   const [id, setId] = useState(location.state.id);
   const [pdfLoaded, setPdfLoaded] = useState(false);

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });

   useEffect(() => {
      Utils.verifyJwt();
      getAssessmentInfo(id);
   }, []);

   const getAssessmentInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${id}&at=formative`).then((response) => {
         if (response.data) {
            const data = response.data;
            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: JSON.parse(data.question_sheet),
               Level: data.level,
               Subject: data.subject,
               Level_desc: data.level_desc,
               Subject_desc: data.subject_desc
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleCardRequest = () => {

   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/formativeassessment', { state: { activetab: "1" } })}>Self-check</a></li>
                           <li><span>/</span></li>
                           <li className="active">Preview</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              {/* <AntDIcons.HomeOutlined /> */}
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <Link to="/formativeassessment" state={{ activetab: "1" }}>Self-check</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Preview</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>
                     {
                        assInfo.File_url
                           ?
                           // <div className="pdf-all-page-container">
                           //    <RenderPDFViewer url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} />
                           // </div>
                           // <RenderPDFReader url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} />
                           <div className="pdf-all-page-container">
                              <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
                                 <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                    <Viewer
                                       pageLayout={{
                                          transformSize: ({ size }) => ({
                                             height: size.height + 30,
                                             width: size.width + 30,
                                          }),
                                       }}
                                       fileUrl={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}
                                       defaultScale={SpecialZoomLevel.PageWidth}
                                       initialPage={0}
                                    />
                                 </Worker>
                              </div>
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                     }
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <>
                        {assInfo.Question_sheet
                           ?
                           <div className='answer-sheet-content'>
                              <SimpleBarReact>
                                 <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                                    <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                       <Paragraph style={{ textAlign: "left", width: "100%" }}>{assInfo.Title}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Level"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "left", width: "100%" }}>{assInfo.Level_desc}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Subject"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "left", width: "100%" }}>{assInfo.Subject_desc}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Term"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Select
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          value={assInfo.Term}
                                          disabled
                                       >
                                          {SchoolTerms.map(({ value, name }, idx) => {
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Date"} span={2} style={{ textAlign: "center", width: "50%" }}>
                                       <Paragraph style={{ textAlign: "left", width: "100%" }}>{assInfo.Date_created}</Paragraph>
                                    </Descriptions.Item>
                                 </Descriptions>
                                 <ul className="questions">
                                    {assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, image_url, question }, idx) => {

                                       if (type == "section") {
                                          qcardIdx = 1;
                                          idxCol.push(sectionIdx);
                                          sectionIdx++;
                                       } else {
                                          idxCol.push(qcardIdx);
                                          qcardIdx++;
                                       }

                                       return (
                                          <li>
                                             <BraineeQuestionCard
                                                key={id}
                                                index={idxCol[idx]}
                                                id={idx}
                                                type={type}
                                                points={points}
                                                correct={correct}
                                                option_labels={option_labels}
                                                image_url={image_url}
                                                question={question}
                                                question_type={'1'}
                                                mode={'preview'}
                                                parentCallback={handleCardRequest}
                                                assessment_type={'formative'}
                                             />
                                          </li>
                                       );
                                    })}
                                 </ul>
                              </SimpleBarReact>
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              {/* <Empty description='No items added.'/> */}
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                        }
                     </>
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
