import React, { useEffect } from 'react'
import SimpleBarReact from "simplebar-react";
import moment from 'moment';
import './Subject.scss'
import { Tabs, Modal, Button, Image, Typography, Divider, message, Empty, Breadcrumb } from 'antd';
import { CloudDownloadOutlined, PlayCircleOutlined, CaretLeftOutlined, CaretRightOutlined, AppstoreOutlined, ArrowLeftOutlined, LockOutlined, EyeOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player'
import Iframe from 'react-iframe'
// import { EditLesson } from '../../components/lesson';
import { useSelector, useDispatch } from 'react-redux'
import { TopicSection } from '../../components/lesson';
import { WebsiteContent } from '../../components/lesson';
import LessonService from '../../services/LessonService';
import Enums from '../../misc/Enums';
import { Notes } from '../../components/lesson/Notes';
import Utils from '../../misc/Utils';
import { useNavigate, Link } from 'react-router-dom';
import { set_lesson } from '../../actions';
import Discussions from '../../components/lesson/Discussions';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
// import FileSaver from "file-saver";
// import { type } from '@testing-library/user-event/dist/type';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

function callback(key) {
   console.log(key);
}

export function SubjectView(props) {

   const [downloadStatus, setDownloadStatus] = React.useState(false);
   const [currentS3ContentLink, setCurrentS3ContentLink] = React.useState('');
   const [downloadOfficeFileName, setDownloadOfficeFileName] = React.useState('');
   const [currentContentLink, setCurrentContentLink] = React.useState('');
   const [currentContentLinkIdx, setCurrentContentLinkIdx] = React.useState(0);
   const [currentContentType, setCurrentContentType] = React.useState('');
   const [contentCount, setContentCount] = React.useState(0);
   const [currentSectionIdx, setCurrentSectionIdx] = React.useState(0);
   const [currentSectionContentIdx, setCurrentSectionContentIdx] = React.useState(0);
   const [contentList, setContentList] = React.useState([]);
   const [visible, setVisible] = React.useState(false);
   const [toggleSideMenu, setToggleSideMenu] = React.useState(false);
   const [visibleTile, setVisibleTile] = React.useState(false);
   const [lesson, setLesson] = React.useState({});
   const [currentTopicEditIdx, setCurrentTopicEditIdx] = React.useState(0);
   const [currentLessonEditIdx, setCurrentLessonEditIdx] = React.useState(0);
   const [viewState, setViewState] = React.useState(0) //-- Set to tile view
   const [levelid, setLevelId] = React.useState(0);
   const [lessonid, setLessonId] = React.useState("");
   const [mode, setMode] = React.useState("view");
   const navigate = useNavigate();

   const [effectiveDate, setEffectiveDate] = React.useState("");
   const [subjectDesc, setSubjectDesc] = React.useState("");
   const [quizDesc, setQuizDesc] = React.useState("");
   const [quizStatus, setQuizStatus] = React.useState(true);

   const lessonState = useSelector(state => state.lesson)
   const dispatch = useDispatch();

   useEffect(() => {
      Utils.verifyJwt();
      const search = window.location.search;
      const query = new URLSearchParams(search);
      const Id = query.get('Id')
      setLessonId(Id)
      setLevelId(query.get('level'))
      console.log(`id ` + Id);

      LessonService.getLessonById(Id).then((lesson) => {
         let data = lesson.data
         if (data.sections === null)
            data.sections = []
         dispatch(set_lesson(data))
         setLesson(data)

         if (data.sections.length > 0) {
            var firstContent = data.sections[currentSectionIdx].contents[currentSectionContentIdx];
            if (firstContent) {
               if (firstContent.links.length > 0) {

                  if (firstContent.links[0].url !== "") {
                     var s3FullPathWithOfficeLink = ""
                     var s3FullPathName = ""

                     if (firstContent.links[0].type === "msoffice") {
                        var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
                        var s3File = firstContent.links[0].url.split(msOfficelink)
                        s3FullPathWithOfficeLink = s3File[1]
                        var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
                        s3FullPathName = firstContent.links[0].title + "." + s3FileExt
                     }
                     else if (firstContent.links[0].type === "others") {
                        s3FullPathWithOfficeLink = firstContent.links[0].url
                        var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
                        s3FullPathName = firstContent.links[0].title + "." + s3FileExt
                     }

                     setCurrentS3ContentLink(s3FullPathWithOfficeLink)
                     setDownloadOfficeFileName(s3FullPathName)
                  }

                  setCurrentContentLink(firstContent.links[0].url);
                  setCurrentContentType(firstContent.links[0].type);
               }
               else if (firstContent.type === "quiz") {
                  var today = moment(new Date()).format('YYYY-MM-DD');
                  //console.log("Date : " + today)
                  var current = false;

                  var tmpeffectivedate = firstContent.effectivedate;
                  var eDate = ""
                  if (tmpeffectivedate.includes("@")) {
                     var tmpdatesplit = tmpeffectivedate.split("@");
                     var startDate = moment(tmpdatesplit[0], 'YYYY-MM-DD').format('YYYY-MM-DD')
                     var endDate = moment(tmpdatesplit[1], 'YYYY-MM-DD').add(1, 'd').format("YYYY-MM-DD")
                     //console.log("Date Start : " + startDate)
                     //console.log("Date End : " + endDate)
                     //current = today.isBetween(moment(tmpdatesplit[0],'YYYY-MM-DD').format('YYYY-MM-DD'),moment(tmpdatesplit[1],'YYYY-MM-DD').add(1,'d').format("YYYY-MM-DD"), null, '[]') 
                     current = (today >= startDate && today <= endDate)
                     //console.log("Current ====: " + current)
                     eDate = tmpdatesplit[0] + " - " + tmpdatesplit[1]
                  }
                  else {
                     var effectivedate = moment(firstContent.effectivedate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                     eDate = firstContent.effectivedate + " - " + moment(firstContent.effectivedate, 'YYYY-MM-DD').add(1, 'd').format("YYYY-MM-DD")
                     // current =  today.isBefore(effectivedate) 
                     current = (today <= effectivedate)
                  }

                  setQuizStatus(current)
                  setCurrentContentLink("");
                  setCurrentContentType(firstContent.type);
                  setEffectiveDate(eDate);
                  setSubjectDesc(firstContent.otherdesc);
                  setQuizDesc(firstContent.title)
               }
               setContentCount(firstContent.links.length);
               setCurrentContentLinkIdx(0);
               setContentList(firstContent);
            }
            else {
               message.info("No resources included.")
            }
         }
      }).catch((ex) => {
         message.error(ex.message)
      })

   }, []);

   const truncate = (str, n) => {
      return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
   };

   const _clickTileItem = (idx) => {
      // var idx = currentContentLinkIdx + 1 < contentCount ? currentContentLinkIdx + 1  : contentCount - 1;

      if (contentList.links[idx].url !== "") {
         var s3FullPathWithOfficeLink = ""
         var s3FullPathName = ""

         if (contentList.links[idx].type === "msoffice") {
            var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
            var s3File = contentList.links[idx].url.split(msOfficelink)
            s3FullPathWithOfficeLink = s3File[1]
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }
         else if (contentList.links[idx].type === "others" || contentList.links[idx].type === "pdf") {
            s3FullPathWithOfficeLink = contentList.links[idx].url
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }

         setCurrentS3ContentLink(s3FullPathWithOfficeLink)
         setDownloadOfficeFileName(s3FullPathName)
      }

      setCurrentContentLink(contentList.links[idx].url);
      setCurrentContentType(contentList.links[idx].type);
      setCurrentContentLinkIdx(idx);
      setVisibleTile(true)
   }

   const _tileView = () => {
      return <>
         <div className="container">
            {/*    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4"> */}
            <div >
               <ul class="cards">
                  {(contentList.type !== "quiz" && contentList.links !== undefined) && contentList.links.map((item, idx) => {
                     return (
                        <li class="cards__item">
                           <div class="card">
                              <div class="card__image " style={{ backgroundImage: `url(${item.image})` }}>
                              </div>
                              <div class="card__content">
                                 <div class="card__title"> {item.title}</div>
                                 <p class="card__text">{item.description} </p>
                                 {/* <button class="btn btn--block card__btn"  onClick={() => _clickTileItem(idx)}>Open</button> */}
                                 <Button type='default' size='middle' shape='round'
                                    onClick={() => item.type !== "search" ? _clickTileItem(idx) : window.open(item.url, '_blank')}>
                                    {item.type !== "search" ? "View" : "Open Link"}</Button>
                              </div>
                           </div>
                        </li>
                     )
                  })
                  }
                  {contentList.type === "quiz" && <>
                     <li class="cards__item">
                        <div class="card">
                           <div class="card__image " style={{ backgroundImage: `url(../images/Exam.jpg)` }}>
                           </div>
                           <div class="card__content">
                              <div class="card__title"> {contentList.title}</div>
                              <p class="card__text">{contentList.otherdesc} </p>
                              {/* {quizStatus && <button class="btn btn--block card__btn"  onClick={() =>viewFormative()}>Open</button>}
                                                {!quizStatus && <button class="btn btn--block card__btn"  disabled>Closed</button>} */}

                              {quizStatus
                                 ?
                                 <>
                                    <Button type='default' size='middle' shape='round' onClick={() => viewFormative()}>View</Button>
                                 </>
                                 :
                                 <>
                                    <Button type='default' size='middle' shape='round' disabled>Closed</Button>
                                 </>
                              }

                              {/* {quizStatus && <Button type='default' size='middle' shape='round' onClick={() =>viewFormative()}>View</Button>}
                                                {!quizStatus && <Button type='default' size='middle' shape='round' disabled>Closed</Button>} */}
                           </div>
                        </div>
                     </li>
                  </>
                  }
               </ul>
            </div>
         </div>
      </>
   }

   const _downloadFile = (url, name) => {
      setDownloadStatus(true)
      fetch(url)
         .then(response => {
            response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               a.href = url;
               a.download = name;
               a.click();
               setDownloadStatus(false)
            });
         });
   };

   const _playContent = (type, link) => {
      //var test = link.replace(/ /g, '+').replace(/\#/g,'%23');
      switch (type) {
         case 'pdf':
            return <>
               <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "10px" }}>
                  <Button disabled={downloadStatus} loading={downloadStatus} type="primary" shape="round" icon={<CloudDownloadOutlined />} onClick={() => _downloadFile(currentS3ContentLink, downloadOfficeFileName)} >
                     {downloadStatus ? "Downloading ..." : "Download"}
                  </Button>
               </div>
               <div style={{ width : "100%", height:"calc(100vh - 300px)" }}>
                  <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                     <Viewer 
                        pageLayout={{transformSize: ({ size }) => ({
                           height: size.height + 30,
                           width: size.width + 30,
                        }),}}  
                        fileUrl={link}  
                        defaultScale={SpecialZoomLevel.PageWidth}   
                        initialPage={0} 
                     />
                  </Worker>
               </div>
               {/* <RenderPDFReader url={link} height={"calc(100vh - 300px)"} /> */}
               {/* <object data={link} width="100%" style={{ height: 'calc(100vh - 300px)' }}>
                  <p>Brainee LMS PDF - View<a href={link}> PDF!</a></p>
               </object> */}
               {/* <RenderPDFViewer url={link} height={"calc(100vh - 300px)"} showdownload={true} showprint={true} showpagecontrols={true} showfullscreen={true} /> */}
            </>
         case 'msoffice':
            return <>
               <div>
                  <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "10px" }}>
                     {/*                   <a className='downloadLink' href={currentS3ContentLink} download="downloadOfficeFileName.dmg">Download this File</a> */}
                     <Button disabled={downloadStatus} loading={downloadStatus} type="primary" shape="round" icon={<CloudDownloadOutlined />} onClick={() => _downloadFile(currentS3ContentLink, downloadOfficeFileName)} >
                        {downloadStatus ? "Downloading ..." : "Download"}
                     </Button>
                  </div>
                  <div style={{ display: "flex" }}>
                     <Iframe url={link}
                        position="absolute"
                        width="100%"
                        id="frameOffice"
                        height="90%"
                     />
                  </div>
               </div>
            </>
         case 'videolink': case 'youtube': case 'video':
            return <ReactPlayer
               controls={true}
               width='100%'
               height='100%'
               url={link.replace(/ /g, '+').replace(/#/g, '%23')}
            />
         case 'image':
            return <Image
               // width='100%'
               height='100%'
               src={link}
            />
         case 'search':
            return <WebsiteContent currentSectionIdx={currentSectionIdx} currentSectionContentIdx={currentSectionContentIdx} linkIndex={currentContentLinkIdx} />
         case 'quiz':
            return <div style={{ textAlign: "center", height: "100%", backgroundColor: "white" }}>
               <div class="parent">
                  <div class="child1"> <img src='../images/Exam.jpg' style={{ width: "80%", height: "100%" }}></img></div>
                  <div class="child2">
                     <h2 style={{ color: "white" }}>Quiz / Assessment</h2>
                     <div style={{
                        textAlign: "center", height: "calc(100% - 75px)", backgroundColor: "white",
                        display: "flex", justifyContent: "center", alignItems: "center",
                        borderRadius: "20px 0px 0px 20px;"
                     }}>
                        <div>
                           <h4 style={{ color: "black" }}> Description : {quizDesc}</h4>
                           <h6 style={{ color: "black" }}>{subjectDesc}</h6>
                           <h5 style={{ color: "black", paddingBottom: "10px" }}> Effective Date : {effectiveDate}</h5>
                           {quizStatus && <Button type="primary" onClick={() => viewFormative()} shape="round" icon={<EyeOutlined />} size={"large"}>View</Button>}
                           {!quizStatus && <Button type="primary" disabled shape="round" icon={<LockOutlined />} size={"large"}>Closed</Button>}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         default:
            return <>
               <div style={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
                  <Empty
                     description={
                        <span>
                           File is not recognize by the system.
                        </span>
                     }
                  >
                     <Button disabled={downloadStatus} loading={downloadStatus} type="primary" shape="round" icon={<CloudDownloadOutlined />} onClick={() => _downloadFile(currentS3ContentLink, downloadOfficeFileName)} >
                        {downloadStatus ? "Downloading ..." : "Download"}
                     </Button>
                     {/*           <a className='downloadLink' href={currentS3ContentLink} download={downloadOfficeFileName}>Download this File</a> */}
                  </Empty>
               </div>
            </>


      }
   }
   const viewFormative = () => {
      var firstContent = lessonState.sections[currentSectionIdx].contents[currentSectionContentIdx];

      var assessment_id = firstContent.otherid
      var assignment_id = firstContent.otherid2
      var q_type = firstContent.q_type;
      let role = Utils.getCurrentUserInfo(Enums.UserInfo.role)

      if (role === 'Admin' || role === 'Teacher') {
         if (q_type === 1)
            navigate('/formativeassessment/preview-pdf', { state: { "id": assessment_id, levelid, lessonid, mode } });
         else
            navigate('/formativeassessment/preview-manual', { state: { "id": assessment_id, levelid, lessonid, mode } });
      }
      else {
         // Student
         if (q_type === 1)
            navigate('/formativeassessment/answer-pdf', { state: { assessment_id, assignment_id, levelid, lessonid, mode } });
         else
            navigate('/formativeassessment/answer-manual', { state: { assessment_id, assignment_id, levelid, lessonid, mode } });
      }
   }

   const _playNext = () => {
      var idx = currentContentLinkIdx + 1 < contentCount ? currentContentLinkIdx + 1 : contentCount - 1;

      if (contentList.links[idx].url !== "") {
         var s3FullPathWithOfficeLink = ""
         var s3FullPathName = ""

         if (contentList.links[idx].type === "msoffice") {
            var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
            var s3File = contentList.links[idx].url.split(msOfficelink)
            s3FullPathWithOfficeLink = s3File[1]
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }
         else if (contentList.links[idx].type === "others") {
            s3FullPathWithOfficeLink = contentList.links[idx].url
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }

         setCurrentS3ContentLink(s3FullPathWithOfficeLink)
         setDownloadOfficeFileName(s3FullPathName)
      }

      setCurrentContentLink(contentList.links[idx].url);
      setCurrentContentType(contentList.links[idx].type);
      setCurrentContentLinkIdx(idx);
   };

   const _playPrevious = () => {
      var idx = currentContentLinkIdx - 1 >= 0 ? currentContentLinkIdx - 1 : 0;
      if (contentList.links[idx].url !== "") {
         var s3FullPathWithOfficeLink = ""
         var s3FullPathName = ""

         if (contentList.links[idx].type === "msoffice") {
            var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
            var s3File = contentList.links[idx].url.split(msOfficelink)
            s3FullPathWithOfficeLink = s3File[1]
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }
         else if (contentList.links[idx].type === "others") {
            s3FullPathWithOfficeLink = contentList.links[idx].url
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }

         setCurrentS3ContentLink(s3FullPathWithOfficeLink)
         setDownloadOfficeFileName(s3FullPathName)
      }

      setCurrentContentLink(contentList.links[idx].url);
      setCurrentContentType(contentList.links[idx].type);
      setCurrentContentLinkIdx(idx);
   };

   const _editLesson = (lessonIdx, topicIdx) => {
      setCurrentTopicEditIdx(topicIdx)
      setCurrentLessonEditIdx(lessonIdx)
      setVisible(true)
   }


   const _lessonClick = (topicIdx, lessonIdx, type) => {
      const itemIdx = lessonIdx.split('-');
      setCurrentSectionIdx(itemIdx[1])
      setCurrentSectionContentIdx(itemIdx[0])
      var firstContent = lessonState.sections[itemIdx[1]].contents[itemIdx[0]];
      if (firstContent.links.length > 0) {

         if (firstContent.links[0].url !== "") {
            var s3FullPathWithOfficeLink = ""
            var s3FullPathName = ""

            if (firstContent.links[0].type === "msoffice") {
               var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
               var s3File = firstContent.links[0].url.split(msOfficelink)
               s3FullPathWithOfficeLink = s3File[1]
               var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
               s3FullPathName = firstContent.links[0].title + "." + s3FileExt
            }
            else if (firstContent.links[0].type === "others") {
               s3FullPathWithOfficeLink = firstContent.links[0].url
               var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
               s3FullPathName = firstContent.links[0].title + "." + s3FileExt
            }

            setCurrentS3ContentLink(s3FullPathWithOfficeLink)
            setDownloadOfficeFileName(s3FullPathName)
         }

         setCurrentContentLink(firstContent.links[0].url);
         setCurrentContentType(firstContent.links[0].type);
      }
      else if (firstContent.type === "quiz") {
         var today = moment(new Date()).format('YYYY-MM-DD');
         //console.log("Date : " + today)
         var current = false;

         var tmpeffectivedate = firstContent.effectivedate;
         var eDate = ""
         if (tmpeffectivedate.includes("@")) {
            var tmpdatesplit = tmpeffectivedate.split("@");
            var startDate = moment(tmpdatesplit[0], 'YYYY-MM-DD').format('YYYY-MM-DD')
            var endDate = moment(tmpdatesplit[1], 'YYYY-MM-DD').add(1, 'd').format("YYYY-MM-DD")
            //console.log("Date Start : " + startDate)
            //console.log("Date End : " + endDate)
            //current = today.isBetween(moment(tmpdatesplit[0],'YYYY-MM-DD').format('YYYY-MM-DD'),moment(tmpdatesplit[1],'YYYY-MM-DD').add(1,'d').format("YYYY-MM-DD"), null, '[]') 
            current = (today >= startDate && today <= endDate)
            //console.log("Current ====: " + current)
            eDate = tmpdatesplit[0] + " - " + tmpdatesplit[1]
         }
         else {
            var effectivedate = moment(firstContent.effectivedate, 'YYYY-MM-DD').format('YYYY-MM-DD')
            eDate = firstContent.effectivedate + " - " + moment(firstContent.effectivedate, 'YYYY-MM-DD').add(1, 'd').format("YYYY-MM-DD")
            // current =  today.isBefore(effectivedate) 
            current = (today <= effectivedate)
         }

         setQuizStatus(current)
         setCurrentContentLink("");
         setCurrentContentType(firstContent.type);
         setEffectiveDate(eDate);
         setSubjectDesc(firstContent.otherdesc);
         setQuizDesc(firstContent.title)
      }
      else {
         setCurrentContentLink("");
         setCurrentContentType("");
      }
      setContentCount(firstContent.links.length);
      setCurrentContentLinkIdx(0);
      setContentList(firstContent);
   }


   const autoPlayClick = () => {
      if (lessonState.sections.length > 0) {
         setCurrentContentLinkIdx(0);
         if (contentList.links.length > 0) {

            if (contentList.links[0].url !== "") {
               var s3FullPathWithOfficeLink = ""
               var s3FullPathName = ""

               if (contentList.links[0].type === "msoffice") {
                  var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
                  var s3File = contentList.links[0].url.split(msOfficelink)
                  s3FullPathWithOfficeLink = s3File[1]
                  var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
                  s3FullPathName = contentList.links[0].title + "." + s3FileExt
               }
               else if (contentList.links[0].type === "others") {
                  s3FullPathWithOfficeLink = contentList.links[0].url
                  var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
                  s3FullPathName = contentList.links[0].title + "." + s3FileExt
               }

               setCurrentS3ContentLink(s3FullPathWithOfficeLink)
               setDownloadOfficeFileName(s3FullPathName)
            }

            setCurrentContentLink(contentList.links[0].url);
            setCurrentContentType(contentList.links[0].type);
         }
         else if (contentList.type === "quiz") {
            setCurrentContentLink("");
            setCurrentContentType(contentList.type);
         }
         setCurrentContentLinkIdx(0);
      }
      setViewState(1)
   }

   const _setToggle = () => {
      setToggleSideMenu(!toggleSideMenu);
   }

   return (<>
      {toggleSideMenu && <div className='button-side-menu' onClick={() => setToggleSideMenu(false)}>
         <div style={{ paddingTop: "3px", paddingRight: "10px" }}><ArrowLeftOutlined /></div>
         <div>Topic Lesson</div>
      </div>}
      <div className={toggleSideMenu ? "dashboard-area-all" : "dashboard-area"}>
         <div className="dashboard-item">
            <div className="dashboard-item-inner">
               {/* <ul>
                  <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                  <li><span>/</span></li>
                  <li className="active">Brainee LMS</li>
                  <li><span>/</span></li>
                  <li><a href="#" onClick={() => navigate('/subject')}>Lesson</a></li>
                  <li><span>/</span></li>
                  <li className="active">View</li>
               </ul> */}
               <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                  <Breadcrumb.Item>
                     <Link to="/home">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                  <Breadcrumb.Item>
                     <a href="#" onClick={() => navigate('/subject')}>Unit</a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>View</Breadcrumb.Item>
               </Breadcrumb>
            </div>
            <Divider />
            <div className="dashboard-item-inner">
               <div className="container">
                  <div className="row justify-between">
                     <div className="col-12">
                        <div className="LessonTitle"><Text elipsis>{lesson.title}</Text></div>
                     </div>

                  </div>
               </div>
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-9">
                        <div className="SubLessonTitle">{lesson.teacherName !== "" ? lesson.teacherName + "," : ""} {lesson.subject}</div>
                     </div>
                     <div className="col-3" >
                        <div className='LessonButtons'>
                           <button className='ButtonRight' style={viewState === 0 ? { backgroundColor: "#033D89" } : {}} onClick={() => setViewState(0)}><AppstoreOutlined /></button>
                           <button className='ButtonLeft' style={viewState === 1 ? { marginRight: "5px", backgroundColor: "#033D89" } : { marginRight: "5px" }} onClick={() => autoPlayClick()}><PlayCircleOutlined /></button>
                           {viewState === 1 && <><button className='ButtonRight' onClick={() => _playPrevious()}><CaretLeftOutlined /></button>
                              <button className='ButtonLeft' onClick={() => _playNext()}><CaretRightOutlined /></button> </>}

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="dashboard-item-inner3">
            {viewState === 1 ? <div className="container">
               <div className="row justify-content-center">
                  <div className="col-12">
                     <div className='LessonProper'> {_playContent(currentContentType, currentContentLink)}</div>
                  </div>
               </div>
            </div> :
               <div className='LessonProper'>
                  <SimpleBarReact style={{ height: "100%" }}>{_tileView()}</SimpleBarReact>
               </div>
            }
         </div>
         <div className="dashboard-item-inner3">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-12">
                     <div className='small-screen-tab'>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                           <TabPane tab="Overview" key="1">
                              <div dangerouslySetInnerHTML={{ __html: lesson.overview }} />
                           </TabPane>
                           <TabPane tab="Class Comment" key="2">
                              <Discussions Id={lesson.lessonId} />
                           </TabPane>
                           <TabPane tab="Notes" key="3">
                              <Notes Id={lesson.lessonId} />
                           </TabPane>
                           <TabPane tab="Topic Section" key="4">
                              {(!toggleSideMenu && lesson) &&
                                 <div className="container">
                                    <div style={{ paddingTop: "15px" }}>
                                       <TopicSection topics={lesson.sections} editLesson={_editLesson} lessonClick={_lessonClick} toggle={_setToggle} mode={"view"} />
                                    </div>
                                 </div>}
                           </TabPane>
                        </Tabs>
                     </div>
                     <div className='normal-screen-tab'>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                           <TabPane tab="Overview" key="1">
                              <div dangerouslySetInnerHTML={{ __html: lesson.overview }} />
                           </TabPane>
                           <TabPane tab="Class Comment" key="2">
                              <Discussions Id={lesson.lessonId} />
                           </TabPane>
                           <TabPane tab="Notes" key="3">
                              <Notes Id={lesson.lessonId} />
                           </TabPane>
                        </Tabs>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      {!toggleSideMenu && <div className="notice-area-topic notice-area">
         <div className="notice-item">
            <div className="container">
               <div style={{ paddingTop: "15px" }}>
                  <TopicSection topics={lesson.sections} editLesson={_editLesson} lessonClick={_lessonClick} toggle={_setToggle} mode={"view"} />
               </div>
            </div>
         </div>
      </div>}
      <Modal
         key="Modal-View-Mode"
         title="View Lesson"
         destroyOnClose={true}
         centered
         visible={visibleTile}
         onOk={() => setVisibleTile(false)}
         onCancel={() => setVisibleTile(false)}
         width='80%'
         // height='80%'
         okButtonProps={{
            style: {
               display: "none",
            },
         }}
      >
         <div className="container">
            <div className="row justify-content-center">
               <div className="col-12">
                  <div className='LessonProper'> {_playContent(currentContentType, currentContentLink)}</div>
               </div>
            </div>
         </div>
      </Modal>
   </>)
}

