import { Box } from 'theme-ui'
import ImagesPanel from './ImagesPanel'
import BackgroundPanel from './BackgroundPanel'
import ObjectsPanel from './ObjectsPanel'
import VectorPanel from './VectorPanel'
import TextPanel from './TextPanel'
import VideosPanel from './VideosPanel'
import AIPanel from './AIPanel'
import WebSearch from './Websearch'
import ThirdPartyPanel from './ThirdPartyPanel'
import MyFilesPanel from './MyFilesPanel'
import { Scrollbars } from 'react-custom-scrollbars'
import classNames from 'classnames'

function PanelItem({ panelOpen, activeTab }) {
  const className = classNames({
    'panel-item-container': true,
    open: panelOpen,
  })

  return (
    <div className={className}>
      <Box sx={{ flex: 1 }}>
        <Scrollbars
          renderThumbVertical={() => <div style={{ background: '#033D89' }}></div>}
          autoHide
        >
          {activeTab === 'images' && <ImagesPanel />}
          {activeTab === 'text' && <TextPanel />}
          {activeTab === 'objects' && <ObjectsPanel />}
          {activeTab === 'background' && <BackgroundPanel />}
          {activeTab === 'vector' && <VectorPanel />}
          {activeTab === 'videos' && <VideosPanel />}
          {activeTab === 'BraineeBot' && <AIPanel />}
          {activeTab === 'web' && <WebSearch />}
          {activeTab === 'Digital tools' && <ThirdPartyPanel />}
          {activeTab === 'My Files' && <MyFilesPanel />}
        </Scrollbars>
      </Box>
    </div>
  )
}

export default PanelItem
