/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, Spin, Tag, Empty, Typography, Table, Divider, Breadcrumb } from 'antd';
// import * as AntDIcons from '@ant-design/icons';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import SimpleBarReact from "simplebar-react";
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

const { Text, Title } = Typography;

var data_main = [];

export function MasteryReportType4(props) {
   const navigate = useNavigate();
   // const location = useLocation();]

   const search = window.location.search;
   const query = new URLSearchParams(search);

   const term = query.get('term');
   const level = query.get('level');
   const subject = query.get('subject');
   const assigned_by = query.get('assigned_by');

   // const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [data, setData] = useState({
      loading: false,
      hasData: false,
   })

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      getMasteryReportData(term, level, subject, assigned_by);
   }, []);

   const report_columns = [
      {
         title: 'Code',
         key: 'competency_code',
         dataIndex: 'competency_code',
         width: '10%',
      },
      {
         title: 'Competency',
         key: 'competency',
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Text>{object.competency}</Text>
         ),
         width: '54%',
      },
      {
         title: 'Proficiency',
         children: [
            {
               title: 'Level 1',
               key: 'level1_proficiency',
               render: object => (
                  object.level1_proficiency !== null && object.level1_proficiency !== '' &&
                  <Tag
                     style={{ fontSize: 12 }}
                     // color={object.level1_proficiency === "Beginner" ? '#5297DC' : (object.level1_proficiency === 'Proficient' ? '#369DA2' : '#6EA339')}
                     color={object.level1_proficiency === "Beginner" ? '#6395F9' : (object.level1_proficiency === 'Proficient' ? '#5AD8A6' : '#8593AE')}
                  >
                     {object.level1_proficiency}
                  </Tag>
               ),
               width: '12%',
               align: 'center',
            },
            {
               title: 'Level 2',
               key: 'level2_proficiency',
               render: object => (
                  object.level2_proficiency !== null && object.level2_proficiency !== '' &&
                  <Tag
                     style={{ fontSize: 12 }}
                     // color={object.level2_proficiency === "Beginner" ? '#5297DC' : (object.level2_proficiency === 'Proficient' ? '#369DA2' : '#6EA339')}
                     color={object.level2_proficiency === "Beginner" ? '#6395F9' : (object.level2_proficiency === 'Proficient' ? '#5AD8A6' : '#8593AE')}
                  >
                     {object.level2_proficiency}
                  </Tag>


               ),
               width: '12%',
               align: 'center',
            },
            {
               title: 'Level 3',
               key: 'level3_proficiency',
               render: object => (
                  object.level3_proficiency !== null && object.level3_proficiency !== '' &&
                  <Tag
                     style={{ fontSize: 12 }}
                     // color={object.level3_proficiency === "Beginner" ? '#5297DC' : (object.level3_proficiency === 'Proficient' ? '#369DA2' : '#6EA339')}
                     color={object.level3_proficiency === "Beginner" ? '#6395F9' : (object.level3_proficiency === 'Proficient' ? '#5AD8A6' : '#8593AE')}
                  >
                     {object.level3_proficiency}
                  </Tag>
               ),
               width: '12%',
               align: 'center',
            },
         ]
      },

   ];

   const getMasteryReportData = (term, level, subject, assigned_by) => {
      setData({
         loading: true,
         hasData: false,
      });

      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/getmasteryreportdata4?term=${term}&level=${level}&subject=${subject}&assigned_by=${assigned_by}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            data_main = response.data;

            setData({
               loading: false,
               hasData: data_main.length > 0 ? true : false,
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   function GenerateReport() {
      var student = null

      var retVal = (
         <>
            <div style={{ width: '100%', textAlign: 'center' }}>
               <h5><b>Individual Student Report</b></h5>
            </div>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Teacher: <b>{data_main[0].teacher}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Quarter: <b>{term === '0' ? "All" : data_main[0].term}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Subject: <b>{subject === '0' ? "All" : data_main[0].subject}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px", paddingBottom: "20px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Grade: <b>{level === '0' ? "All" : data_main[0].grade_level}</b></span>
               </Col>
            </Row>

            {
               data_main.map((obj, i) => {
                  if (obj.student_id !== student) {
                     student = obj.student_id;
                     var table_data = data_main.filter(data => data.student_id === obj.student_id);

                     return (
                        <>
                           <Divider orientation="left">
                              <Title level={5} style={{ textAlign: "center", color: "#369DA2" }}>{obj.student_name}</Title>
                           </Divider>
                           <Row style={{ paddingBottom: '2rem' }}>
                              <Col span={24}>
                                 <Table
                                    size='small'
                                    columns={report_columns}
                                    dataSource={table_data}
                                    pagination={false}
                                    rowKey={'uid'}
                                    scroll={{
                                       x: '1000px'
                                    }}
                                 />
                              </Col>
                           </Row>
                        </>
                     )
                  }
               })
            }

            <Row>
               <Col span={24}>
                  <span></span>
               </Col>
            </Row>

         </>
      );

      return retVal;
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/masteryassessmentreport')}>Mastery Assessment Report</a></li>
                     <li><span>/</span></li>
                     <li className="active">Report Type 4</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/masteryassessmentreport')}>Mastery Assessment Report</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Report Type 4</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               {/* <SimpleBarReact style={{ height: "calc(100vh - 55px)" }}> */}
               {data.loading === false
                  ?
                  <>
                     {
                        data.hasData
                           ?
                           // <div className='section_to_print' style={{ width: "calc(100% - 6px)" }}>
                           <div className='section_to_print'>
                              {GenerateReport()}
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              <Empty description="No data available for report" image="../images/brainee_mascot.png" />
                           </div>
                     }
                  </>
                  :
                  <>
                     <div className='mastery_assessment_list'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </>
               }
               {/* </SimpleBarReact> */}
            </div>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}