/* eslint-disable default-case */
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import { useNavigate } from "react-router-dom";
import { Flex } from 'theme-ui'
import Footer from './Footer';
import Toolbox from './Toolbox/Toolbox';
import './EditLessonPage.scss'
import CanvasComponent from './CanvasComponent';
import Moveable from "react-moveable";
import Panels from './Panels/Panels';
import { Button ,Tooltip, message, Breadcrumb} from 'antd';
import { UpSquareOutlined,DownSquareOutlined,LeftSquareOutlined,RightSquareOutlined,CloseCircleOutlined } from '@ant-design/icons';
import Utils from '../../misc/Utils';
import LessonService from '../../services/LessonService';
import { CanvasContext } from './hooks/LessonPage';
import { Link } from 'react-router-dom';


export function EditLessonPage(props) {
   const navigate = useNavigate();
   const { state, actions } = useContext(CanvasContext)
   const [enableQuillToolbar, setEnableQuillToolbar] = useState(false)
   const [editorRef, setEditorRef] = useState(null)
   const [id, setId] = useState("")
  //  const [moveableRect, setMoveableRect] = useState(null)
   const containerRef = useRef(null)
   const divWrapper = useRef(null)
   const isSelectAll = useRef(false)  
   const [scale, setScale] = useState(1)

   const [scaleWidth, setScaleWidth] = useState(1)
   const [scaleHeight, setScaleHeight] = useState(1)
   

   useEffect(() => {
    setEditorRef(containerRef)
  }, [containerRef]);

  useEffect(() => {
    if (state?.isSave)
      actions.saveSectionData()

  }, [state?.canvasListData, state?.isSave]);


  useEffect(() => {
    // Utils.verifyJwt();
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const Id = query.get('Id')
    setId(Id)
    const lessonIdx = query.get('lessonIdx')
    actions.setLessonIdx(lessonIdx)
    const topicIdx = query.get('topicIdx')
    actions.setTopicIdx(topicIdx)
    actions.setLessonId(Id)
    LessonService.getLessonById(Id).then((lesson) => {
      let data = lesson.data

      if (data.sections === null)
         data.sections = []

      data = Utils.convertKeysToLowerCase(data)
      actions.setDataLesson(data)
      if (data.sectionversion === 0)
      {
        var listData = {backgroundcolor : "white", backgroundimage : "none", data : canvasData}
        actions.setCanvasListData([...canvasData, listData])
      }
      else
      {
        var canvasListData = data.sections[topicIdx].contents.filter(p => p.id === lessonIdx)[0]?.data
        var canvasData = []
        if (canvasListData)
        {
           if (canvasListData.length > 0)
            {
              // actions.setCanvasListData([...canvasListData])
              // actions.setSelectedIdxCanvas(state?.canvasListDataIdx)
              canvasData = [...{...canvasListData[state?.canvasListDataIdx]}?.data]
              actions.setCanvasData(canvasData)
              actions.setCanvasListData([...canvasListData])
              actions.setBodyBackgroundImage(canvasListData[state?.canvasListDataIdx].backgroundimage)
              actions.setBodyBackgroundColor(canvasListData[state?.canvasListDataIdx].backgroundcolor)
              actions.setCanvasListDataIdx(0)
            }
           else
           {
            var listData = {backgroundcolor : "white", backgroundimage : "none", data : canvasData}
            actions.setCanvasListData([...canvasData, listData])
           } 
        } 
        else
        {
          var listData = {backgroundcolor : "white", backgroundimage : "none", data : canvasData}
          actions.setCanvasListData([...canvasData, listData])
        }

        actions.setCanvasData(canvasData)

      }
    }).catch((ex) => {
      message.error(ex.message)
   })

    setEditorRef(containerRef)

  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  //for zoom detection

  useEffect(() => {
    if (!divWrapper.current) return;
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {

    //   if (divWrapper.current.offsetHeight === 0) return

      var elHeight = containerRef.current?.clientHeight;
      var elWidth = containerRef.current?.clientWidth;  
      
      var scale;
      var width = divWrapper.current?.clientWidth/ elWidth
      var height =  divWrapper.current?.clientHeight / elHeight
      scale = Math.min(
        divWrapper.current?.offsetWidth/ elWidth,    
        divWrapper.current?.offsetHeight / elHeight
      );


     // scale = scale > 1 ? 1 : scale
      var minHeight = scale === 0 ? scaleHeight : height-0.2
      var minWidth = scale === 0 ? scaleWidth : scale-0.05

      setScale(minWidth)
      setScaleWidth(minWidth)
      setScaleHeight(minHeight)
      var s= ""
    });
    resizeObserver.observe(divWrapper.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);


   const handleKeyDown = useCallback(
     event => {
       if (event.key === "Delete") {
         actions.deleteElement()
       } else if (["a", "A"].includes(event.key) && event.ctrlKey) {
         event.preventDefault()
         actions.selectAllElement()
       }
     },
     [actions.deleteElement, actions.selectAllElement]
   )
 
   const outSideClickHandler = () => {
     isSelectAll.current = false
     actions.setActiveSelection(new Set())
   }
 
   const handleMouseDown = useCallback(event => {
     if (!isSelectAll.current) {
       return
     }
 
     outSideClickHandler()
     isSelectAll.current = false
   }, [])
 
   React.useEffect(() => {
     document.addEventListener("keydown", handleKeyDown)
     document.addEventListener("mousedown", handleMouseDown)
     return () => {
       document.removeEventListener("keydown", handleKeyDown)
       document.removeEventListener("mousedown", handleMouseDown)
     }
   }, [handleKeyDown, handleMouseDown])


   function setZoom(zoom,el) {
      
      el.style.transformOrigin = [0,0];
      var p = ["webkit", "moz", "ms", "o"],
            s = "scale(" + zoom + ")",
            oString = (el.style.transformOrigin[0] * 100) + "% " + (el.style.transformOrigin[1] * 100) + "%";
  
      for (var i = 0; i < p.length; i++) {
          el.style[p[i] + "Transform"] = s;
          el.style[p[i] + "TransformOrigin"] = oString;
      }
  
      el.style["transform"] = s;
      // el.style["transformOrigin"] = oString;
      
  }

   const ZoomChange = (value) =>
   {
      var zoomScale = Number(value);
      setZoom(zoomScale,document.getElementById('imgItem'))      
      // setZoomValue(value)
   }


   const clickFlex = () =>
   {
     actions.setTargetRef(null)
     setEnableQuillToolbar(false)
     actions.setCurrentElementId(null)
   }

   const MoveableAbles = {
    name: "editable",
    props: [],
    events: [],
    render(moveable, React) {
        const rect = moveable.getRect();
        const { pos2 } = moveable.state;

        const EditableViewer = moveable.useCSS("div", `
        {
            position: absolute;
            left: 0px;
            top: 0px;
            will-change: transform;
            transform-origin: 0px 0px;
        }
        .custom-button {
            width: 24px;
            height: 24px;
            margin-bottom: 4px;
            background: #4af;
            border-radius: 4px;
            appearance: none;
            border: 0;
            color: white;
            font-weight: bold;
        }
            `);
        return <div>
       <div key={"dimension-viewer"} className={"moveable-dimension"} style={{
            position: "absolute",
            left: `${rect.width / 2}px`,
            top: `${rect.height + 20}px`,
            // background: "#4af",
            borderRadius: "2px",
            padding: "2px 4px",
            color: "white",
            fontSize: "13px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            willChange: "transform",
            transform: `translate(-50%, 0px)`,
        }}>
            {/* {Math.round(rect.offsetWidth)} x {Math.round(rect.offsetHeight)} */}
            <Flex sx={{ flex: 1, flexDirection: 'column' }}>
              <Flex sx={{ flex: 1, marginRight : "5px" }}>       
                <Tooltip title="Forward">
                  <Button type="primary" icon={<UpSquareOutlined style={{ fontSize: '26px'}} />} size='middle' style={{marginRight : "2px", color : "white"}} onClick={() => actions.CanvasItemChangePosition('up')} />
                </Tooltip>
                <Tooltip title="Backward">
                  <Button  type="primary" icon={<DownSquareOutlined style={{ fontSize: '26px'}}  />} size='middle' style={{marginRight : "2px", color : "white"}} onClick={() => actions.CanvasItemChangePosition('down')}  />
                </Tooltip>
                <Tooltip title="To Back">
                  <Button type="primary" icon={<LeftSquareOutlined style={{ fontSize: '26px'}}  />} size='middle' style={{marginRight : "2px", color : "white"}}  onClick={() => actions.CanvasItemChangePosition('back')}   />
                </Tooltip>
                <Tooltip title="To Front">
                  <Button type="primary" icon={<RightSquareOutlined style={{ fontSize: '26px'}}  />} size='middle' style={{marginRight : "2px", color : "white"}}  onClick={() => actions.CanvasItemChangePosition('front')}   />
                </Tooltip>                                
              </Flex>
          </Flex>
        </div>          
        <EditableViewer key={"editable-viewer"} className={"moveable-editable"} style={{
            transform: `translate(${pos2[0]}px, ${pos2[1]}px) rotate(${rect.rotation}deg) translate(10px)`,
        }}>
           <Tooltip title="Delete Item">
              <Button type="primary" icon={<CloseCircleOutlined style={{ fontSize: '26px'}}  />} size='middle' style={{marginRight : "5px", color : "white"}} onClick={() => {
                actions.deleteData()
            }} />
           </Tooltip>   
        </EditableViewer>
        </div>;
    },
} 

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li >Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/subject')}>Lesson</a></li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate(`/subject/edit?Id=${id}`)}>Edit</a></li>
                     <li><span>/</span></li>
                     <li className="active">Add Resources</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                  <Breadcrumb.Item>
                     {/* <AntDIcons.HomeOutlined /> */}
                     <Link to=   "/home">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                  <Breadcrumb.Item>
                     <Link to="/subject">Unit</Link>
                  </Breadcrumb.Item> 
                  <Breadcrumb.Item>
                    <Link to={`/subject/edit?Id=${id}`}>Edit</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Add Resource</Breadcrumb.Item>
               </Breadcrumb>
               </div>
            </div>
            <div class="main" id='scaleable-wrapper'>
                  <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                     <Flex sx={{ flex: 1 }}>
                        <Panels />
                        <Flex
                           sx={{
                           flex: 1,
                           flexDirection: 'column',
                           }}
                        >
                           <Toolbox isEditEnable={enableQuillToolbar} />
                           <div class="scaleable-wrapper" id="scaleable-wrapper" ref={divWrapper} onClick={() => clickFlex()}
                             style={{height : "calc(100vh - 268px)"}}
                            >
                              <div ref={containerRef} class="very-specific-design"  id='very-specific-design'
                                    style={{ 
                                              backgroundColor : state?.bodyBackgroundColor,
                                              backgroundImage :  (`url(${state?.bodyBackgroundImage})`) ,
                                              transform : `translate(-50%, -50%) scale(${scale})`,
                                            }}  
                              >
                                      {state?.canvasData?.map((canvas) => {
                                        return <CanvasComponent {...canvas} />;
                                    })} 
                              </div>
                            <Moveable
                                target={state?.targetRef}
                                ables={[MoveableAbles]}
                                origin={false}
                                 useAccuratePosition={true}
                                onRender={e => {
                                    e.target.style.cssText += e.cssText;
                                    }}
                                props={{
                                            editable: true,
                                      }}
                                draggable={true}
                                rotatable={true}                    
                                scalable={true}
                                resizable={(state?.activeEditable.type === "TEXT" || state?.activeEditable.type === "FILE") ? true : false}
                                keepRatio={false}
                                throttleScale={0}
                                renderDirections={["nw","n","ne","w","e","sw","s","se"]}
                                onScale={e => {
                                    e.target.style.transform = e.drag.transform;
                                    var data = {height : `${e.height}px`, 
                                    width : `${e.height}px` , 
                                    transform : e.transform,
                                    top : `${e.top}px` ,
                                    left : `${e.left}px` ,
                                    type : state?.activeEditable.type 
                                  }
                                  actions.updateCanvasOrientation(data, "drag")                        
                                }}
                                onScaleEnd={({ target, isDrag, clientX, clientY }) => {
                                  console.log("onScaleEnd", target, isDrag);
                                  actions.setIsSave(true)
                                }}                    
                                onDrag={e => {
                                  e.target.style.transform = e.transform;
                                  var data = {height : `${e.height}px`, 
                                              width : `${e.height}px` , 
                                              transform : e.transform,
                                              top : `${e.top}px` ,
                                              left : `${e.left}px` ,
                                              type : state?.activeEditable.type 
                                            }
                                    actions.updateCanvasOrientation(data, "drag")
                                                
                                }}        
                                onDragEnd={({ target, isDrag, clientX, clientY }) => {
                                  console.log("onDragEnd", target, isDrag);
                                  actions.setIsSave(true)
                                }}                                               
                                onResize={e => {
                                    e.target.style.width = `${e.width}px`;
                                    e.target.style.height = `${e.height}px`;
                                    e.target.style.transform = e.drag.transform;
                                    var data = {height : `${e.height}px`, 
                                          width : `${e.width}px` , 
                                          transform : e.transform,
                                          top : `${e.top}px` ,
                                          left : `${e.left}px` ,
                                          type : state?.activeEditable.type 
                                        }
                                    // var dataLesson = state?.activeEditable.type    
                                    actions.updateCanvasOrientation(data , "resize")                                              
                                }}
                                onResizeEnd={({ target, isDrag, clientX, clientY }) => {
                                  // This is a special case when a text object were resized. 
                                  // Capture the text height and width
                                  // console.log(`width : ${target.clientWidth} | height : ${target.clientHeight}` );
                                  var data = {
                                        height : `${target?.clientHeight}px`, 
                                        width : `${target?.clientWidth}px` , 
                                        type : "TEXT-MOVABLE"
                                      }
                                  // var dataLesson = state?.activeEditable.type    
                                  actions.updateCanvasOrientation(data , "resize")                                      
                                  actions.setIsSave(true)
                                }}
                                onRotate={e => {
                                  e.target.style.transform = e.drag.transform;
                                  var data = {height : `${e.height}px`, 
                                              width : `${e.height}px` , 
                                              transform : e.transform,
                                              top : `${e.top}px` ,
                                              left : `${e.left}px` ,
                                              type : state?.activeEditable.type 
                                            }
                                  actions.updateCanvasOrientation(data , "rotate")                                            
                                }}          
                                onRotateEnd={({ target, isDrag, clientX, clientY }) => {
                                  console.log("onRotateEnd", target, isDrag);
                                  actions.setIsSave(true)
                              }}                                
                            />     
                           </div>                           
                           <Footer ZoomChange={ZoomChange} />
                        </Flex>                     
                     </Flex>
                  </Flex>   
            </div>
         </div>
      </>
   );
}