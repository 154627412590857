import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
   Popconfirm, Input, Tabs, Table, Typography, Space, Tooltip, Button, Badge,
   Tag, Descriptions, Divider, message, Modal, List, Avatar, Row, Col, InputNumber, Spin, Breadcrumb
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
import './Assessment.css';
import './Tracker.css';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

// import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useDownloadExcel } from 'react-export-table-to-excel';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

// import {useSelector, useDispatch} from 'react-redux'
// import { update_option_data } from '../../actions';

const { Text } = Typography;
// const { TextArea } = Input;

// let sectionIdx = 1;
// let qcardIdx = 1;
// let idxCol = [];
// let matched = false;
// let essayIdx = -1;



export function BDiagnosticTracker() {
   const navigate = useNavigate();
   const location = useLocation();
   const tableRef = useRef(null);

   const search = window.location.search;
   const query = new URLSearchParams(search);

   const assign_id = query.get('id');
   const origin = query.get('origin');
   const type = query.get('type');

   // const assign_id = location.state.assign_id;
   // // const title = location.state.title;
   // const origin = location.state.origin;

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [assignInfo, setAssignInfo] = useState(null);
   const [trackerList, setTrackerList] = useState([]);
   const [filteredTrackerList, setFilteredTrackerList] = useState([]);
   const [legendCount, setLegendCount] = useState({
      submitted: 0,
      started: 0,
      not_started: 0
   });
   const [trackerLoading, setTrackerLoading] = useState(false);
   const [recheckLoading, setRecheckLoading] = useState(false);
   const [assessmentTitle, setAssessmentTitle] = useState("");

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   const CancelToken = axios.CancelToken;
   const source = CancelToken.source();

   useEffect(() => {
      Utils.verifyJwt();

      window.addEventListener('error', e => {
         if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
            const resizeObserverErrDiv = document.getElementById(
               'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
               'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
               resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
               resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
         }
      });

      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);
      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher') {
         navigate('/home');
      }
      else {
         getAssignInfo(assign_id);
      }

      return () => {
         source.cancel()
      }
   }, [assign_id]);

   const getAssignInfo = (assign_id) => {
      var api = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/assignmentinfo?id=${assign_id}`;
      axios.get(api, {
         cancelToken: source.token
      })
         .then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               setAssignInfo(data);
               setAssessmentTitle(data.title);
            }

            getTrackerList(assign_id);
         }, (error) => {
            if (axios.isCancel(error)) {
               console.log('Successfully aborted!');
            } else {
               console.log(error.message);
            }
         });
   }

   const getTrackerList = (assign_id) => {
      setTrackerLoading(true);

      var api = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/gettrackerlist?id=${assign_id}`;
      axios.get(api, {
         cancelToken: source.token
      })
         .then((response) => {
            console.log(response);

            if (response.data) {
               setTrackerLoading(false);
               const data = response.data;

               var legend = {
                  submitted: 0,
                  started: 0,
                  not_started: 0
               }

               for (var i = 0; i < data.length; i++) {
                  if (data[i].submit_status === 1)
                     legend.submitted++;
                  else if (data[i].submit_status === 0)
                     legend.started++;
                  else
                     legend.not_started++;
               }

               setLegendCount(legend);
               setTrackerList(data);
               setFilteredTrackerList(data);
            }
         }, (error) => {
            if (axios.isCancel(error)) {
               console.log('Successfully aborted!');
            } else {
               console.log(error.message);
            }
         });
   }

   function getItem(label, key, icon, children) {
      return {
         key,
         icon,
         children,
         label,
      };
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const tracker_columns = [
      {
         title: 'Name',
         key: 'fullname',
         fixed: 'left',
         sorter: (a, b) => { return a.fullname.localeCompare(b.fullname) },
         ...getColumnSearchProps('fullname'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip
               placement="topLeft"
               title={
                  <Space direction='vertical'>
                     <span>Browser: {object.browser}</span>
                     <span>Browser Version: {object.browser_version}</span>
                     <span>OS: {object.os_platform}</span>
                  </Space>
               }
            >
               <Tag
                  style={{ fontSize: 12, cursor: 'default' }}
                  color={object.submit_status === 1 ? 'green' : (object.submit_status === 0 ? 'gold' : 'red')}
               >
                  {object.fullname.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
               </Tag>
            </Tooltip>
         ),
         width: '16%'
      },
      {
         title: 'Gender',
         key: 'gender',
         sorter: (a, b) => { return a.gender.localeCompare(b.gender) },
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Text>{object.gender.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</Text>
         ),
         width: '8%'
      },
      {
         title: 'Level',
         key: 'level',
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.level}>
               <Text>{object.level}</Text>
            </Tooltip>
         ),
         width: '9%'
      },

      {
         title: 'Section',
         key: 'section',
         sorter: (a, b) => { return a.section.localeCompare(b.section) },
         ...getColumnSearchProps('section'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.section}>
               <Text>{object.section}</Text>
            </Tooltip>
         ),
         width: '10%'
      },
      {
         title: 'Score',
         key: 'score',
         sorter: (a, b) => a.score - b.score,
         // ellipsis: {
         //    showTitle: true,
         // },
         render: object => (
            // <Text>{object.status === "" ? '' : object.score + "/" + object.total_points}</Text>
            // <Text>{ object.score + "/" + object.total_points }</Text>
            <Text>{object.status === "" ? '' : object.score}</Text>
         ),
         width: '7%'
      },
      {
         title: 'Percent',
         key: 'percentage',
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Text>{object.status === "" ? '' : object.percentage + "%"}</Text>
            // <Text>{ object.percentage + "%" }</Text>
         ),
         width: '8%'
      },
      {
         title: 'Status',
         key: 'status',
         sorter: (a, b) => { return a.status.localeCompare(b.status) },
         // dataIndex: 'status',
         // ellipsis: {
         //    showTitle: true,
         // },
         render: object => (
            object.status !== "" &&
            <Tag
               style={{ fontSize: 12 }}
               color={object.status === "Passed" ? 'green' : 'red'}
            >
               {object.status}
            </Tag>
         ),
         width: '8%'
      },
      {
         title: 'Started',
         key: 'start_date',
         dataIndex: 'start_date',
         sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
         width: '13%'
      },
      {
         title: 'Submitted',
         key: 'end_date',
         dataIndex: 'end_date',
         sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
         width: '13%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               {payload.submit_status === 1 &&
                  <Space size="small">
                     <Tooltip placement="top" title='View Answer'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleViewAnswer(payload.assessment_id, payload.student_id, payload.fullname)} /></Tooltip>
                     <Tooltip placement="top" title='Allow Retake'>
                        <Popconfirm
                           title="Allow this student to retake?"
                           onConfirm={() => handleAllowRetake(payload.assessment_id, payload.assign_id, payload.student_id)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <AntDIcons.FormOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>

                     </Tooltip>
                  </Space>
               }
            </>
         },
         width: '8%'
      },
   ].filter(item => !item.hidden);

   const handleViewAnswer = (assessment_id, student_id, student_name) => {
      if (assignInfo.question_type.toString() === '1') {
         navigate('/bdiagnostic/teacher/view-ans-pdf', { state: { student_name, assessment_id, assignInfo, student_id, origin, type } });
      }
      else if (assignInfo.question_type.toString() === '2') {
         navigate('/bdiagnostic/teacher/view-ans-manual', { state: { student_name, assessment_id, assignInfo, student_id, origin, type } });
      }
   }

   const handleAllowRetake = (assessment_id, assign_id, student_id) => {
      const key = 'updatable';
      message.loading({ content: "Allowing student to retake...", key });
      // setTrackerLoading(true);

      axios.post(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/allowretakesummative?assessment_id=${assessment_id}&assign_id=${assign_id}&student_id=${student_id}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            message.success({ content: "Student is now allowed to retake", key, duration: 1 });
            updateTrackerList(assign_id);
         } else {
            message.error({ content: "Maximum retakes reached!", key, duration: 1 });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleRecheckAnswers = (assign_id) => {
      const key = 'updatable';
      // message.loading({content: "Re-cheking answers...", key});
      setRecheckLoading(true);

      axios.post(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/recheckanswers?id=${assign_id}`).then((response) => {
         console.log(response);

         //if (response.data) {
         message.success({ content: "Re-checking complete!", key, duration: 1 });
         setRecheckLoading(false);
         updateTrackerList(assign_id);
         //}
      }, (error) => {
         console.log(error.message);
      });
   }

   const updateTrackerList = (assign_id) => {
      setTrackerLoading(true);

      axios.get(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/gettrackerlist?id=${assign_id}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            var legend = {
               submitted: 0,
               started: 0,
               not_started: 0
            }

            for (var i = 0; i < data.length; i++) {
               if (data[i].submit_status === 1)
                  legend.submitted++;
               else if (data[i].submit_status === 0)
                  legend.started++;
               else
                  legend.not_started++;
            }

            setLegendCount(legend);
            setTrackerList(data);
            setFilteredTrackerList(data);

            setTrackerLoading(false);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleUpdateTracker = () => {
      updateTrackerList(assign_id);
   }

   const handleFilterTracker = (status_count, filterby) => {
      if (status_count > 0) {
         var filtered = [...trackerList.filter(p => p.submit_status === filterby)]
         setFilteredTrackerList(filtered);
      }
   }

   const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: assignInfo !== null ? assignInfo.title.replace(/ /g, '_') : '',
      sheet: 'Result'
   });

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate(`/bdiagnostic?type=${type}`, { state: { activetab: origin } })}>Diagnostic Assessments</a></li>
                     <li><span>/</span></li>
                     <li className="active">Tracker</li>
                  </ul> */}

                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate(`/bdiagnostic?type=${type}`, { state: { activetab: origin } })}>{type === 'pre' ? '(Pre) ' : '(Post) '} Diagnostic Assessment</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Tracker</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <div className="dashboard-item-inner3" style={{ overflow: "auto" }}>
                  {assignInfo !== 'undefined' &&
                     assignInfo !== null &&
                     <Descriptions bordered size='small'
                        column={{
                           xxl: 4,
                           xl: 3,
                           lg: 3,
                           md: 3,
                           sm: 2,
                           xs: 1,
                        }}>
                        <Descriptions.Item label="Title" span={4} style={{ fontSize: 13 }}>
                           <b>{assignInfo.title}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Start Date" span={2} style={{ fontSize: 13 }}>
                           <b>{moment(assignInfo.start_date).format("MMM. DD, YYYY hh:mm A")}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="End Date" span={2} style={{ fontSize: 13 }}>
                           <b>{moment(assignInfo.end_date).format("MMM. DD, YYYY hh:mm A")}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Duration" span={2} style={{ fontSize: 13 }}>
                           <b>{assignInfo.duration + " min(s)"}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Passing Percentage" span={2} style={{ fontSize: 13 }}>
                           <b>{assignInfo.passing_percentage + "%"}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Actions" span={2} style={{ fontSize: 13 }}>
                           <Space size={'small'}>
                              {/* <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleCheckEssay()} style={{ fontSize: 12 }}>Check Essay</Button> */}
                              <Button className='button-shadow' size='small' shape='round' type='primary' loading={recheckLoading} onClick={() => handleRecheckAnswers(assign_id)} style={{ fontSize: 12 }}>Re-check Answers</Button>
                           </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Total Points" span={2} style={{ fontSize: 13 }}>
                           <b>{trackerList.length > 0 ? (trackerList[0].total_points !== 0 ? trackerList[0].total_points : <Text style={{ color: '#70D0D4', fontStyle: 'italic' }}>Not yet avaialble.</Text>) : <Text style={{ color: '#70D0D4', fontStyle: 'italic' }}>Not yet avaialble.</Text>}</b>
                        </Descriptions.Item>
                     </Descriptions>
                  }

                  {/* <Divider /> */}
                  <Row style={{ paddingTop: 20, paddingBottom: '20px', gap: '20px', justifyContent: 'center' }}>
                     {/* <Text>Legend</Text> */}
                     <Badge count={legendCount.submitted} style={{ backgroundColor: 'green' }} overflowCount={999}>
                        <Tag color={"green"} style={{ cursor: legendCount.submitted > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.submitted, 1)}>Submitted</Tag>
                     </Badge>
                     <Badge count={legendCount.started} style={{ backgroundColor: 'gold' }} overflowCount={999}>
                        <Tag color={"gold"} style={{ cursor: legendCount.started > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.started, 0)}>Answering</Tag>
                     </Badge>
                     <Badge count={legendCount.not_started} style={{ backgroundColor: 'red' }} overflowCount={999}>
                        <Tag color={"red"} style={{ cursor: legendCount.not_started > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.not_started, -1)}>Not Started</Tag>
                     </Badge>
                     <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleUpdateTracker()} loading={trackerLoading} style={{ fontSize: 12 }}>Update Tracker</Button>
                     <Button className='button-shadow' type="primary" shape='round' size='small' onClick={onDownload} style={{ fontSize: 12 }}> Export to Excel </Button>
                  </Row>

                  {/* <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px', paddingBottom: '10px' }}>
                     <Button className='button-shadow' type="primary" shape='round' onClick={onDownload}> Export to Excel </Button>
                  </div> */}

                  {/* <div style={{ overflow: "auto" }}> */}
                  {/* <DownloadTableExcel
                        filename="Assessment"
                        sheet="Assessment Result"
                        currentTableRef={tableRef.current}
                     >

                        <Button type="primary" shape='round'>
                           Export To Excel
                        </Button>
                     </DownloadTableExcel> */}

                  <Table
                     ref={tableRef}
                     rowKey={'uid'}
                     // scroll={{
                     //    // y: 'calc(100svh - 20rem)',
                     //    x: '1400px'
                     // }}
                     loading={trackerLoading}
                     // style={{ fontSize: '12px', height: 'calc(100svh - 17rem' }}
                     style={{ fontSize: '12px' }}
                     size='small'
                     columns={tracker_columns}
                     // dataSource={trackerList}
                     dataSource={filteredTrackerList}
                     scroll={{
                        x: '1000px',
                        y: 'calc(100vh - 21.5rem)',
                     }}
                     footer={() => 'Total Records: ' + filteredTrackerList.length}
                     // footer={() => 'Total Records: ' + trackerList.length}

                     pagination={false}
                  // pagination={{ pageSize: 10, position: ['topRight', 'bottomRight'] }}
                  // expandable={{
                  //    expandedRowRender: record => <>
                  //    <Space>
                  //    <p style={{ margin: 0 }}>Browser <Tag color={"#4abec7"}>{record.browser}</Tag></p>
                  //    <p style={{ margin: 0 }}>Browser Version <Tag color={"#4abec7"}>{record.browser_version}</Tag></p>
                  //    <p style={{ margin: 0 }}>Operating System <Tag color={"#4abec7"}>{record.os_platform}</Tag></p>
                  //    </Space>
                  //    </>
                  // }}
                  // scroll={{ y: 240 }}
                  // rowClassName={ (record) => { record.submit_status === 1 ? 'ass_submitted' : (record.submit_status === 0 ? 'ass_started' : 'ass_not_started') }   }
                  // rowClassName={(record) => record.submit_status === 1 ? 'ass_submitted' : (record.submit_status === 0 ? 'ass_started' : 'ass_not_started')}
                  />
                  {/* </div> */}
               </div>
            </div>
         </div>
      </>
   );
}