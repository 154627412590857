import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Typography, Tooltip, Button, Select, message, Upload, Space, Modal, Empty, Spin, Progress, Image, Breadcrumb } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StickyBox from "react-sticky-box";
// import AllPagesPDFViewer from '../../components/pdf-viewer/all-pages';
// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import AssessmentTools from '../../components/assessment/AssessmentTools';
// import { CardRequestHelper } from './Helpers';
import { CardRequestHelper } from '../../helper/AQHelper';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import Split from 'react-split';
// import './SplitPanel.css';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { BrowserView, MobileView } from 'react-device-detect';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Title } = Typography;

var assOptionsTemplate = {
   id: '',
   assessment_id: '',
   assigned_by: '1',
   duration: '1',
   allowed_attempts: '1',
   passing_percentage: '50',
   allow_result_viewing: '0',
   enable_timer: '0',
   start_date: '',
   end_date: '',
   assigned_students: '',
   term: ''
};

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let tempAssOptions = null;
let pdfFile = '';

export function FormativeEditPDF(props) {
   // const[answerSheetData, setAnswerSheetData] = useState([]); //useState(questionData);
   // const[drawerVisible, setDrawerVisible] = useState(false);
   // const[questionSheetPDF, setQuestionSheetPDF] = useState(''); // useState("https://media.campuscloudph.com/ssapamp/uploads/lms_assessment/lms_assessment_online_163922682965702119/assessment_online_163922685061451080.pdf");
   const [showAssessmentSheet, setShowAssessmentSheet] = useState(true);
   const [showAssignment, setShowAssignment] = useState(false);
   const [assOptions, setAssOptions] = useState(assOptionsTemplate);
   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: ""
   });

   const [hasAnswers, setHasAnswers] = useState(false);

   const [percentage, setPercentage] = React.useState(0);
   const [isUploading, setIsUploading] = React.useState(false);

   const location = useLocation();
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      pdfFile = '';

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');
      else {
         const assessmentID = location.state.assessmentID;
         // getAssessmentInfo(assessmentID);
         checkIfHasAnswers(assessmentID);
      }
   }, []);

   const checkIfHasAnswers = (assessment_id) => {
      var url = `${process.env.REACT_APP_API_ASSESSMENT}/hasanswers?assessment_id=${assessment_id}&at=formative`;
      axios.get(url).then((response) => {
         // if (response.data) {
         var data = response.data;

         setHasAnswers(data);
         getAssessmentInfo(assessment_id);
         // }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAssessmentInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${id}&at=formative`).then((response) => {
         if (response.data) {
            const data = response.data;
            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: JSON.parse(data.question_sheet),
               Level: data.level,
               Subject: data.subject
            });

            // setQuestionSheetPDF(data.file_url);
            // setAnswerSheetData(JSON.parse(data.question_sheet));
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   // const showDrawer = () => {
   //    setDrawerVisible(true);
   // };

   // const onClose = () => {
   //    setDrawerVisible(false);
   // };

   const handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(assInfo.Question_sheet);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // setAnswerSheetData(items);
      setAssInfo({
         Id: assInfo.Id,
         Title: assInfo.Title,
         Term: assInfo.Term,
         File_url: assInfo.File_url,
         Question_type: assInfo.Question_type,
         Created_by: assInfo.Created_by,
         Date_created: assInfo.Date_created,
         Question_sheet: items,
         Level: assInfo.Level,
         Subject: assInfo.Subject
      });

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleCardRequest = (method, type, data, idx) => {
      var asd = CardRequestHelper(method, type, data, idx, assInfo.Question_sheet);
      // setAnswerSheetData(asd);
      setAssInfo({
         Id: assInfo.Id,
         Title: assInfo.Title,
         Term: assInfo.Term,
         File_url: assInfo.File_url,
         Question_type: assInfo.Question_type,
         Created_by: assInfo.Created_by,
         Date_created: assInfo.Date_created,
         Question_sheet: asd,
         Level: assInfo.Level,
         Subject: assInfo.Subject
      });

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      //-- Hide drawer on button click
      // setDrawerVisible(false);
   }

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
         //console.log(`${percentage} %`)
      }
   }

   const handleUpload = (file) => {
      const formData = new FormData();
      formData.append('file', file);

      setPercentage(0);
      setIsUploading(true);

      const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_QUESTIONAIRE_PATH}`;
      axios.post(url, formData, upload_option).then(response => {

         if (response.data) {
            const data = response.data;
            setAssInfo({
               Id: assInfo.Id,
               Title: assInfo.Title,
               Term: assInfo.Term,
               File_url: data,
               Question_type: assInfo.Question_type,
               Created_by: assInfo.Created_by,
               Date_created: assInfo.Date_created,
               Question_sheet: assInfo.Question_sheet,
               Level: assInfo.Level,
               Subject: assInfo.Subject
            });

            setIsUploading(false);
         }
      }, (error) => {
         console.log(error.message);
         setIsUploading(false);
      });
   }

   const UploadPdf = () => {
      const props = {
         beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
               message.error(`${file.name} is not a pdf file`);
            }
            // return isPDF || Upload.LIST_IGNORE;
            handleUpload(file);
         },
         onChange: info => {
            console.log(info.fileList);
            // message.success(`Upload successful`);
         },
      };
      return (
         <Upload {...props} showUploadList={false} accept=".pdf">
            <Tooltip placement="left" title='Upload questionaire in PDF format'>
               <Button className='button-shadow' type="primary" size="middle" shape='round'>
                  <Text ellipsis style={{ color: "#fff" }}><AntDIcons.CloudUploadOutlined />&nbsp;Upload Questionaire</Text>
               </Button>
            </Tooltip>
         </Upload>
      );
   };

   const handleSave = (assinfo) => {
      // message.success(JSON.stringify(assOptions));
      // message.success(JSON.stringify(answerSheetData));
      const key = "updatable";

      message.loading({ content: "Updating assessment...", key });

      var ass_info = {
         Id: assInfo.Id,
         Title: assinfo.title,
         Term: assinfo.term,
         File_url: assInfo.File_url,
         Question_type: assInfo.Question_type,
         Created_by: assInfo.Created_by,
         Question_sheet: JSON.stringify(assInfo.Question_sheet),
         Level: assinfo.level,
         Subject: assinfo.subject
      }

      const url = `${process.env.REACT_APP_API_ASSESSMENT}/update?at=formative`
      axios.post(url, ass_info).then(response => {
         // console.log(response);
         if (response.data) {
            const data = response.data;
            message.success({ content: 'Formative Assessment updated.', key });
            Modal.success({
               title: 'Formative Assessment',
               content: 'Assessment was updated.',
               centered: true,
            });
         }
      }, (error) => {
         console.log(error.message);
         message.error({ content: 'Formative Assessment update failed.', key });
         Modal.error({
            title: 'Formative Assessment',
            content: 'Assessment update failed.',
            centered: true
         });
      });
   }

   const showHideAssignment = () => {
      setShowAssignment(!showAssignment);
      setShowAssessmentSheet(!showAssessmentSheet);
   }

   const handleSetOptions = (data) => {
      // message.info(JSON.stringify(data));
      tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);
      // setShowAssignment(!showAssignment);
      // setShowAssessmentSheet(!showAssessmentSheet);
   }

   const handleBack = (data) => {
      // message.info(JSON.stringify(data));
      setShowAssignment(!showAssignment);
      setShowAssessmentSheet(!showAssessmentSheet);
   }

   function sPDFFile() {
      pdfFile = assInfo.File_url;
      return true;
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px`, height: `calc(100vh - 2rem)` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/formativeassessment', { state: { activetab: "1" } })}>Self-check</a></li>
                           <li><span>/</span></li>
                           <li className="active">Edit</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              {/* <AntDIcons.HomeOutlined /> */}
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <Link to="/formativeassessment" state={{ activetab: "1" }}>Self-check</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Edit</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>

                     {assInfo.File_url
                        ?
                        <div className="pdf-all-page-container" style={{ height: "calc(100vh - 65px);" }}>
                           {/* <SimpleBarReact>
                        <StickyBox style={{zIndex: 1000}}>
                           <div style={{textAlign: "right"}}>{UploadPdf()}</div>
                        </StickyBox>
                        <AllPagesPDFViewer pdf={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} /> 
                     </SimpleBarReact> */}

                           <StickyBox style={{ zIndex: 1000 }}>
                              <div style={{ textAlign: "right" }}>{UploadPdf()}</div>
                           </StickyBox>
                           <>
                              <div style={{ width: "100%", height: "calc(100vh - 90px)" }}>
                                 <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                    <Viewer
                                       pageLayout={{
                                          transformSize: ({ size }) => ({
                                             height: size.height + 30,
                                             width: size.width + 30,
                                          }),
                                       }}
                                       fileUrl={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}
                                       defaultScale={SpecialZoomLevel.PageWidth}
                                       initialPage={0}
                                    />
                                 </Worker>
                              </div>
                              {/* <BrowserView>
                                 <object data={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} width="100%" style={{ height: 'calc(100vh - 90px)' }}>
                                    <p>Brainee LMS PDF - View<a href={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}> PDF!</a></p>
                                 </object>
                              </BrowserView>
                              <MobileView>
                                 <div style={{ width : "100%", height:"calc(100vh - 90px)" }}>
                                    <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                       <Viewer 
                                          pageLayout={{transformSize: ({ size }) => ({
                                             height: size.height + 30,
                                             width: size.width + 30,
                                          }),}}  
                                          fileUrl={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}  
                                          defaultScale={SpecialZoomLevel.PageWidth}   
                                          initialPage={0} 
                                       />
                                    </Worker>
                                 </div>
                              </MobileView> */}
                           </>
                           {/* <object data={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} width="100%" style={{ height: 'calc(100vh - 95px)' }}>
                              <p>Brainee LMS PDF - <a href={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}> Show PDF</a></p>
                           </object> */}
                           {/* <RenderPDFViewer url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 90px)"} load={pdfFile !== assInfo.File_url ? sPDFFile() : false} /> */}
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           {/* <Empty description='No items added.'/> */}
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </div>
               </div>

               <div>
                  <div className="notice-item">

                     {showAssignment &&
                        <div className='assign-assessment-container'>
                           <AssessmentAssignTool
                              mode={"normal"}
                              options={assOptions}
                              parentCallback={handleSetOptions}
                              backCallback={handleBack} />
                        </div>
                     }

                     {showAssessmentSheet && assInfo.Question_sheet
                        ?
                        <>
                           <div className='answer-sheet-content'>
                              <SimpleBarReact>
                                 <StickyBox style={{ zIndex: 1000 }}>
                                    <AssessmentTools
                                       key={"editPDF"}
                                       data={assInfo}
                                       cardRequestCallback={handleCardRequest}
                                       showHideAssCallback={showHideAssignment}
                                       saveCallback={handleSave}
                                       showToggle={true}
                                       mode={"edit"}
                                       type={'pdf'}
                                       assessment_type={'formative'}
                                    />
                                 </StickyBox>

                                 {hasAnswers
                                    ?
                                    <>
                                       <ul className="questions" style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                                          {assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, image_url, question }, idx) => {

                                             if (type === "section") {
                                                qcardIdx = 1;
                                                idxCol.push(sectionIdx);
                                                sectionIdx++;
                                             } else {
                                                idxCol.push(qcardIdx);
                                                qcardIdx++;
                                             }

                                             return (
                                                <li>
                                                   <BraineeQuestionCard
                                                      key={id}
                                                      index={idxCol[idx]}
                                                      id={idx}
                                                      type={type}
                                                      points={points}
                                                      correct={correct}
                                                      option_labels={option_labels}
                                                      image_url={image_url}
                                                      question={question}
                                                      question_type={'1'}
                                                      mode={'edit'}
                                                      parentCallback={handleCardRequest}
                                                      assessment_type={'formative'}
                                                   />
                                                </li>
                                             );
                                          })}
                                       </ul>
                                    </>
                                    :
                                    <>
                                       <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
                                          <Droppable droppableId="questions">
                                             {(provided) => (
                                                <ul className="questions" {...provided.droppableProps} ref={provided.innerRef} style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                                                   {assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, image_url, question }, idx) => {

                                                      if (type === "section") {
                                                         qcardIdx = 1;
                                                         idxCol.push(sectionIdx);
                                                         sectionIdx++;
                                                      } else {
                                                         idxCol.push(qcardIdx);
                                                         qcardIdx++;
                                                      }

                                                      return (
                                                         <Draggable key={id} draggableId={id} index={idx}>
                                                            {(provided) => (
                                                               <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                  <BraineeQuestionCard
                                                                     key={id}
                                                                     index={idxCol[idx]}
                                                                     id={idx}
                                                                     type={type}
                                                                     points={points}
                                                                     correct={correct}
                                                                     option_labels={option_labels}
                                                                     image_url={image_url}
                                                                     question={question}
                                                                     question_type={'1'}
                                                                     mode={'edit'}
                                                                     parentCallback={handleCardRequest}
                                                                     assessment_type={'formative'}
                                                                  />
                                                               </li>
                                                            )}
                                                         </Draggable>
                                                      );
                                                   })}
                                                   {provided.placeholder}
                                                </ul>
                                             )}
                                          </Droppable>
                                       </DragDropContext>
                                    </>
                                 }


                              </SimpleBarReact>
                           </div>
                        </>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </div>
               </div>
            </Split>
         </div>

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>
      </>
   )
}
