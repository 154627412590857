import React, { useState, useEffect } from 'react';
import * as AntDIcons from '@ant-design/icons';
import axios from 'axios';
import uuid from 'react-uuid';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Button, message, Upload, Modal, Typography, Breadcrumb, Progress, Image } from 'antd';

const { Text } = Typography

export function Create(props) {
   const navigate = useNavigate();

   const schedType = props.type;
   const level = props.level;
   const section = props.section;
   const btnLabel = props.button_label;

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [percentage, setPercentage] = React.useState(0);
   const [isUploading, setIsUploading] = React.useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

   }, []);

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
         //console.log(`${percentage} %`)
      }
   }

   const handleUpload = (file) => {
      // const key = 'updatable';
      // message.loading({ content: 'Uploading ' + btnLabel + '...', key, duration: 0 });

      const formData = new FormData();
      formData.append('file', file);

      setPercentage(0);
      setIsUploading(true);

      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

      // const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_SCHEDULES_PATH}`;
      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/uploadschedule?s3path=${process.env.REACT_APP_S3_SCHEDULES_PATH}`;
      axios.post(url, formData, upload_option).then(response => {
         console.log(response);
         if (response.data) {
            let file_url = response.data;

            var sched_data = {
               schedule_id: 'schedule_' + o2o_mode + '_' + uuid(),
               schedule_type: schedType,
               file_url,
               file_name: file.name,
               uploaded_by: user_id,
               level,
               section,
            }

            // let strData = JSON.stringify(sched_data);
            //-- Add new schedule
            const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/addschedule`;
            axios.post(url, sched_data).then(response => {
               // console.log(response);
               if (response.data) {
                  const respData = response.data;
                  Modal.success({
                     title: 'Schedule',
                     content: btnLabel + ' uploaded successfully.',
                     centered: true,
                     // onOk: navigate(-1),
                  });

                  setIsUploading(false);

                  props.onFinishCreateCallback(sched_data.file_url);
               }
            }, (error) => {
               console.log(error.message);
               setIsUploading(false);
               Modal.error({
                  title: 'Schedule',
                  content: btnLabel + ' upload failed.',
                  centered: true
               });
            });

         }
         // setTimeout(() => {
         //    message.success({ content: 'File upload complete!', key, duration: 2 });
         // }, 1000);
      }, (error) => {
         console.log(error.message);
         setIsUploading(false);
         // setTimeout(() => {
         //    message.error({ content: 'File upload failed!', key, duration: 2 });
         // }, 1000);
      });
   }

   const UploadPdf = () => {
      const props = {
         beforeUpload: file => {
            const isPNG = file.type === 'image/png';
            const isJPG = file.type === 'image/jpeg';
            const isBMP = file.type === 'image/bmp';
            const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.ms-word';
            const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
            const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const isPPT = file.type === 'application/vnd.ms-powerpoint';
            const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            const isPPSX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
            const isPDF = file.type === 'application/pdf';

            const fileValid = isPNG || isJPG || isBMP || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX || isPDF || isPPSX;

            if (fileValid) {
               const isLt100M = file.size / 1024 / 1024 <= 100;

               if (isLt100M) {
                  handleUpload(file);
               } else {
                  Modal.error({
                     title: 'Schedule',
                     content: 'File should be less than 100 MB',
                     centered: true
                  });
               }
            } else {
               Modal.error({
                  title: 'Schedule',
                  content: 'You are not allowed to upload that file type',
                  centered: true
               });
            }
         },
      };
      return (
         level !== null && section !== null
            ?
            <Upload {...props} showUploadList={false}
               accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,.png,.jpg,.pdf,.ppsx"
            >
               {/* <Tooltip placement="left" title='Upload Class Schedule'> */}
               <Button className='button-shadow' type="primary" size="large" shape='round'>
                  <Text ellipsis style={{ color: "#fff" }}><AntDIcons.CloudUploadOutlined />&nbsp;Upload {btnLabel}</Text>
               </Button>
               {/* </Tooltip> */}
            </Upload>
            :
            <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleValidate()}>
               <Text ellipsis style={{ color: "#fff" }}><AntDIcons.CloudUploadOutlined />&nbsp;Upload {btnLabel}</Text>
            </Button>
      );
   };

   const handleValidate = () => {
      if (level === null || section === null) {
         Modal.error({
            title: 'Schedule',
            content: 'Please select Level and Section.',
            centered: true
         });
      }
   }

   return (
      <>
         <div style={{ display: 'flex', justifyContent: 'left' }}>
            {UploadPdf()}
         </div>

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>
      </>
   );
}