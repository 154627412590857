/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, Spin, Empty, Typography, Table, Divider, Breadcrumb } from 'antd';
// import * as AntDIcons from '@ant-design/icons';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import SimpleBarReact from "simplebar-react";
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

const { Text, Title } = Typography;

var data_main = [];

var data_level1 = [];
var data_level2 = [];
var data_level3 = [];

export function MasteryReportType3(props) {
   const navigate = useNavigate();
   // const location = useLocation();

   const search = window.location.search;
   const query = new URLSearchParams(search);

   const term = query.get('term');
   const level = query.get('level');
   const subject = query.get('subject');
   const assigned_by = query.get('assigned_by');

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [data, setData] = useState({
      loading: false,
      hasData: false,
   })

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      getMasteryReportData(term, level, subject, assigned_by);
   }, []);

   const report_columns = [
      {
         title: 'Code',
         key: 'competency_code',
         dataIndex: 'competency_code',
         width: '10%',
      },
      {
         title: 'Competency',
         key: 'competency',
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Text>{object.competency}</Text>
         ),
         width: '54%',
      },
      {
         title: 'Number of Students',
         children: [
            {
               title: 'Beginner',
               key: 'student_count_beginner',
               dataIndex: 'student_count_beginner',
               width: '12%',
               align: 'center',
            },
            {
               title: 'Proficient',
               key: 'student_count_proficient',
               dataIndex: 'student_count_proficient',
               width: '12%',
               align: 'center',
            },
            {
               title: 'Competent',
               key: 'student_count_competent',
               dataIndex: 'student_count_competent',
               width: '12%',
               align: 'center',
            },
         ]
      },

   ];

   const getMasteryReportData = (term, level, subject, assigned_by) => {
      setData({
         loading: true,
         hasData: false,
      });

      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/getmasteryreportdata3?term=${term}&level=${level}&subject=${subject}&assigned_by=${assigned_by}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            data_main = response.data;

            data_level1 = data_main.filter(data => data.assessment_type === 'formative_1');
            data_level2 = data_main.filter(data => data.assessment_type === 'formative_2');
            data_level3 = data_main.filter(data => data.assessment_type === 'formative_3');

            setData({
               loading: false,
               hasData: data_main.length > 0 ? true : false,
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   function GenerateReport() {
      var retVal = (
         <>
            <div style={{ width: '100%', textAlign: 'center' }}>
               <h5><b>Number of Students by Proficiency Level</b></h5>
            </div>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Teacher: <b>{data_main[0].teacher}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Quarter: <b>{term === '0' ? "All" : data_main[0].term}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Subject: <b>{subject === '0' ? "All" : data_main[0].subject}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px", paddingBottom: "20px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Grade: <b>{level === '0' ? "All" : data_main[0].grade_level}</b></span>
               </Col>
            </Row>
            {/* <Row style={{ rowGap: "0px", paddingBottom: "30px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Competency: <b>{data_main[0].competency_code.toUpperCase() + " " + data_main[0].competency}</b></span>
               </Col>
            </Row> */}
            <Divider orientation="center">
               <Title level={5} style={{ textAlign: "center", color: "#252525" }}>Level 1</Title>
            </Divider>
            <Row style={{ paddingBottom: '2rem' }}>
               <Col span={24}>
                  <Table
                     size='small'
                     columns={report_columns}
                     dataSource={data_level1}
                     pagination={false}
                     rowKey={'uid'}
                     // style={{ minWidth: "1000px" }}
                     scroll={{
                        x: '1000px'
                     }}
                  />
               </Col>
            </Row>
            <Divider orientation="center">
               <Title level={5} style={{ textAlign: "center", color: "#252525" }}>Level 2</Title>
            </Divider>
            <Row style={{ paddingBottom: '2rem' }}>
               <Col span={24}>
                  <Table
                     size='small'
                     columns={report_columns}
                     dataSource={data_level2}
                     pagination={false}
                     rowKey={'uid'}
                     // style={{ minWidth: "1000px" }}
                     scroll={{
                        x: '1000px'
                     }}
                  />
               </Col>
            </Row>
            <Divider orientation="center">
               <Title level={5} style={{ textAlign: "center", color: "#252525" }}>Level 3</Title>
            </Divider>
            <Row style={{ paddingBottom: '2rem' }}>
               <Col span={24}>
                  <Table
                     size='small'
                     columns={report_columns}
                     dataSource={data_level3}
                     pagination={false}
                     rowKey={'uid'}
                     // style={{ minWidth: "1000px" }}
                     scroll={{
                        x: '1000px'
                     }}
                  />
               </Col>
            </Row>

         </>
      );

      return retVal;
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/masteryassessmentreport')}>Mastery Assessment Report</a></li>
                     <li><span>/</span></li>
                     <li className="active">Report Type 3</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/masteryassessmentreport')}>Mastery Assessment Report</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Report Type 3</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               {/* <SimpleBarReact style={{ height: "calc(100vh - 55px)" }}> */}
               {data.loading === false
                  ?
                  <>
                     {
                        data.hasData
                           ?
                           // <div className='section_to_print' style={{ width: "calc(100% - 6px)" }}>
                           <div className='section_to_print'>
                              {GenerateReport()}
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              <Empty description="No data available for report" image="../images/brainee_mascot.png" />
                           </div>
                     }
                  </>
                  :
                  <>
                     <div className='mastery_assessment_list'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </>
               }
               {/* </SimpleBarReact> */}
            </div>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}