import React, { useState } from 'react'
import { Space, Button, Card, InputNumber, Avatar, Popconfirm, Typography, Tooltip, Row, Col, message, Badge } from 'antd';
import { CloseCircleTwoTone, AlertTwoTone } from '@ant-design/icons';

// import { Editor } from 'react-draft-wysiwyg';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import EssayItem from './question-items/Essay';
import MultipleAnswerItem from './question-items/MultipleAnswer';
import MultipleChoiceItem from './question-items/MultipleChoice';
import ShortAnswerItem from './question-items/ShortAnswer';
import MultipleChoiceImageItem from './question-items/MultipleChoiceImage';
import MultipleAnswerImageItem from './question-items/MultipleAnswerImage';

// import { Editor } from '@tinymce/tinymce-react';
import Wysiwyg from '../wysiwyg/wysiwyg';

const { Text } = Typography;
const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
   "A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1", "J1", "K1", "L1", "M1", "N1", "O1", "P1", "Q1", "R1", "S1", "T1", "U1", "V1", "W1", "X1", "Y1", "Z1"];

export default function QuestionCard(props) {
   const [optionValues, setOptionValues] = useState(props.option_labels !== undefined && props.option_labels !== '' ? props.option_labels : []);

   //--correctAnswers (this will be used to hold data from create/edit/answer)
   const [correctAnswers, setCorrectAnswers] = useState(props.correct !== undefined && props.correct !== '' ? props.correct : []);
   const [points, setPoints] = useState(props.points !== undefined && props.points !== '' ? props.points : '1');
   const [question, setQuestion] = useState(props.question !== undefined && props.question !== '' ? props.question : '');
   const [editorContent, setEditorContent] = useState(props.question !== undefined && props.question !== '' ? props.question : '');
   const [imageURL, setImageURL] = useState(props.image_url !== undefined && props.image_url !== '' ? props.image_url : []);
   const [studentAnswers, setStudenttAnswers] = useState(props.answers !== undefined && props.answers !== '' ? props.answers : []);
   const cardBackgroundColor = props.matched !== undefined ? (props.matched === true ? "#DFFFDC" : "#FFDCDC") : "#E2EBFF";
   const enable_consider = props.enable_consider !== undefined ? props.enable_consider : true;

   const handleRequest = (method, value, idx) => {
      var ov = [...optionValues];
      var ca = [...correctAnswers];
      var img = [...imageURL];
      var pts = points;
      var qstn = question;

      switch (method) {
         case "remove":
            ov.splice(idx, 1);
            ca.splice(idx, 1);
            if (img.length > 0) img.splice(idx, 1)
            break;
         case "update":
            ov[idx] = value;
            ca[idx] = '0';
            break;
         case "add":
            if (ov.length <= alphabet.length - 1) {
               ov.push(alphabet[ov.length]);
               ca.push('0');
               if (img.length > 0) img.push('');
            } else {
               message.info('No more options available.')
            }

            break;
         case "update-check":
            ca[idx] = value === true ? '1' : '0';
            break;
         case "update-check-radio":
            for (var i = 0; i < ca.length; i++) {
               if (i === idx)
                  ca[i] = '1';
               else
                  ca[i] = '0';
            };
            break;
         case "update-input":
            if (props.mode === "answer") {
               ca = [];
               ca.push(value);
            } else {
               ca[idx] = value;
            }

            console.log(value);
            break;
         case "update-points":
            pts = value === null ? 1 : value;
            break;
         case "update-question":
            setEditorContent(value);
            qstn = value;

            console.log(value);
            break;
         case "update-image-url":
            img[idx] = value;
            break;
         case "add-correct":
            ca.push(value)
            break;
         default:
            break;
      }

      if (props.mode === "answer") {
         props.answerCallback(ca, props.id); //-- Update student answers
      } else {
         if (img.length > 0) {
            var data = {
               correct: ca,
               option_labels: ov,
               points: pts,
               question: qstn,
               image_url: img
            }
         } else {
            var data = {
               correct: ca,
               option_labels: ov,
               points: pts,
               question: qstn,
            }
         }

         props.parentCallback("update", "", data, props.id); //-- Update parent state answer keys data
      }

      setOptionValues(ov);
      setCorrectAnswers(ca);
      setPoints(pts);
      setQuestion(qstn);
      if (img.length > 0) setImageURL(img);
   }

   const handleCardRequest = (method, idx) => {
      props.parentCallback(method, "", "", idx);
   }

   const handleConsiderAnswer = (questionKeyId, answer_to_consider) => {
      props.considerAnswerCallback(questionKeyId, answer_to_consider);
   }

   const generateQuestionOptions = () => {
      // if (props.type !== "short_answer" && props.type !== "long_answer") {
      if (props.type !== "long_answer") {
         if (props.type === "short_answer") {
            if (props.mode === "answer" || props.mode.includes("view")) {
               return <ShortAnswerItem
                  key={props.id}
                  id={props.id}
                  correct={props.mode.includes("view") ? props.answers : props.correct}
                  mode={props.mode}
                  parentCallback={handleRequest}
               />
            } else {
               return correctAnswers.map((el, i) => (
                  <ShortAnswerItem
                     key={props.id + "_" + i}
                     id={i}
                     correct={correctAnswers[i]}
                     mode={props.mode}
                     parentCallback={handleRequest}
                  />
               ))
            }
         } else if (props.type === "multiple_answer") {
            return optionValues.map((el, i) => (
               <MultipleAnswerItem
                  key={props.id + "_" + i}
                  id={i}
                  value={el}
                  correct={props.mode.includes("view") ? studentAnswers[i] : correctAnswers[i]}
                  mode={props.mode}
                  parentCallback={handleRequest}
               />
            ))
         } else if (props.type === "multiple_answer_image") {
            return (<>
               <Row gutter={12}>
                  {optionValues.map((el, i) => (
                     <Col key={props.id + "_" + i} className="gutter-row" span={12}>
                        <MultipleAnswerImageItem
                           key={props.id + "_" + i}
                           id={i}
                           value={el}
                           correct={props.mode.includes("view") ? studentAnswers[i] : correctAnswers[i]}
                           mode={props.mode}
                           parentCallback={handleRequest}
                           image_url={props.image_url[i]}
                        />
                     </Col>
                  ))}
               </Row>
            </>)
         } else if (props.type === "multiple_choice") {
            return optionValues.map((el, i) => (
               <MultipleChoiceItem
                  key={props.id + "_" + i}
                  cardid={props.id}
                  id={i}
                  value={el}
                  correct={props.mode.includes("view") ? studentAnswers[i] : correctAnswers[i]}
                  mode={props.mode}
                  parentCallback={handleRequest}
               />
            ))
         } else if (props.type === "multiple_choice_image") {
            return (<>
               <Row gutter={12}>
                  {optionValues.map((el, i) => (
                     <Col key={props.id + "_" + i} className="gutter-row" span={12}>
                        <MultipleChoiceImageItem
                           key={props.id + "_" + i}
                           cardid={props.id} id={i}
                           value={el}
                           correct={props.mode.includes("view") ? studentAnswers[i] : correctAnswers[i]}
                           mode={props.mode}
                           parentCallback={handleRequest}
                           image_url={props.image_url[i]}
                        />
                     </Col>
                  ))}
               </Row>
            </>)
         }

      } else {
         // if (props.type === "short_answer") {
         //    return <ShortAnswerItem 
         //       key={props.id} 
         //       id={props.id} 
         //       correct={props.correct} 
         //       mode={props.mode} 
         //       parentCallback={handleRequest} 
         //    />
         // } else 
         if (props.type === "long_answer") {
            return <EssayItem
               key={props.id}
               id={props.id}
               mode={props.mode}
               value={props.mode.includes("view") && props.mode !== "preview" ? props.answers : (props.mode === 'answer' ? props.correct[0] : '')}
               // value={props.mode.includes("view") ? props.answers : props.correct[0]}
               // initialValue={props.mode.includes("view") ? props.answers : props.correct[0]}
               parentCallback={handleRequest}
            />
         }
      }
   }

   function getCorrectAnswer(type, correct_answer_seq, option_labels) {
      var retVal = "";

      if (type !== "long_answer") {
         if (type === "short_answer")
            retVal = correct_answer_seq.join(' or ');
         else if (type.includes("multiple_answer")) {
            for (var i = 0; i < correct_answer_seq.length; i++) {
               if (correct_answer_seq[i] === "1") {
                  if (retVal !== "") retVal += " and ";
                  retVal += option_labels[i];
               }
            }
         } else {
            for (var i = 0; i < correct_answer_seq.length; i++) {
               if (correct_answer_seq[i] === "1")
                  retVal += option_labels[i];
            }
         }
      }

      return retVal === '' ? <span><AlertTwoTone twoToneColor={'red'} /><Text style={{ color: 'red', fontStyle: 'italic' }}>Correct answer was not set.</Text></span> : retVal;
   }

   const generateQuestionCard = () => {
      if (props.type === "section") {
         return (
            <Card
               key={props.id}
               title={<Text type="secondary" style={{ fontSize: 14, fontWeight: "bold" }}>SECTION {props.index}</Text>}
               style={{ width: "100%", backgroundColor: "#E2EBFF", marginRight: "15px" }}
               // hoverable={true}
               extra={props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view")
                  ?
                  <Popconfirm
                     title="Remove this section?"
                     onConfirm={() => handleCardRequest("remove", props.id)}
                     okText="Yes"
                     cancelText="No">
                     <CloseCircleTwoTone twoToneColor="#aaa" style={{ cursor: "pointer", fontSize: "24px" }} />
                  </Popconfirm>
                  :
                  <></>
               }
            >
               {/* {
                  props.mode === 'answer' || props.mode.includes("assign") || props.mode.includes("view")
                     ?
                     <div
                        dangerouslySetInnerHTML={{ __html: editorContent }}
                        style={{ textOverflow: 'ellipsis', overflow: 'hidden', backgroundColor: '#FFF', height: '300px', padding: '10px' }}
                     />
                     :
                     <Editor
                        key={'brainee' + props.id}
                        init={{
                           height: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 220 : 120,
                           menubar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? true : false,
                           statusbar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? true : false,
                           plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code',
                              'insertdatetime media table paste code help wordcount emoticons'
                           ],
                           toolbar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | emoticons | help' : false,
                           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}

                        disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                        id={'brainee' + props.id}
                        apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                        // onBlur={ (e) => handleRequest("update-input", e, props.id) }
                        onFocusOut={(e) => handleRequest("update-input", e.target.innerHTML, 0)}
                        initialValue={props.correct[0]}
                     // onEditorChange={ (e) => handleRequest("update-input", e, props.id) }
                     // value={ props.correct[0] }
                     // onFocusOut={ (e) => handleRequest("update-input", e, props.id) }
                     />
               } */}
                     <Wysiwyg 
                           id={props.id} 
                           changeContentCallback={(e) => handleRequest("update-question", e, props.id)} 
                           content={editorContent} 
                           height={props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 220 : 120}
                           contentHeight={235}
                           disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                        />
                  {/* <Editor
                        key={'brainee' + props.id}
                        init={{
                           height: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 220 : 120,
                           menubar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? true : false,
                           statusbar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? true : false,
                           plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code',
                              'insertdatetime media table paste code help wordcount emoticons'
                           ],
                           toolbar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | emoticons | help' : false,
                           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}

                        disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                        id={'brainee' + props.id}
                        apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                        // onBlur={ (e) => handleRequest("update-input", e, props.id) }
                        onFocusOut={(e) => handleRequest("update-input", e.target.innerHTML, 0)}
                        initialValue={props.correct[0]}
                     // onEditorChange={ (e) => handleRequest("update-input", e, props.id) }
                     // value={ props.correct[0] }
                     // onFocusOut={ (e) => handleRequest("update-input", e, props.id) }
                     /> */}

            </Card>
         )
      } else {
         return (
            <Card
               key={props.id}
               title={
                  <span>
                     <Space direction='horizontal' size="small">
                        {props.mode === "create" || props.mode === "edit"
                           ?
                           <>
                              <Tooltip placement="right" title='Click to create a copy'>
                                 <Avatar
                                    style={{ backgroundColor: '#4abec7', verticalAlign: 'middle', cursor: "pointer" }}
                                    size="middle"
                                    gap={2}
                                    onClick={() => handleCardRequest("duplicate", props.id)}
                                 >
                                    {props.index}
                                 </Avatar>
                              </Tooltip>
                              {
                                 props.type.includes("multiple")
                                    ?
                                    props.type.includes("choice")
                                       ?
                                       <Text style={{ color: "#a3a3ac", fontWeight: "normal" }}>Multiple Choice</Text>
                                       :
                                       <Text style={{ color: "#a3a3ac", fontWeight: "normal" }}>Multiple Answer</Text>
                                    :
                                    props.type.includes("short_answer")
                                       ?
                                       <Text style={{ color: "#a3a3ac", fontWeight: "normal" }}>Short Answer</Text>
                                       :
                                       <Text style={{ color: "#a3a3ac", fontWeight: "normal" }}>Open-Ended/Essay</Text>
                              }
                              <InputNumber
                                 addonBefore="Points"
                                 min={1}
                                 max={100}
                                 style={{ width: "130px" }}
                                 value={props.points}
                                 onChange={(val) => handleRequest("update-points", val, props.id)}
                                 disabled={(props.mode === "answer" || props.mode.includes("assign")) && props.mode.includes("view") ? true : false}
                              />
                           </>
                           :
                           <>
                              <Avatar
                                 style={{ backgroundColor: '#4abec7', verticalAlign: 'middle' }}
                                 size="middle"
                                 gap={2}
                                 onClick={() => handleCardRequest("duplicate", props.id)}
                              >
                                 {props.index}
                              </Avatar>
                              {
                                 props.type.includes("multiple")
                                    ?
                                    props.type.includes("choice")
                                       ?
                                       <Text style={{ color: "#a3a3ac", fontWeight: "normal" }}>Multiple Choice</Text>
                                       :
                                       <Text style={{ color: "#a3a3ac", fontWeight: "normal" }}>Multiple Answer</Text>
                                    :
                                    props.type.includes("short_answer")
                                       ?
                                       <Text style={{ color: "#a3a3ac", fontWeight: "normal" }}>Short Answer</Text>
                                       :
                                       <Text style={{ color: "#a3a3ac", fontWeight: "normal" }}>Open-Ended/Essay</Text>
                              }
                           </>
                        }
                     </Space>
                  </span>
               }
               style={{ width: "100%", backgroundColor: props.type === "long_answer" ? "#E2EBFF" : cardBackgroundColor, marginRight: "15px" }}
               // hoverable={true}
               extra={props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view")
                  ?
                  <Popconfirm
                     title="Remove this answer key?"
                     onConfirm={() => handleCardRequest("remove", props.id)}
                     okText="Yes"
                     cancelText="No"
                  >

                     <CloseCircleTwoTone twoToneColor="#aaa" style={{ cursor: "pointer", fontSize: "24px" }} />
                  </Popconfirm>
                  :
                  props.mode === "teacher-view" && props.type === "short_answer" && props.matched === false && enable_consider
                     ?
                     <>
                        <Popconfirm
                           title="Are you sure you want to consider this answer?"
                           onConfirm={() => handleConsiderAnswer(props.question_key_id, props.answers)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <Button
                              size='small'
                              className='button-shadow'
                              type='primary'
                              shape='round'
                           //onClick={() => handleConsiderAnswer(props.question_key_id, props.answers)}
                           >
                              Consider Answer
                           </Button>
                        </Popconfirm>

                     </>
                     :
                     <></>
               }
            >

               <Space direction='vertical' size="small" style={{ width: "100%" }}>
                  {
                     props.question_type === "2" //Manual encode
                     &&
                     <>
                        <Wysiwyg 
                              id={props.id} 
                              changeContentCallback={(e) => handleRequest("update-question", e, props.id)} 
                              content={editorContent} 
                              height={300}
                              contentHeight={235}
                              disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                           />
                        {/* <Editor
                           key={'brainee' + props.id}
                           init={{
                              height: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 250 : 150,
                              menubar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? true : false,
                              statusbar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? true : false,
                              plugins: [
                                 'advlist autolink lists link image charmap print preview anchor',
                                 'searchreplace visualblocks code',
                                 'insertdatetime media table paste code help wordcount emoticons'
                              ],
                              toolbar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 'undo redo | formatselect | ' +
                                 'bold italic backcolor | alignleft aligncenter ' +
                                 'alignright alignjustify | bullist numlist outdent indent | ' +
                                 'removeformat | emoticons | help' : false,
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                           }}

                           disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                           id={'brainee' + props.id}
                           apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                           // onEditorChange={(e) => handleRequest("update-question", e, props.id)}
                           // onBlur={(e) => handleRequest("update-question", e.target, props.id)}
                           onFocusOut={(e) => handleRequest("update-question", e.target.innerHTML, props.id)}
                           initialValue={editorContent}
                        // value={editorContent}
                        /> */}
                        {/* props.mode === 'answer' || props.mode.includes("assign") || props.mode.includes("view")
                        ?
                        <div
                           dangerouslySetInnerHTML={{ __html: editorContent }}
                           style={{ textOverflow: 'ellipsis', overflow: 'hidden', backgroundColor: '#FFF', height: '300px', padding: '10px' }}
                        />
                        :
                        <Editor
                           key={'brainee' + props.id}
                           init={{
                              // height: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 300 : 200,
                              height: 300,
                              menubar: false,
                              statusbar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? true : false,
                              plugins: [
                                 'advlist autolink lists link image charmap print preview anchor',
                                 'searchreplace visualblocks code',
                                 'insertdatetime media table paste code help wordcount emoticons'
                              ],
                              toolbar: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 'undo redo | formatselect | ' +
                                 'bold italic backcolor | alignleft aligncenter ' +
                                 'alignright alignjustify | bullist numlist outdent indent | ' +
                                 'removeformat | emoticons | help' : false,
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                           }}

                           disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                           id={'brainee' + props.id}
                           apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                           onFocusOut={(e) => handleRequest("update-question", e.target.innerHTML, props.id)}
                           initialValue={editorContent}
                        /> */}
                     </>
                  }
                  {generateQuestionOptions()}
                  {
                     props.type !== "long_answer"
                        ?
                        props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view")
                           ?
                           props.type === "short_answer"
                              ?
                              <Button className='button-shadow' id={props.id} type='primary' block shape='round' onClick={() => handleRequest("add-correct", "", -1)}>Add Correct Answer</Button>
                              :
                              <Button className='button-shadow' id={props.id} type='primary' block shape='round' onClick={() => handleRequest("add", "", -1)}>Add Option</Button>
                           :
                           <></>
                        :
                        <></>

                     // props.type !== "short_answer" && props.type !== "long_answer" && props.mode !== "answer" && !props.mode.includes("assign") && props.mode !== "view" 
                     // ? 
                     // <Button className='button-shadow' id={props.id} type='primary' block shape='round' onClick={() => handleRequest("add", "", -1)}>Add Option</Button>
                     // :
                     // <Button className='button-shadow' id={props.id} type='primary' block shape='round' onClick={() => handleRequest("add-correct", "", -1)}>Add Correct Answer</Button>
                  }
                  {
                     props.matched !== undefined
                        ?
                        (props.matched === false && props.type !== "long_answer"
                           ?
                           <>
                              <Badge.Ribbon text="Correct Answer">
                                 <Card
                                    size="small"
                                    style={{ paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", paddingRight: "70px", textAlign: "left" }}
                                 >
                                    {getCorrectAnswer(props.type, props.correct, props.option_labels)}

                                 </Card>
                              </Badge.Ribbon>
                           </>
                           :
                           <></>)
                        : <></>

                  }
               </Space>

            </Card>
         )
      }
   }

   return (
      <>
         {generateQuestionCard()}
      </>
   )
}