import classNames from 'classnames'
import PanelItemIcon from './PanelItemIcon'


function PanelItem(props) {
  const { setActiveTab, label, icon, name, activeTab, setPanelOpen } = props
  const className = classNames({
    'panel-items-list-item': true,
    active: activeTab === name,
  })

  return (
    <div
      className={className}
      onClick={() => {
        setPanelOpen(true)
        setActiveTab(name)
      }}
    >
      {PanelItemIcon[icon].render()}
      <span>{label}</span>
    </div>
  )
}

export default PanelItem
