import React,{ useContext, useEffect, useRef, useState } from 'react'
// import { getImage, getImages } from '@services/iconscout'
import { Input, Box, Grid, Flex } from 'theme-ui'
// import { useHandlers } from '@/uibox'
import SearchObjectsAPI from '../../LessonComponents/SearchObjectsAPI'
// import { CanvasContext } from '../../EditLessonPage'
import { CanvasContext } from '../../hooks/LessonPage'
// import { useHandlers } from '@/uibox'
import { Shape1,
         Shape2, 
         Shape3, 
         Shape4, 
         Shape5,
         Shape6,
         Shape7,
         Shape8,
         Shape9,
         Shape10,
         Shape11,
         Shape12,
         Shape13,
         Shape14,
         Shape15,
         Shape16,
         Shape17,
         Shape18,
         Shape19,
         Shape20,
      } from '../../LessonComponents/Shape' 

function ObjectsPanel() {

  const { state, actions } = useContext(CanvasContext);

  const setItem = (type) =>
  {
    var data = {type : "OBJECT", url : "", defaultcontent : type}
    actions?.addElement(data);
  }

  const elementRef = React.useRef(null)

  return (
    <>
     <Box sx={{ padding: '2rem 2rem 1rem' }}>
        <div className="logo">
              <div style={{color : 'white' , fontWeight : 'bold', width : '100%', fontSize : '18px' }}>
                OBJECT LIST
              </div>
            {/* <img src={searchLogo}/> */}
          </div>      
        {/* <Input style={{ background: '#fff', color: '#333' }} type="tel" placeholder="Search Shape" /> */}
        {/* <button onClick={setItem}>Click Me</button> */}
      </Box>     
        <Grid id gap={3} columns={[2, null, 4]} style={{margin : "15px"}}>
          <Box >
              <div style={{cursor : "pointer"}} onClick={() => setItem("circle")}>
                <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" >
                    <circle r="100" cx="100" cy="100" fill="gray" />
                  </svg>
              </div>  
            </Box>
            <Box >
              <div style={{cursor : "pointer"}} onClick={() => setItem("star")}>
                <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" >
                      <polygon points = "100,10 40,180 190,60 10,60 160,180" fill = "gray"/>
                  </svg>
              </div>  
            </Box>
            <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("square")}>
                  <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" >
                    <rect width="200" height="200" x="5" y="5"  fill="gray" />
                  </svg>
              </div>  
            </Box>
            <Box >
              <div style={{cursor : "pointer"}} onClick={() => setItem("triangle")}>
                  <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" >
                      <polygon points="100,0 0,200 200,200" class="triangle" fill="gray" />
                  </svg>
              </div>  
            </Box>        
            
        </Grid>
        <Grid id gap={3} columns={[2, null, 4]} style={{margin : "15px"}}>
        <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape1")}>
              <Shape1/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape2")}>
            <Shape2/>
            </div>  
          </Box>
          <Box >
          <div style={{cursor : "pointer"}} onClick={() => setItem("shape3")}>
          <Shape3/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape4")}>
            <Shape4/>
            </div>  
          </Box>        
          
      </Grid>    

        <Grid id gap={3} columns={[2, null, 4]} style={{margin : "15px"}}>
        <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape5")}>
              <Shape5/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape6")}>
            <Shape6/>
            </div>  
          </Box>
          <Box >
          <div style={{cursor : "pointer"}} onClick={() => setItem("shape7")}>
          <Shape7/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape8")}>
            <Shape8/>
            </div>  
          </Box>        
          
      </Grid>        

        <Grid id gap={3} columns={[2, null, 4]} style={{margin : "15px"}}>
        <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape9")}>
              <Shape9/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape10")}>
            <Shape10/>
            </div>  
          </Box>
          <Box >
          <div style={{cursor : "pointer"}} onClick={() => setItem("shape11")}>
          <Shape11/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape12")}>
            <Shape12/>
            </div>  
          </Box>        
          
      </Grid>        

        <Grid id gap={3} columns={[2, null, 4]} style={{margin : "15px"}}>
        <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape13")}>
              <Shape13/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape14")}>
            <Shape14/>
            </div>  
          </Box>
          <Box >
          <div style={{cursor : "pointer"}} onClick={() => setItem("shape15")}>
          <Shape15/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape16")}>
            <Shape16/>
            </div>  
          </Box>        
          
      </Grid>            

        <Grid id gap={3} columns={[2, null, 4]} style={{margin : "15px"}}>
        <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape17")}>
              <Shape17/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape18")}>
            <Shape18/>
            </div>  
          </Box>
          <Box >
          <div style={{cursor : "pointer"}} onClick={() => setItem("shape19")}>
          <Shape19/>
            </div>  
          </Box>
          <Box >
            <div style={{cursor : "pointer"}} onClick={() => setItem("shape20")}>
            <Shape20/>
            </div>  
          </Box>        
          
      </Grid>            
                   
          {/* </Flex>    
      </Grid> */}
    </>
  )
}
export default ObjectsPanel
