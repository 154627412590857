import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
   Input, Table, Typography, Space, Tooltip, Button, Badge,
   Tag, Descriptions, Divider, message, Modal, List, Avatar, Row, Col,
   InputNumber, Spin, Breadcrumb
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import './Assessment.css';
import './Tracker.css';
// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import SimpleBarReact from "simplebar-react";
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

// import {useSelector, useDispatch} from 'react-redux'
// import { update_option_data } from '../../actions';

const { Text } = Typography;
const { TextArea } = Input;

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let matched = false;
let essayIdx = -1;

export function FormativeTracker() {
   const navigate = useNavigate();
   const location = useLocation();
   // const tableRef = useRef(null);

   const assign_id = location.state.assign_id;
   // const title = location.state.title;
   const origin = location.state.origin;

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [assignInfo, setAssignInfo] = useState(null);
   const [trackerList, setTrackerList] = useState([]);
   const [trackerListFiltered, setTrackerListFiltered] = useState([]);
   const [legendCount, setLegendCount] = useState({
      submitted: 0,
      started: 0,
      not_started: 0
   });
   const [trackerLoading, setTrackerLoading] = useState(false);
   const [recheckLoading, setRecheckLoading] = useState(false);
   const [checkEssayModalVisible, setCheckEssayModalVisible] = useState(false);
   const [assessmentTitle, setAssessmentTitle] = useState("");
   const [menuItems, setMenuItems] = useState([]);
   const [selectedItem, setSelectedItem] = useState('');
   const [selectedStudentName, setSelectedStudentName] = useState("");
   const [essayScore, setEssayScore] = useState(0);
   const [essayScoreMulti, setEssayScoreMulti] = useState([]);
   const [assignID, setAssignID] = useState("");
   const [evaluating, setEvaluating] = useState(false);
   // const [essayIndex, setEssayIndex] = useState(-1);
   const [essayStudentIdx, setEssayStudentIdx] = useState(-1);
   const [essayComments, setEssayComments] = useState([]);

   const [tmpEssayScore, setTmpEssayScore] = useState({
      value: 0,
      points: 0,
   });

   const [tmpEssayComment, setTmpEssayComment] = useState("");

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);
      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher') {
         navigate('/home');
      }
      else {
         getAssignInfo(assign_id);
         getTrackerList(assign_id);
      }
   }, [assign_id]);

   useEffect(() => {
      essayIdx = -1;
      idxCol = [];
      qcardIdx = 1;
   },);

   const getAssignInfo = (assign_id) => {
      axios
         .get(`${process.env.REACT_APP_API_ASSESSMENT}/assigninfo?id=${assign_id}&at=formative`)
         .then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               setAssignInfo(data);
               setAssessmentTitle(data.title);
            }
         }, (error) => {
            console.log(error.message);
         });
   }

   const getTrackerList = (assign_id) => {
      setTrackerLoading(true);

      var url = `${process.env.REACT_APP_API_ASSESSMENT}/assessmenttracker?assign_id=${assign_id}&at=formative`;

      axios
         .get(url)
         .then((response) => {
            console.log(response);

            setTrackerLoading(false);

            if (response.data) {
               const data = response.data;

               var legend = {
                  submitted: 0,
                  started: 0,
                  not_started: 0
               }

               var menuItems = [];

               for (var i = 0; i < data.length; i++) {
                  if (data[i].submit_status === 1)
                     legend.submitted++;
                  else if (data[i].submit_status === 0)
                     legend.started++;
                  else
                     legend.not_started++;

                  //-- For essay student list
                  menuItems.push(getItem(data[i].fullname, i + 1, '<AntDIcons.UserOutlined />'));
               }

               setLegendCount(legend);
               setMenuItems(menuItems);
               setTrackerList(data);
               setTrackerListFiltered(data);
            }
         }, (error) => {
            setTrackerLoading(false);
            console.log(error.message);
         });
   }

   function getItem(label, key, icon, children) {
      return {
         key,
         icon,
         children,
         label,
      };
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const tracker_columns = [
      {
         title: 'Answer Id',
         key: 'answer_id',
         dataIndex: 'answer_id',
         hidden: true,
      },
      {
         title: 'Student Id',
         key: 'student_id',
         dataIndex: 'student_id',
         hidden: true,
      },
      {
         title: 'Id',
         key: 'assign_id',
         dataIndex: 'assign_id',
         hidden: true,
      },
      {
         title: 'Assessment Id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Submit Status',
         key: 'submit_status',
         dataIndex: 'submit_status',
         hidden: true,
      },
      {
         title: 'Total Points',
         key: 'total_points',
         dataIndex: 'total_points',
         hidden: true,
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Browser',
         key: 'browser',
         dataIndex: 'browser',
         hidden: true,
      },
      {
         title: 'Browser Version',
         key: 'browser_version',
         dataIndex: 'browser_version',
         hidden: true,
      },
      {
         title: 'OS',
         key: 'os_platform',
         dataIndex: 'os_platform',
         hidden: true,
      },
      {
         title: 'Essay Score',
         key: 'essay_score',
         dataIndex: 'essay_score',
         hidden: true,
      },
      {
         title: 'Name',
         key: 'fullname',
         sorter: (a, b) => { return a.fullname.localeCompare(b.fullname) },
         ...getColumnSearchProps('fullname'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip
               placement="topLeft"
               title={
                  <Space direction='vertical'>
                     <span>Browser: {object.browser}</span>
                     <span>Browser Version: {object.browser_version}</span>
                     <span>OS: {object.os_platform}</span>
                  </Space>
               }
            >
               <Tag
                  style={{ fontSize: 12, cursor: 'default' }}
                  color={object.submit_status === 1 ? 'green' : (object.submit_status === 0 ? 'gold' : 'red')}
               >
                  {object.fullname.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
               </Tag>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Gender',
         key: 'gender',
         sorter: (a, b) => { return a.gender.localeCompare(b.gender) },
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Text>{object.gender.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</Text>
         ),
         width: '8%'
      },
      {
         title: 'Level',
         key: 'level',
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.level}>
               <Text>{object.level}</Text>
            </Tooltip>
         ),
         width: '9%'
      },

      {
         title: 'Section',
         key: 'section',
         sorter: (a, b) => { return a.section.localeCompare(b.section) },
         ...getColumnSearchProps('section'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.section}>
               <Text>{object.section}</Text>
            </Tooltip>
         ),
         width: '10%'
      },
      {
         title: 'Score',
         key: 'score',
         sorter: (a, b) => a.score - b.score,
         // ellipsis: {
         //    showTitle: true,
         // },
         render: object => (
            <Text>{object.status === "" ? '' : object.score + "/" + object.total_points}</Text>
            // <Text>{ object.score + "/" + object.total_points }</Text>
         ),
         width: '7%'
      },
      {
         title: 'Percent',
         key: 'percentage',
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Text>{object.status === "" ? '' : object.percentage + "%"}</Text>
            // <Text>{ object.percentage + "%" }</Text>
         ),
         width: '8%'
      },
      {
         title: 'Status',
         key: 'status',
         sorter: (a, b) => { return a.status.localeCompare(b.status) },
         // dataIndex: 'status',
         // ellipsis: {
         //    showTitle: true,
         // },
         render: object => (
            object.status !== "" &&
            <Tag
               style={{ fontSize: 12 }}
               color={object.status === "Passed" ? 'green' : 'red'}
            >
               {object.status}
            </Tag>
         ),
         width: '8%'
      },
      {
         title: 'Started',
         key: 'start_date',
         dataIndex: 'start_date',
         sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
         width: '11%'
      },
      {
         title: 'Submitted',
         key: 'end_date',
         dataIndex: 'end_date',
         sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
         width: '11%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               {payload.submit_status === 1 &&
                  <Space size="small">
                     <Tooltip placement="top" title='View Answer'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleViewAnswer(payload.assessment_id, payload.student_id, payload.fullname)} /></Tooltip>
                     {/* <Tooltip placement="top" title='Allow Retake'>
                        <Popconfirm
                           title="Allow this student to retake?"
                           onConfirm={() => handleAllowRetake(payload.assessment_id, payload.assign_id, payload.student_id)}
                           okText="Yes"
                           cancelText="No"
                        >                   
                        <AntDIcons.FormOutlined color='#4abec7' style={{ cursor: 'pointer' }}/>
                     </Popconfirm>
                        
                     </Tooltip> */}
                  </Space>
               }
            </>
         },
         width: '8%'
      },
   ].filter(item => !item.hidden);

   const handleViewAnswer = (assessment_id, student_id, student_name) => {
      if (assignInfo.question_type.toString() === '1') {
         navigate('/formativeassessmenttracker/teacher-view-ans-pdf', { state: { student_name, assessment_id, assignInfo, student_id } });
      }
      else if (assignInfo.question_type.toString() === '2') {
         navigate('/formativeassessmenttracker/teacher-view-ans-manual', { state: { student_name, assessment_id, assignInfo, student_id } });
      }
   }

   // const handleAllowRetake = (assessment_id, assign_id, student_id) => {
   //    const key = 'updatable';
   //    message.loading({ content: "Allowing student to retake...", key });
   //    // setTrackerLoading(true);

   //    axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/allowretakeformative?assessment_id=${assessment_id}&assign_id=${assign_id}&student_id=${student_id}`).then((response) => {
   //       console.log(response);

   //       if (response.data) {
   //          const data = response.data;
   //          message.success({ content: "Student is now allowed to retake", key, duration: 1 });
   //          updateTrackerList(assign_id);
   //       } else {
   //          message.success({ content: "Allowing to retake failed.", key, duration: 1 });
   //       }
   //    }, (error) => {
   //       console.log(error.message);
   //    });
   // }

   const handleCheckEssay = () => {
      setCheckEssayModalVisible(true);
   }

   const handleRecheckAnswers = (assign_id) => {
      const key = 'updatable';
      // message.loading({content: "Re-cheking answers...", key});
      setRecheckLoading(true);

      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/recheckanswers?assign_id=${assign_id}&at=formative`).then((response) => {
         console.log(response);

         //if (response.data) {
         const data = response.data;
         message.success({ content: "Re-checking complete!", key, duration: 1 });
         setRecheckLoading(false);
         updateTrackerList(assign_id);
         //}
      }, (error) => {
         console.log(error.message);
      });
   }

   const updateTrackerList = (assign_id) => {
      const key = 'updatable';

      // essayIdx = -1;

      // message.loading({content: "Updating Tracker...", key});
      setTrackerLoading(true);

      var url = `${process.env.REACT_APP_API_ASSESSMENT}/assessmenttracker?assign_id=${assign_id}&at=formative`;

      axios.get(url).then((response) => {
         console.log(response);

         setTrackerLoading(false);

         if (response.data) {
            const data = response.data;

            var legend = {
               submitted: 0,
               started: 0,
               not_started: 0
            }

            for (var i = 0; i < data.length; i++) {
               if (data[i].submit_status === 1)
                  legend.submitted++;
               else if (data[i].submit_status === 0)
                  legend.started++;
               else
                  legend.not_started++;
            }

            let e_s_m = data[0].essay_score_multi !== null && data[0].essay_score_multi !== "" ? JSON.parse(data[0].essay_score_multi) : [];
            setEssayScoreMulti(e_s_m);

            let e_c_m = data[0].essay_multi_comments !== null && data[0].essay_multi_comments !== "" ? JSON.parse(data[0].essay_multi_comments) : [];
            setEssayComments(e_c_m);

            setLegendCount(legend);
            setTrackerList(data);
            setTrackerListFiltered(data);
         }
      }, (error) => {
         setTrackerLoading(false);
         console.log(error.message);
      });
   }

   const filterTrackerList = (assign_id, filterby) => {
      // const key = 'updatable';
      // message.loading({ content: "Filtering Tracker...", key });

      // axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/assessmentfiltertracker?assign_id=${assign_id}&submit_status=${filterby}&at=formative`).then((response) => {
      //    console.log(response);

      //    if (response.data) {
      //       const data = response.data;

      //       message.success({ content: "Tracker updated", key, duration: 1 });
      //       setTrackerList(data);
      //    }
      // }, (error) => {
      //    console.log(error.message);
      // });

      var filteredList = trackerList.filter(e => e.submit_status === filterby);

      setTrackerListFiltered(filteredList);
   }

   const handleUpdateTracker = () => {
      updateTrackerList(assign_id);
   }

   const handleFilterTracker = (status_count, filterby) => {
      if (status_count > 0)
         filterTrackerList(assign_id, filterby);
   }

   // const items = [
   //    getItem('Navigation One', 'sub1', <AntDIcons.MailOutlined />, [
   //    getItem('Item 1', 'g1', null, [getItem('Option 1', '1'), getItem('Option 2', '2')], 'group'),
   //    getItem('Item 2', 'g2', null, [getItem('Option 3', '3'), getItem('Option 4', '4')], 'group'),
   //    ]),
   //    getItem('Navigation Two', 'sub2', <AntDIcons.AppstoreOutlined />, [
   //    getItem('Option 5', '5'),
   //    getItem('Option 6', '6'),
   //    getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
   //    ]),
   //    getItem('Navigation Three', 'sub4', <AntDIcons.SettingOutlined />, [
   //    getItem('Option 9', '9'),
   //    getItem('Option 10', '10'),
   //    getItem('Option 11', '11'),
   //    getItem('Option 12', '12'),
   //    ]),
   // ];

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: [],
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });

   const [answerInfo, setAnswerInfo] = useState({
      Id: "",
      Answers: "",
      Score: "",
      Total_points: "",
      Start_date: "",
      End_date: ""
   });

   const getAssessmentInfo = (assessment_id, assign_id, student_id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${assessment_id}&at=formative`).then((response) => {
         if (response.data) {
            var data = response.data;
            var qs = JSON.parse(data.question_sheet);
            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: qs,
               Level: data.level,
               Subject: data.subject,
               Level_desc: data.level_desc,
               Subject_desc: data.subject_desc
            });

            getAnswerInfo(assessment_id, assign_id, student_id);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAnswerInfo = (assessment_id, assignment_id, student_id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/answerinfo2?assessment_id=${assessment_id}&assignment_id=${assignment_id}&student_id=${student_id}&at=formative`).then((response) => {
         if (response.data) {
            const data = response.data;
            // const answers = JSON.parse(data.answer);
            // setAnswers(JSON.parse(data.answer));
            setAnswerInfo({
               Id: data.id,
               Answers: JSON.parse(data.answer),
               Score: data.score,
               Total_points: data.total_points,
               Start_date: data.start_date,
               End_date: data.end_date
            })
         }
      }, (error) => {
         setAnswerInfo({
            Id: "",
            Answers: "",
            Score: "",
            Total_points: "",
            Start_date: "",
            End_date: ""
         });

         console.log(error.message);
      });
   }

   const handleCancelCheckEssay = () => {
      setAssInfo({
         Id: "",
         Title: "",
         Term: "",
         File_url: "",
         Question_type: "1",
         Created_by: "",
         Date_created: "",
         Question_sheet: [],
         Level: "",
         Subject: "",
         Level_desc: "",
         Subject_desc: ""
      });

      setAnswerInfo({
         Id: "",
         Answers: "",
         Score: "",
         Total_points: "",
         Start_date: "",
         End_date: ""
      });

      setEssayStudentIdx(-1);
      setSelectedItem('');
      setCheckEssayModalVisible(false)
   }

   const handleStudentClick = (assessment_id, assignment_id, student_id, student_name, essay_score, essay_score_multi, essay_multi_comments, idx) => {
      setAssInfo({
         Id: "",
         Title: "",
         Term: "",
         File_url: "",
         Question_type: "1",
         Created_by: "",
         Date_created: "",
         Question_sheet: [],
         Level: "",
         Subject: "",
         Level_desc: "",
         Subject_desc: ""
      });

      setAnswerInfo({
         Id: "",
         Answers: "",
         Score: "",
         Total_points: "",
         Start_date: "",
         End_date: ""
      });

      setEssayStudentIdx(idx);
      // essayIdx = -1;

      // message.info("Hey " + student_id);
      setSelectedStudentName(student_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()));
      setEssayScore(essay_score);

      let e_s_m = essay_score_multi !== null && essay_score_multi !== "" ? JSON.parse(essay_score_multi) : [];
      setEssayScoreMulti(e_s_m);

      essay_multi_comments = essay_multi_comments === 0 ? '[""]' : essay_multi_comments;

      let e_m_c = essay_multi_comments !== "" && essay_multi_comments !== null ? JSON.parse(essay_multi_comments) : [];
      setEssayComments(e_m_c);

      setSelectedItem(assessment_id);
      setAssignID(assignment_id);
      getAssessmentInfo(assessment_id, assignment_id, student_id);
   }

   const handleSaveEssayScore = (id) => {
      // essayIdx = -1;
      setEvaluating(true);

      //--Replace all null with '0'
      essayScoreMulti.forEach((item, i) => {
         if (item === null)
            essayScoreMulti[i] = '0';
      });

      essayComments.forEach((item, i) => {
         if (item === null)
            essayComments[i] = '';
      });

      let score = JSON.stringify(essayScoreMulti);
      let comments = JSON.stringify(essayComments);

      var total_score = essayScoreMulti.reduce((a, b) => (a !== null ? parseInt(a) : 0) + (b !== null ? parseInt(b) : 0));
      var url = `${process.env.REACT_APP_API_ASSESSMENT}/addessayscoremulti?answer_id=${id}&score=${score}&total_score=${total_score}&comments=${comments}&at=formative`;

      axios.post(url).then(response => {
         if (response.data) {
            setEvaluating(false);

            setAnswerInfo({
               Id: "",
               Answers: "",
               Score: "",
               Total_points: "",
               Start_date: "",
               End_date: ""
            });

            Modal.success({
               title: 'Formative Assessment',
               content: 'Essay evaluation added.',
               centered: true,
               onOk: () => { handleRecheckAnswers(assignID); },
            });
         }
      }, (error) => {
         setEvaluating(false);
         Modal.error({
            title: 'Formative Assessment',
            content: 'Essay evaluation failed.',
            centered: true,
         });
      });
   }

   // const handleSetEssayScoreMulti = (val, max_score) => {
   //    if (val !== null) {
   //       if (parseInt(val) > parseInt(max_score)) {
   //          val = max_score;

   //          Modal.error({
   //             title: 'Institutional Assessment',
   //             content: 'The value you entered exceeds the maximum allowed points! The system will automatically set the points to maximum value.',
   //             centered: true,
   //          });
   //       }

   //       essayIdx = -1;
   //       let tmpEssayScore = [...essayScoreMulti];

   //       tmpEssayScore[essayIndex] = val === '' ? '0' : val.toString();
   //       setEssayScoreMulti(tmpEssayScore);
   //    }
   // }

   // const handleClickEssayScore = (id) => {
   //    essayIdx = -1;
   //    setEssayIndex(parseInt(id))
   // }

   // const handleSetEssayComment = (val) => {
   //    essayIdx = -1;
   //    let tmpEssayComments = [...essayComments];
   //    tmpEssayComments[essayIndex] = val;
   //    setEssayComments(tmpEssayComments);
   // }

   const handleSetEssayScore = (idx) => {
      if (tmpEssayScore.value !== null) {
         let val = -1;
         if (parseInt(tmpEssayScore.value) > parseInt(tmpEssayScore.points)) {
            val = tmpEssayScore.points;

            Modal.error({
               title: 'Assessment',
               content: 'The value you entered exceeds the maximum allowed points! Brainee will automatically set the points to maximum value.',
               centered: true,
            });
         } else {
            val = tmpEssayScore.value;
         }

         let essayScores = [...essayScoreMulti];
         essayScores[parseInt(idx)] = val === '' ? '0' : val.toString();
         setEssayScoreMulti(essayScores);
         // setEssayScoreMulti({...essayScoreMulti, [parseInt(idx)]: val === '' ? '0' : val.toString()});
      }
   }

   const handleSetEssayComment = (idx) => {

      // let tmpEssayComments = {...essayComments, [idx]: tmpEssayComment};
      let tmpEssayComments = [...essayComments];
      tmpEssayComments[idx] = tmpEssayComment;
      setEssayComments(tmpEssayComments);
   }

   return (
      <>

         {/* } */}

         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/formativeassessment', { state: { activetab: origin } })}>Formative Assessment</a></li>
                     <li><span>/</span></li>
                     <li className="active">Tracker</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        {/* <AntDIcons.HomeOutlined /> */}
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to="/formativeassessment" state={{ activetab: origin }}>Self-check</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Tracker</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <div className="dashboard-item-inner3">
                  {assignInfo !== null &&
                     <Descriptions bordered size='small'
                        column={{
                           xxl: 4,
                           xl: 3,
                           lg: 3,
                           md: 3,
                           sm: 2,
                           xs: 1,
                        }}
                     >
                        <Descriptions.Item label="Title" span={4} style={{ fontSize: 13 }}>
                           <b>{assignInfo.title}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Start Date" span={2} style={{ fontSize: 13 }}>
                           <b>{assignInfo.start_date}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="End Date" span={2} style={{ fontSize: 13 }}>
                           <b>{assignInfo.end_date}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Duration" span={2} style={{ fontSize: 13 }}>
                           <b>{assignInfo.duration + " min(s)"}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Passing Percentage" span={2} style={{ fontSize: 13 }}>
                           <b>{assignInfo.passing_percentage + "%"}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Actions" span={4} style={{ fontSize: 13 }}>
                           <Space size={'small'}>
                              <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleCheckEssay()} style={{ fontSize: 12 }}>Check Essay</Button>
                              <Button className='button-shadow' size='small' shape='round' type='primary' loading={recheckLoading} onClick={() => handleRecheckAnswers(assign_id)} style={{ fontSize: 12 }}>Re-check Answers</Button>
                           </Space>
                        </Descriptions.Item>
                     </Descriptions>
                  }
                  {/* <Divider /> */}
                  {/* <Space size={'large'} style={{ paddingBottom: 15, paddingTop: 15, width: '100%', justifyContent: 'center' }} > */}
                  <Row style={{ paddingTop: 20, paddingBottom: '20px', gap: '20px', justifyContent: 'center' }}>
                     <Badge count={legendCount.submitted} style={{ backgroundColor: 'green' }} overflowCount={999}>
                        <Tag color={"green"} style={{ cursor: legendCount.submitted > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.submitted, 1)}>Submitted</Tag>
                     </Badge>
                     <Badge count={legendCount.started} style={{ backgroundColor: 'gold' }} overflowCount={999}>
                        <Tag color={"gold"} style={{ cursor: legendCount.started > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.started, 0)}>Answering</Tag>
                     </Badge>
                     <Badge count={legendCount.not_started} style={{ backgroundColor: 'red' }} overflowCount={999}>
                        <Tag color={"red"} style={{ cursor: legendCount.not_started > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.not_started, -1)}>Not Started</Tag>
                     </Badge>
                     <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleUpdateTracker()} loading={trackerLoading} style={{ fontSize: 12 }}>Update Tracker</Button>
                  </Row>
                  {/* </Space> */}

                  <Table
                     rowKey={'uid'}
                     // loading={trackerLoading}
                     style={{ fontSize: '12px' }}
                     size='small'
                     columns={tracker_columns}
                     dataSource={trackerListFiltered}
                     pagination={false}
                     scroll={{
                        x: '1000px',
                        y: 'calc(100vh - 21.5rem)',
                     }}
                     footer={() => 'Total Records: ' + trackerListFiltered.length}
                  // expandable={{
                  //    expandedRowRender: record => <>
                  //    <Space>
                  //    <p style={{ margin: 0 }}>Browser <Tag color={"#4abec7"}>{record.browser}</Tag></p>
                  //    <p style={{ margin: 0 }}>Browser Version <Tag color={"#4abec7"}>{record.browser_version}</Tag></p>
                  //    <p style={{ margin: 0 }}>Operating System <Tag color={"#4abec7"}>{record.os_platform}</Tag></p>
                  //    </Space>
                  //    </>
                  // }}
                  // scroll={{ y: 240 }}
                  // rowClassName={ (record) => { record.submit_status === 1 ? 'ass_submitted' : (record.submit_status === 0 ? 'ass_started' : 'ass_not_started') }   }
                  // rowClassName={(record) => record.submit_status === 1 ? 'ass_submitted' : (record.submit_status === 0 ? 'ass_started' : 'ass_not_started')}
                  />
               </div>
            </div>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar/>
               <NoticeBoard/>
            </div>
         </div> */}

         <Modal
            title={" Check Essay for " + assessmentTitle}
            centered
            destroyOnClose={true}
            open={checkEssayModalVisible}
            onOk={() => handleCancelCheckEssay()}
            onCancel={() => handleCancelCheckEssay()}
            // width={window.innerWidth - 600}
            width='70%'
            // cancelButtonProps={{shape:"round"}}
            okButtonProps={{ shape: "round" }}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            {/* <Menu
               // onClick={onClick}
               style={{
               width: 256,
               }}
               defaultSelectedKeys={['1']}
               defaultOpenKeys={['sub1']}
               mode="inline"
               items={items}
            /> */}
            <Row>
               <Col span={10}>
                  <div className='student_list_container'>
                     <SimpleBarReact style={{ height: "calc(100vh - 185x)" }}>
                        <List
                           // bordered
                           itemLayout="horizontal"
                           dataSource={trackerListFiltered}
                           renderItem={(item, idx) => (
                              <List.Item
                                 // style={{paddingLeft: "15px", paddingRight: "15px", background: (item.assessment_id === selectedItem ? "#FFF" : "#E2EBFF")}} 
                                 style={{ paddingLeft: "15px", paddingRight: "15px" }}
                                 className={idx === essayStudentIdx ? 'essay_student_selected' : 'essay_student'} //'essay_student '
                                 onClick={() => handleStudentClick(item.assessment_id, item.assign_id, item.student_id, item.fullname, item.essay_score, item.essay_score_multi, item.essay_multi_comments, idx)}
                              >
                                 <List.Item.Meta
                                    style={{ cursor: "pointer" }}
                                    avatar={<Avatar size={45} icon={<AntDIcons.UserOutlined />} />}
                                    title={
                                       <Tag
                                          style={{ fontSize: 13, cursor: 'pointer' }}
                                          color={item.submit_status === 1 ? 'green' : 'red'}
                                       >
                                          {item.fullname.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                       </Tag>
                                    }
                                    description={"Essay Score: " + item.essay_score} //(item.essay_score === -1 ? 'No Score' : item.essay_score)}
                                 />
                              </List.Item>
                           )}
                        />
                     </SimpleBarReact>

                  </div>
               </Col>
               <Col span={14}>
                  {answerInfo.Id !== ""
                     ?
                     <Spin spinning={evaluating} tip="Adding evaluation, please wait...">
                        <div className='essay_list_container'>
                           <SimpleBarReact style={{ height: "calc(100vh - 240px)" }}>
                              {
                                 assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {
                                    if (type == "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                    }

                                    //-- Check if answer and correct are matched 
                                    var matched = false;

                                    if (type === "long_answer") {
                                       essayIdx++;
                                       let essay_score = parseInt(essayScoreMulti[essayIdx]);
                                       let essay_comment = essayComments[essayIdx];

                                       return (
                                          <li>
                                             <Space direction='vertical' size={'middle'} style={{ width: "100%" }}>
                                                <BraineeQuestionCard
                                                   key={id}
                                                   question_key_id={id}
                                                   index={idxCol[idx]}
                                                   id={idx}
                                                   type={type}
                                                   points={points}
                                                   correct={correct}
                                                   answers={answerInfo.Answers[idx].answer}
                                                   option_labels={option_labels}
                                                   image_url={image_url}
                                                   question_type={assignInfo.question_type.toString()}
                                                   mode={'tracker-view'}
                                                   matched={matched}
                                                   template={template}
                                                   question={question}
                                                   question_image={question_image}
                                                   question_audio={question_audio}
                                                   question_video={question_video}
                                                   assessment_type={'formative'}
                                                // considerAnswerCallback={handleConsiderAnswer} 
                                                />
                                                <Descriptions bordered size='small' column={4} style={{ marginBottom: "20px", marginLeft: '20px', marginRight: '20px' }}>
                                                   <Descriptions.Item label={"Points (Max: " + points + ")"} span={4} style={{ fontSize: 13 }}>
                                                      <div id={essayIdx} onBlur={(e) => handleSetEssayScore(e.target.id)}>
                                                         <InputNumber
                                                            id={essayIdx}
                                                            size='middle'
                                                            max={points}
                                                            min={0}
                                                            style={{ width: "100%", borderRadius: "7px" }}
                                                            defaultValue={essay_score}
                                                            onChange={(value) => setTmpEssayScore({ value, points })}
                                                         />
                                                      </div>
                                                   </Descriptions.Item>
                                                   <Descriptions.Item label="Comments/Remarks" span={4} style={{ fontSize: 13 }}>
                                                      <div id={essayIdx} onBlur={(e) => handleSetEssayComment(e.target.id)}>
                                                         <TextArea
                                                            allowClear
                                                            id={essayIdx}
                                                            name={essayIdx}
                                                            rows={5}
                                                            onChange={(e) => setTmpEssayComment(e.target.value)}
                                                            defaultValue={essay_comment}
                                                         />
                                                      </div>
                                                   </Descriptions.Item>
                                                </Descriptions>
                                             </Space>
                                          </li>
                                       )
                                    }
                                    else
                                       return (<></>)
                                 })
                              }
                           </SimpleBarReact>
                           {
                              essayIdx > -1
                                 ?
                                 <>
                                    {/* <Divider /> */}
                                    <Button
                                       className='button-shadow'
                                       size='middle'
                                       type='primary'
                                       shape='round'
                                       block
                                       onClick={() => handleSaveEssayScore(answerInfo.Id)}
                                    >
                                       Save
                                    </Button>
                                 </>
                                 :
                                 <></>
                           }
                        </div>
                     </Spin>
                     :
                     <div className='essay_list_container'></div>
                  }
               </Col>
            </Row>
         </Modal>
      </>
   );
}